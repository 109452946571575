import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'

class EnlargeableImage extends Component {
  state = {
    imageDialogOpen: false,
    imageLoading: false
  }

  // componentDidMount () {
  //   const { loadImage } = this.props

  //   if (loadImage) {
  //     this.setState({ imageLoading: true })
  //     loadImage()
  //       .then(() => {
  //         this.setState({ imageLoading: false })
  //       })
  //       .catch(() => {
  //         this.setState({ imageLoading: false })
  //       })
  //   }
  // }

  enlargeImage = () => {
    this.setState({ imageDialogOpen: true })
  }

  handleImageDialogClose = () => {
    this.setState({ imageDialogOpen: false })
  }

  render () {
    const { imageDialogOpen } = this.state
    const { image } = this.props

    // if (!image) {
    //   if (imageLoading) {
    //     return (
    //       <div style={{height: '130px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    //         <CircularProgress />
    //       </div>
    //     )
    //   }

    //   return <div style={{height: '130px', display: 'flex', alignItems: 'center'}}>N/A</div>
    // }

    return (
      <span>
        <img
          src={'data:image/png;base64,' + image}
          className='expand'
          alt='Item'
          onClick={this.enlargeImage}
          style={{height: '100px', cursor: 'pointer'}}
        />
        <Dialog maxWidth={false} open={imageDialogOpen} onClose={this.handleImageDialogClose}>
          <img
            src={`data:image/png;base64,${image}`}
            alt='Item'
            style={{width: '500px'}}
          />
        </Dialog>
      </span>
    )
  }
}

export default EnlargeableImage
