import { postResource, postInsecureResource, getResource } from '../utilities/service-utils'	


//NOT USED
// export const getAllAgents = () => {
//   // console.log('[services.agent] - getAllAgents')  
//   return getResource('dguser')
// }

//NOT USED
// export const getAllApprovedAgents = () => {
//   // console.log('[services.agent] - getAllApprovedAgents')  
//   return getResource('dguser/approved')
// }

// export const getAllPendingAgents = () => {
//   // console.log('[services.agent] - getAllPendingAgents')  
//   return getResource('dguser/findAllPending')
// }

// TODO: This method and several others are not currently being used.
// export const getAllDeniedAgents = () => {
//   // console.log('[services.agent] - getAllDeniedAgents')  
//   return getResource('dguser/denied')
// }

//NOT USED
//by User Name
// export const getOneAgentByName = (name) => {
//   // console.log('[services.agent] - getOneAgentByName');  
//   // console.log('name=',name);
//   return getResource(`dguser/dguser/${name}`);   
// }

//by Database Key
// export const getOneAgentById = (id) => {
//   // console.log('[services.agent] - getOneAgentById') 
//   // console.log('id=',id); 
//   return getResource(`dguser/findByUserKeyVal/${id}`)  
// }

export const createAgent = (agent, userId) => {	
  // console.log('[services/agent] createAgent' )	
  return postInsecureResource('dguser/create', agent, userId)	
}

// NOT USED
// export const approveAgent = (userId,adminId) => {	
//   // console.log('[services/agent] approveAgent:'+userId+"  by:"+adminId )	
//   return postResource('dguser/approve/'+ userId,null,adminId)	
// }

//NOT USED
// export const denyAgent = (userId,reason, adminId) => {	
//   // console.log('[services/agent] denyAgent:'+userId+"  by:"+adminId +"  reason:"+reason)	
//   return postResource('dguser/deny/'+userId,reason,adminId)	
// }