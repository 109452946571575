import { connect } from 'react-redux'
import * as OptionActions from '../../actions/option'

import SelectField from '../../components/inputs/SelectField'



const mapStateToProps = (state) => {
  const dangerousItems = state.option.dangerousItems

  const options = dangerousItems
    .map((option) => ({
      id: option.id,
      field: option.field,
      label: option.description,
      value: option.description,
      description: option.name
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1)

  return {
    options
  }
}



const mapDispatchToProps = (dispatch, ownProps) => {
  //console.log('[DangerousItemSelect]  mapDispatchToProps  ', ownProps)
  //console.log('ownProps.hazclass =' + ownProps.hazclass)
  const loadData = () => {
    // console.log('[DangerousItemSelect]  loadData -  ownProps.hazclass=' + ownProps.hazclass) 
    return (
      dispatch(OptionActions.retrieveDangerousItems(ownProps.hazclass))      
    )
  }
      
  return {
    loadData
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectField)
