import * as TabActions from '../actions/tab'

const initialState = {
  incidents: 0
}

const tabs = (state = initialState, action) => {
  switch (action.type) {
    case TabActions.CHANGE:
      return {
        ...state,
        [action.name]: action.tabIndex
      }
    default:
      return state
  }
}

export default tabs
