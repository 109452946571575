import { connect } from 'react-redux'
import * as OptionActions from '../../actions/option'

import SelectField from '../../components/inputs/SelectField'

const mapStateToProps = (state) => {
  const letterMailed = state.option.letterMailed

  const options = letterMailed
    .map((option) => ({
      id: option.id,
      field: option.field,
      label: option.description,
      value: option.name,
      description: option.description
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1)

  return {
    options
  }
}

const mapDispatchToProps = (dispatch) => {
  const loadData = () => {
    return dispatch(OptionActions.retrieveLetterMailed())
  }

  return {
    loadData
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectField)
