export const CHANGE = 'TAB_CHANGE'

/**
 * 
 * @param {*} name 
 * @param {*} tabIndex 
 * @returns 
 */
export const change = (name, tabIndex) => {
  return {
    type: CHANGE,
    name,
    tabIndex
  }
}
