const padLeft = (pad, string) => {
    return (pad + string).slice(-pad.length)
  }
  
  export const beginningOfDay = (date) => {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    return newDate
  }
  
  export const endOfDay = (date) => {
    const newDate = new Date(date)
    newDate.setHours(23, 59, 59, 999)
    return newDate
  }

  export const formatSlashed = (date) => {
    const d = new Date(date)
  // console.log('DATE IS ' + d)
    if (!date || isNaN(d)) {
      return null
    }
// console.log('returning ' + `${padLeft('00', d.getMonth() + 1)}/${padLeft('00', d.getDate())}/${d.getFullYear()}`)

    return `${padLeft('00', d.getMonth() + 1)}/${padLeft('00', d.getDate())}/${d.getFullYear()}`
    
  }
  
  export const formatDate = (date) => {
    if (!date) {
      return ''
    }
  
    return new Date(date).toLocaleDateString()
  }
  
  export const formatTime = (date) => {
    if (!date) {
      return ''
    }
  
    const d = new Date(date)
    return `${padLeft('00', d.getHours())}:${padLeft('00', d.getMinutes())}`
  }
  
  export const formatDateTime = (date) => {
    if (!date) {
      return ''
    }
  
    return `${formatDate(date)} ${formatTime(date)}`
  }
  
  export const formatISODate = (date) => {
    const d = new Date(date)
  
    if (!date || isNaN(d)) {
      return null
    }
  
    return `${d.getFullYear()}-${padLeft('00', d.getMonth() + 1)}-${padLeft('00', d.getDate())}`
  }
  
  export const formatIdDate = (date) => {
    const d = new Date(date)
  
    if (!date || isNaN(d)) {
      return null
    }
  
    return `${d.getFullYear()}${padLeft('00', d.getMonth() + 1)}${padLeft('00', d.getDate())}`
  }

  export const formatISODateTime = (date) => {
    const d = new Date(date)
  
    if (!date || isNaN(d)) {
      return null
    }
  
    return `${d.getFullYear()}-${padLeft('00', d.getMonth() + 1)}-${padLeft('00', d.getDate())}T${padLeft('00', d.getHours())}:${padLeft('00', d.getMinutes())}`
  }
  