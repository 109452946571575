import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import JSONPretty from 'react-json-pretty'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import * as DateUtils from '../utilities/date-utils'
import { connect } from 'react-redux'
import { compose } from 'redux'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = {
    header: {
        backgroundColor: '#283FB3'
    },
    headerCell: {
        color: '#FFFFFF'
    },
    container: {
        maxHeight: 400
    },
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      //padding: theme.spacing(1),
    }
  }

/**
 * Builds a fragment of TableRow components
 * @param { row, index } props 
 * @returns Row at that index containing those props
 */  
function Row(props) {
  const { row, index } = props;
  const [open, setOpen] = React.useState(false);
  return(
    <React.Fragment>
        <TableRow key={index} >     
                <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                </TableCell>
                <TableCell align="left">{ row.actionType }</TableCell>
                <TableCell align="right">{ DateUtils.formatDateTime(row.actionDate) }</TableCell>
                <TableCell align="right">{ row.updtUser }</TableCell>
         </TableRow>
         <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Snapshot
              </Typography>
              <Typography variant="h14" gutterBottom component="div">
                   <HistValues parms={ row.parameterValues } />
                </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function HistValues(props){
    const { parms } = props
    
    return (
    
        <JSONPretty id="json-pretty" data={parms}></JSONPretty>
    
    )

} 

  
const AuditTable = ({ classes, logs, snackbarClose, snackbarOpen, open, setOpen }) => (
    
    <Snackbar
    onClose={snackbarClose}
    open={snackbarOpen}
    >
        <TableContainer component={Paper} className={classes.container}>
            <Table>
                <TableHead  className={classes.header}>
                    <TableRow className={classes.header}>
                        <TableCell  className={classes.headerCell} align="right"></TableCell>
                        <TableCell  className={classes.headerCell} align="right">Action</TableCell>
                        <TableCell className={classes.headerCell}  align="right">Date</TableCell>
                        <TableCell  className={classes.headerCell} align="right">User</TableCell>
                    </TableRow>
                </TableHead>
                    <TableBody>
                    {logs.map ?
                        logs.map((log, index)=>(
                        
                        <Row key={index} row={log} />
                      
                    ))
                    :
                    ''
                
                }
                </TableBody>
            </Table>
        </TableContainer>
    </Snackbar>
)

const mapStateToProps = (state) => {
    const open = false
    return {
        open
    }
}

const mapDispatchToProps = (dispatch) => {
    const setOpen = (open) =>{
        // console.log('changing ' + open)
        if(open===true){
            return false
        }
        else{            
            return true
        }
    }
   
    return {
        setOpen 
    }
}


export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(AuditTable)