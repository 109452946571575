import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { render } from 'react-dom'

import Root from './containers/Root'

render(
  <Root />,
  document.getElementById('root')
)
