import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TableHeaderCell from '../TableHeaderCell'
import styles from './styles'
import { connect } from 'react-redux'
import { compose } from 'redux'

const IncidentTableHeader = ({ classes, onHeaderClick, selectedTab, orderBy, sortDirection }) => (
  <TableHead><TableRow><TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Incident ID</Typography>}
        onClick={onHeaderClick('hmKey')}
        sortDirection={orderBy === 'hmKey' ? sortDirection : false} 
      />
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Customs Barcode</Typography>}
        onClick={onHeaderClick('barcodeCustoms')}
        sortDirection={orderBy === 'barcodeCustoms' ? sortDirection : false}
      />
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Submitted By</Typography>}
        onClick={onHeaderClick('reportingContactName')}
        sortDirection={orderBy === 'reportingContactName' ? sortDirection : false}
      />
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Hub</Typography>}
        onClick={onHeaderClick('reportingAirportHub')}
        sortDirection={orderBy === 'reportingAirportHub' ? sortDirection : false} 
      />
      {selectedTab === 3 ?
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Status</Typography>}
        onClick={onHeaderClick('status')}
        sortDirection={orderBy === 'status' ? sortDirection : false}
      />
      : ''}
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Type</Typography>}
        onClick={onHeaderClick('initialFinding')}
        sortDirection={orderBy === 'initialFinding' ? sortDirection : false}
      />
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Airline Reporting Incident</Typography>}
        onClick={onHeaderClick('airlineReportingIncident')}
        sortDirection={orderBy === 'airlineReportingIncident' ? sortDirection : false}
      />
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Agency Reporting Incident</Typography>}
        onClick={onHeaderClick('agencyReportingIncident')}
        sortDirection={orderBy === 'agencyReportingIncident' ? sortDirection : false}
      />   
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Entity Reporting Incident</Typography>}
        onClick={onHeaderClick('entityReportingIncident')}
        sortDirection={orderBy === 'entityReportingIncident' ? sortDirection : false}
      />
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Date Reported</Typography>}
        onClick={onHeaderClick('dateReported')}
        sortDirection={orderBy === 'dateReported' ? sortDirection : false}
      />
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Date Updated</Typography>}
        onClick={onHeaderClick('actionDate')}
        sortDirection={orderBy === 'actionDate' ? sortDirection : false}
      />
      <TableHeaderCell className={classes.tableCell} 
        label={<Typography variant='h6'>Updated By</Typography>}
        onClick={onHeaderClick('updtUser')}
        sortDirection={orderBy === 'updtUser' ? sortDirection : false}
      />
    </TableRow>
  </TableHead>
)

const mapStateToProps = (state) => {
  const selectedTab = state.tabs.incidents
  return {
    selectedTab
  }
}

export default compose(withStyles(styles), connect(mapStateToProps))(IncidentTableHeader)
