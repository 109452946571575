import * as TableActions from '../actions/table'

const initialState = {
  incidentsTable: {
    errorText: '',
    filter: {},
    page: 0,
    rowCount: 0,
    rows: [],
    rowsPerPage: 10,
    loading: false,
    orderBy: '',
    selected: [],
    showFilter: false,
    sortDirection: false
  }
}

const addValues = (state, tableId, values) => {
  const table = state[tableId]

  return {
    ...state,
    [tableId]: {
      ...table,
      ...values
    }
  }
}

const table = (state = initialState, action) => {
  switch (action.type) {
    case TableActions.CHANGE_FILTER:
      return addValues(state, action.tableId, {
        filter: {
          ...state[action.tableId].filter,
          [action.columnName]: action.value
        },
        selected: []
      })
    case TableActions.CHANGE_PAGE:
      return addValues(state, action.tableId, { page: action.page })
    case TableActions.CHANGE_ROWS_PER_PAGE:
      return addValues(state, action.tableId, { rowsPerPage: action.rowsPerPage })
    case TableActions.CLEAR_ROWS:
      return addValues(state, action.tableId, {
        rows: []
      })
    case TableActions.RETRIEVE_ROWS_START:
      return addValues(state, action.tableId, { loading: true })
    case TableActions.RETRIEVE_ROWS_SUCCESS:
      return addValues(state, action.tableId, {
        errorText: '',
        loading: false,
        rowCount: action.rowCount,
        rows: action.rows
      })
    case TableActions.RETRIEVE_ROWS_ERROR:
      return addValues(state, action.tableId, {
        errorText: action.errorText,
        loading: false
      })
    case TableActions.SORT:
      return addValues(state, action.tableId, {
        orderBy: action.orderBy,
        sortDirection: action.sortDirection
      })
    case TableActions.TOGGLE_FILTER:
      return addValues(state, action.tableId, { showFilter: !state[action.tableId].showFilter })
    default:
      return state
  }
}

export default table
