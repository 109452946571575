import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as UserActions from '../actions/user'
import LoginExternal from '../components/LoginExternal'

const mapStateToProps = (state) => {
  const errorMessage = state.user.errorMessage

  return {
    errorMessage
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const onSubmit = (values) => {
    // console.log('[LoginPageExternal] onSubmit - source = idp')
    return dispatch(UserActions.logIn(values.userId, values.password, 'idp')).then((success) => {
        // console.log('[LoginPageExternal] mapDispatchToProps - dispatch UserActions.login - then - success =', success)
        if (success) {
          ownProps.history.push('/welcome')
        }
      })
  }
  return {
    onSubmit
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginExternal))
