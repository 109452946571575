import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as IncidentActions from '../actions/incident'
import * as SnackbarActions from '../actions/snackbar'
import TrackerForm from '../components/TrackerForm'

import * as Roles from '../constants/roles'



const mapStateToProps = (state, ownProps) => {
  // console.log('[containers/TrackerForm] mapStateToProps')
  //console.log('[containers/TrackerForm] mapStateToProps -- ownProps=', ownProps)
  const role = state.user.role
  const carrier = state.user.carrier
  const agency = state.user.agency
  const entity = Roles.DANGEROUS_GOODS_ADMIN === state.user.role ? 'DGADMIN' : state.user.role
  const snackbarOpen = state.snackbars.trackerForm
  const staged_images = state.incident.staged_images || [];
  return {
    role,
    carrier,
    agency,
    entity,
    snackbarOpen,   //used in components.TrackerForm.render {} = this.props
    staged_images
    
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  // console.log('[containers/TrackerForm] mapDispatchToProps')
  //console.log('mapDispatchToProps -- ownProps=', ownProps)
  const onBackClick = () => {  //will be used in components.TrackerForm.render {} = this.props - possibly as handleBackClick (?)
    // console.log('[containers.TrackerForm] -- onBackClick')
    //console.log('ownProps', ownProps)
    //console.log('ownProps.history', ownProps.history)        
    ownProps.history.push('/home')
  }

  const updateWorkNote = (worknote) => {
    dispatch(IncidentActions.updateWorkNote(worknote))

  }

  const onSnackbarClose = () => {  // used in components.TrackerForm.render {} = this.props
    // console.log('[containers.TrackerForm]  onSnackbarClose')
    // console.log("[containers.TrackerForm]  dispatch(SnackbarActions.close('trackerForm'))")
    dispatch(SnackbarActions.close('trackerForm'))
    // console.log("[containers.TrackerForm]  onBackClick")
    onBackClick()
  }
  const saveDraft = () => {  //as 'handleSubmit -- used in components.TrackerForm.render {} = this.props
    console.log('[containers.TrackerForm]  saveDraft')
    return dispatch(IncidentActions.submitDraft())
    
  }

  const onSubmit = (values) => {  //as 'handleSubmit -- used in components.TrackerForm.render {} = this.props
    // console.log('[containers.TrackerForm]  onSubmit')
    return dispatch(IncidentActions.trackerFormSubmit(values))
  }

  const stageImage = (file) => {
    dispatch(IncidentActions.stageImage(file))
  }

  const deleteStagedImages = () => {
    dispatch(IncidentActions.deleteStagedImages())
  }

  return {
    onBackClick,
    onSnackbarClose,
    onSubmit,
    updateWorkNote,
    saveDraft,
    stageImage,
    deleteStagedImages
  }
}


// See https://redux-form.com/8.3.0/examples/selectingformvalues/
// Decorate with connect to read form values
const selector = formValueSelector('TrackerForm') // <-- same as form name
const SelectingTrackerFormValuesForm = connect(state => {
  // Here we create the 'selectors' individually
  const airlineRecordNoValue = selector(state, 'airlineRecordNo')
  const barcodeCn35Value = selector(state, 'barcodeCn35')
  const barcodeCn35VerificationStatusValue = selector(state, 'barcodeCn35VerificationStatus')  
  const skipBarcodeCn35VerificationValue = selector(state, 'skipBarcodeCn35Verification')
  const barcodeCustomsValue = selector(state, 'barcodeCustoms')
  const barcodeCustomsVerificationStatusValue = selector(state, 'barcodeCustomsVerificationStatus')
  const skipBarcodeCustomsVerificationValue = selector(state, 'skipBarcodeCustomsVerification')
  const dangerousItemValue = selector(state, 'dangerousItem')
  const icaoHazardClassValue = selector(state, 'icaoHazardClass')    
  const initialFindingValue = selector(state, 'initialFinding')  
  const issueTypeValue = selector(state, 'issueType')  
  const recipientPhoneValue = selector(state, 'recipientPhone')
  const reportingContactNameValue = selector(state, 'reportingContactName')
  const senderPhoneValue = selector(state, 'senderPhone')
  const restrictedItemValue = selector(state, 'restrictedItem')

  // // But we could also have created multiple selectors as a group all at once
  // const { airlineRecordNoValue,  
  //   barcodeCn35Value, 
  //   barcodeCn35VerificationStatusValue, 
  //   skipBarcodeCn35VerificationValue,
  //   barcodeCustomsValue, 
  //   barcodeCustomsVerificationStatusValue,
  //   skipBarcodeCustomsVerificationValue, 
  //   dangerousItemValue,
  //   icaoHazardClassValue, 
  //   initialFindingValue,
  //   issueTypeValue, 
  //   recipientPhoneValue,
  //   reportingContactNameValue, 
  //   senderPhoneValue, 
  //   restrictedItemValue } = selector(state, 
  //   'airlineRecordNo',
  //   'barcodeCn35',
  //   'barcodeCn35VerificationStatus' , 
  //   'skipBarcodeCn35Verification',
  //   'barcodeCustoms',
  //   'barcodeCustomsVerificationStatus',
  //   'skipBarcodeCustomsVerification',
  //   'dangerousItem',
  //   'icaoHazardClass',   
  //   'initialFinding',
  //   'issueType', 
  //   'recipientPhone',
  //   'reportingContactName',
  //   'senderPhone',
  //   'restrictedItem')


  return {
    airlineRecordNoValue,
    barcodeCn35Value,
    barcodeCn35VerificationStatusValue,
    skipBarcodeCn35VerificationValue,
    barcodeCustomsValue,
    barcodeCustomsVerificationStatusValue,
    skipBarcodeCustomsVerificationValue,
    dangerousItemValue,
    icaoHazardClassValue,
    initialFindingValue,
    issueTypeValue,
    recipientPhoneValue,
    reportingContactNameValue,
    senderPhoneValue,
    restrictedItemValue
    // fullName: `${firstName || ''} ${lastName || ''}`
  }
})(TrackerForm)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectingTrackerFormValuesForm))
