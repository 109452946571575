import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as PersonActions from '../../actions/person'
import * as SnackbarActions from '../../actions/snackbar'
import ViewPerson from '../../components/ViewPerson'

const mapStateToProps = (state) => {
  // console.log("[containers/ViewPerson/ViewPersonForm] mapStateToProps")
  // console.log("state=", state)
  const snackbarOpen = state.snackbars.approvePerson	 
  const person = state.person || ''
  const role = state.user.role
  return {
    initialValues: state.person,
    person,
    role,
    snackbarOpen
  }
}

const mapDispatchToProps = (dispatch) => {
  // console.log("[containers/ViewPerson/ViewPersonForm] mapDispatchToProps")
  
  const fetchPerson= (personId) => {
    // console.log('[containers/ViewPersonForm]  fetchPerson    personId=', personId)	        
    dispatch(PersonActions.retrieveOnePersonById(personId));
  }

  const onBackClick = (ownProps) => {	
    // console.log('[containers/ViewPersonForm]  onBackClick - ownProps='.ownProps)	    
    ownProps.history.push('/adminhome')	
  }	

  const onSnackbarClose = () => {	
    // console.log('[containers/ViewPersonForm]  onSnackbarClose')	
    dispatch(SnackbarActions.close('approvePerson'))	
  }	

  const handleApprove = (values,ownProps) => {
    // console.log('ViewPersonForm.handleApprove   values=', values);
    return dispatch(PersonActions.approvePerson(values));
  }

  const handleDeny = (...values) => {
    // console.log('ViewPersonForm.handleDeny   values=', ...values);
    return dispatch(PersonActions.denyPerson(...values));
  }

  const handleCancel = () => {
    // console.log('ViewPersonForm.handleCancel');
    return onBackClick();
  }

  return {
    fetchPerson,
    onBackClick,	
    onSnackbarClose,	 
    handleApprove,
    handleDeny,
    handleCancel
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewPerson))
