import * as UserActions from '../actions/user'

/**
 * Updates react application state according user login/logout actions
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function user (state = {}, action) {
  switch (action.type) {
    case UserActions.LOGIN_SUCCESS:
      return {
        ...state,
        userId: action.userId,
        role: action.role,
        carrier: action.carrier,
        agency: action.agency,
        authToken: action.authToken,
        authExpiry: action.authTokenExpiry,
        tokenAssignTime: action.tokenAssignTime        
      }
    case UserActions.LOGIN_FAILED:
      return {
        ...state,
        errorMessage: action.message
      }

    case UserActions.REFRESH:
      return{
        ...state,
        authToken: action.authToken,
        authExpiry: action.authTokenExpiry,
        tokenAssignTime: action.tokenAssignTime 
      }

    case UserActions.LOGOUT:
      return {}
    default:
      return state
  }
}
