import * as CountryActions from '../actions/country'

const initialState = {
  countries: []
}

const country = (state = initialState, action) => {
  switch (action.type) {
    case CountryActions.RETRIEVE_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.countries
      }
    default:
      return state
  }
}

export default country
