import { connect } from 'react-redux'
import * as CountryActions from '../../actions/country'

import SelectField from '../../components/inputs/SelectField'

const mapStateToProps = (state) => {
  const countries = state.country.countries

  const options = countries
    .map((country) => ({
      label: country.name,
      value: country.code
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1)

  return {
    options
  }
}

const mapDispatchToProps = (dispatch) => {
  const loadData = () => {
    return dispatch(CountryActions.retrieveAllCountries())
  }

  return {
    loadData
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectField)
