import * as SnackbarActions from '../actions/snackbar'

const initialState = {
  createIrregularity: false,
  reportDetail: false
}

const snackbars = (state = initialState, action) => {
  switch (action.type) {
    case SnackbarActions.OPEN:
      return {
        ...state,
        [action.name]: true
      }
    case SnackbarActions.CLOSE:
      return {
        ...state,
        [action.name]: false
      }
    default:
      return state
  }
}

export default snackbars
