import React from 'react'
// import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'
import IncidentTable from '../../containers/IncidentTable'
// import { lightBlue } from '@material-ui/core/colors'

const styles = {
  tabDraft: {
    maxWidth: '20%',
    color: 'white',
    opacity: '0.5',
    backgroundColor: '#3f50b5',
    border: '2px solid black'
    // color: 'darkblue',
    // opacity: '0.2'
    // border: '1px solid black'
    // borderTopLeftRadius: '20px',
    // borderTopRightRadius: '20px'
  },

  tabInProcess: {
    maxWidth: '40%',
    color: 'white',
    opacity: '0.5',
    backgroundColor: '#3f50b5',
    border: '2px solid black'
    // borderTopLeftRadius: '20px',
    // borderTopRightRadius: '20px'
  },

  tabClosed: {
    maxWidth: '20%',
    color: 'white',
    opacity: '0.5',
    backgroundColor: '#3f50b5',
    border: '2px solid black'
    // borderTopLeftRadius: '20px',
    // borderTopRightRadius: '20px'
  },

  tabAll: {
    maxWidth: '20%',
    color: 'white',
    opacity: '0.5',
    backgroundColor: '#3f50b5',
    border: '2px solid black'
    // borderTopLeftRadius: '20px',
    // borderTopRightRadius: '20px'
  }
}
const IncidentTables = ({
  classes,
  onLoadHazmatData,
  onTabChange,
  page,
  selectedTab
}) => {
  return (
    <div>
      {/* <AppBar position='static'> */}
      <Tabs centered
        onChange={onTabChange}
        value={selectedTab}
        TabIndicatorProps={{
          style: {
            height: '6px'
          }
        }}
        variant='fullWidth'>
        <Tab value={0} classes={{fullWidth: classes.tabDraft}} label='DRAFT' />
        <Tab value={1} classes={{fullWidth: classes.tabInProcess}} label='IN PROCESS' />
        <Tab value={2} classes={{fullWidth: classes.tabClosed}} label='CLOSED' />
        <Tab value={3} classes={{fullWidth: classes.tabAll}} label='All' />
      </Tabs>
      {/* </AppBar> */}
      {selectedTab === 0 && (
        <IncidentTable
          onLoadData={onLoadHazmatData}
          tableId='incidentsTable'
          page={page}
        />
      )}
      {selectedTab === 1 && (
        <IncidentTable
          onLoadData={onLoadHazmatData}
          tableId='incidentsTable'
          page={page}
        />
      )}
      {selectedTab === 2 && (
        <IncidentTable
          onLoadData={onLoadHazmatData}
          tableId='incidentsTable'
          page={page}
        />
      )}
      {selectedTab === 3 && (
        <IncidentTable
          onLoadData={onLoadHazmatData}
          tableId='incidentsTable'
          page={page}
        />
      )}
    </div>

  )

}

export default withStyles(styles)(IncidentTables)
