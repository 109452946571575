import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core'	

import { indigo, blue, grey, red  } from '@material-ui/core/colors'
import * as Roles from '../constants/roles'
import PageContainer from './PageContainer'
import { getAllPendingPersons } from '../services/person'
import 'react-confirm-alert/src/react-confirm-alert.css'

const styles = (theme) => ({
  addIcon: {
    color: grey[600],
    cursor: 'pointer',
    marginRight: '64px',
    '&:hover': {
      color: blue[700]
    }
  },
  cancelIcon: {
    color: grey[600],
    cursor: 'pointer',
    marginRight: '64px',
    '&:hover': {
      color: red[700]
    }
  },
  container: {
    padding: theme.spacing(2)
  },
  errorIcon: {
    color: red[500]
  },
  hidden: {
    visibility: 'hidden'
  },
  iconColumn: {
    width: '30px'
  },
  tableBodyCell: {
    verticalAlign: 'top'
  },
  tableCell: {
    padding: '12px'
  },
  tooltip: {
    fontSize: '12px'
  }
})

const columns = [
  { label: 'Manage User Key', name: 'manageUserKey' },
  { label: 'User Id', name: 'userId' },
  { label: 'Agency Abbreviation', name: 'agencyAbbreviation' },
  { label: 'Carrier Abbreviation', name: 'carrierAbbreviation' },
  { label: 'Entity Type', name: 'entityType' },
  { label: 'Contact Email', name: 'contactEmail' },
  { label: 'Contact Name', name: 'contactName' },
  { label: 'Contact Phone', name: 'contactPhone' },
  { label: 'Created Datetime', name: 'createdDatetime' }
]

class AdminHome extends Component {
  state = {
    rows: []
  }

  componentDidMount () {
    // console.log('[components.AdminHome.index]  componentDidMount()  ')
    // console.log('[components.AdminHome.componentDidMount]  getAllPendingPersons  ')   
    getAllPendingPersons()
      .then((rows) => {
        // console.log(rows)
        this.setState({ rows })
      })
  }

  render () {
    // console.log('this.props',this.props);
    // console.log('this.state',this.state);
    const { role } = this.props
    // console.log('[components.AdminHome]  render () -- role= ',role)   
    //  (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY) && {console.log();} 
    const { classes } = this.props;
    const { rows } = this.state
    return (
      <PageContainer >
        <Typography className={classes.container} variant='h5'>Admin Home</Typography>
        <Grid item xs={12} md={4}>	
          <Typography variant='subtitle1'>  &nbsp;&nbsp;&nbsp;&nbsp;User Registration Requests Pending Approval</Typography>
        </Grid>
        <Grid item xs={12} md={4}></Grid>   
        <Divider />
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.name}><Typography variant='h6'>{column.label}</Typography></TableCell>
                ))}
              </TableRow>
            </TableHead>
            { (role === Roles.DANGEROUS_GOODS_ADMIN) &&
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.manageUserKey}>
                  {columns.map((column) => (
                  <TableCell key={column.name}> <Link style={{ color: indigo[300] }} to={'/viewperson/' + row.manageUserKey}>{row[column.name]}</Link></TableCell>
                ))}
                </TableRow>
              ))}
            </TableBody>
            }
        </Table>
      </PageContainer>
    )
  }
}

export default withStyles(styles)(AdminHome)
