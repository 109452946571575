import * as Roles from '../constants/roles'
import * as Incidents from '../constants/incidents'

const getAdminCriteria = (state, filter) => {
  const selectedTab = state.tabs.incidents
  // console.log('[selectors/incident] getAdminCriteria -- selectedTab=',selectedTab)
  let status = null
  if (selectedTab === 0) {
    status = Incidents.DRAFT
  } else if (selectedTab === 1) {
    status = Incidents.INPROCESS
  }
  else if (selectedTab === 2) {
    status = Incidents.CLOSED
  }
  else if (selectedTab === 3) {
    status = ''
  }

  return {
    status,
    ...filter
  }
}

export const getIncidentCriteria = (state, filter) => {
  const role = state.user.role

  if (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY || role === Roles.CARRIER || role === Roles.AGENT || role === Roles.CUSTOMER_SERVICE_AGENT) {
    return getAdminCriteria(state, filter)
  } 
 
}
