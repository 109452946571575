import { connect } from 'react-redux'
import * as ErrorActions from '../actions/error'
import ErrorDialog from '../components/ErrorDialog'

const mapStateToProps = (state, ownProps) => {
  const errorDialog = state.errors.dialog

  const errorText = errorDialog.errorText
  const open = errorDialog.open
  const title = errorDialog.title

  return {
    errorText,
    open,
    title
  }
}

//dispatch(ErrorActions.closeErrorDialog()) means:

//Get a CLOSE_ERROR_DIALOG Action from the closeErrorDialog Action Creator

//Dispatch this action, meaning send it to Redux.

//Redux will update the store

const mapDispatchToProps = (dispatch, ownProps) => {
  const onClose = () => {
    dispatch(ErrorActions.closeErrorDialog())
  }

  return {
    onClose
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog)
