import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { TextField } from '../ReduxFormMaterialUI'

class SelectField extends Component {
  componentDidMount () {
    // console.log(['[SelectField] componentDidMount', this.props])
    const { loadData } = this.props

    if (loadData) {
      loadData()
    }
  }

  componentDidUpdate (prevProps) {
    // console.log(['[SelectField] componentDidUpdate', this.props])    
    const { loadData } = this.props

    if (loadData) {
      loadData()
    }
  }

  render () {
    const { input, options, ...other } = this.props

    // Fix unknown prop warnings
    const textFieldProps = Object.assign({}, other)
    delete textFieldProps.loadData

    return (
      <TextField
        select
        {...input}
        {...textFieldProps}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value} altvalue={option.altValue} >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    )
  }
}

export default SelectField
