import * as AirportServices from '../services/airport'

export const RETRIEVE_DESTINATION_AIRPORTS_SUCCESS = 'RETRIEVE_DESTINATION_AIRPORTS_SUCCESS'
export const RETRIEVE_ORIGIN_AIRPORTS_SUCCESS = 'RETRIEVE_ORIGIN_AIRPORTS_SUCCESS'

/**
 * 
 * @returns 
 */
export const retrieveDestinationAirports = () => (dispatch, getState) => {
  const state = getState()
  const airportDestinations = state.airport.airportDestinations

  if (airportDestinations && airportDestinations.length > 0) {
    return
  }

  return AirportServices.getDestinationAirports()
    .then((airports) => {
      const sortedAirports = airports.slice()
      sortedAirports.sort((a, b) => {
        return a.airportName > b.airportName
      })

      dispatch({
        type: RETRIEVE_DESTINATION_AIRPORTS_SUCCESS,
        airportDestinations: sortedAirports
      })
    })
}

/**
 * 
 * @returns 
 */

export const retrieveOriginAirports = () => (dispatch, getState) => {
  const state = getState()
  const airportOrigins = state.airport.airportOrigins

  if (airportOrigins && airportOrigins.length > 0) {
    return
  }

  return AirportServices.getOriginAirports()
    .then((airports) => {
      const sortedAirports = airports.slice()
      sortedAirports.sort((a, b) => {
        return a.airportName > b.airportName
      })

      dispatch({
        type: RETRIEVE_ORIGIN_AIRPORTS_SUCCESS,
        airportOrigins: sortedAirports
      })
    })
}
