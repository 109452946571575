import { connect } from 'react-redux'
import * as CarrierActions from '../../actions/carrier'

import SelectField from '../../components/inputs/SelectField'

const mapStateToProps = (state) => {
  const carriers = state.carrier.carriers

  const options = carriers
    .map((carrier) => ({
      label: carrier.code + ' - ' + carrier.name,
      name: carrier.name,
      value: carrier.code,
      altValue: carrier.name
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1)

  return {
    options
  }
}

const mapDispatchToProps = (dispatch) => {
  const loadData = () => {
    // return dispatch(CarrierActions.retrieveAllCarriers())
    return dispatch(CarrierActions.retrievePilotCarriers())    
  }

  return {
    loadData
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectField)
