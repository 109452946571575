import { connect } from 'react-redux'
import * as OptionActions from '../../actions/option'

import SelectField from '../../components/inputs/SelectField'

const mapStateToProps = (state) => {
  //--Received has been intentionally misspelled as receivd because 'CUSTOMER_LETTER_RECEIVD_STATUS' is 30 characters long -- this is the maximum name length allowed by our Oracle database.
  const letterReceivd = state.option.letterReceivd

  const options = letterReceivd
    .map((option) => ({
      id: option.id,
      field: option.field,
      label: option.description,
      value: option.name,
      description: option.description
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1)

  return {
    options
  }
}

const mapDispatchToProps = (dispatch) => {
  const loadData = () => {
    return dispatch(OptionActions.retrieveLetterReceivd())
  }

  return {
    loadData
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectField)
