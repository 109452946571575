import * as ErrorActions from '../actions/error'

const initialState = {
  dialog: {
    open: false
  }
}



/**
The Reducer's job is to update application
state based on Actions that occurred.

Function to update state based on Error Actions
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const errors = (state = initialState, action) => {
  switch (action.type) {
    case ErrorActions.CLOSE_ERROR_DIALOG:
      return {
        ...state,
        dialog: {
          open: false
        }
      }
    case ErrorActions.OPEN_ERROR_DIALOG:
      return {
        ...state,
        dialog: {
          open: true,
          errorText: action.errorText,
          title: action.title
        }
      }
    default:
      return state
  }
}

export default errors
