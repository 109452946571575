import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as UserActions from '../actions/user'
import * as TableActions from '../actions/table'
import App from '../components/App'

const mapStateToProps = (state) => {
  const role = state.user.role
  const page = state.table.incidentsTable.page
  let isLoggedIn = false
  if (state.user && state.user.userId) {
    isLoggedIn = true
  }


  return {
    isLoggedIn,  //used in components.App.render {} = this.props
    role,
    page
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const logOut = () => {    //used in components.App.handleLogOut())
    // console.log('[containers.App] -- logOut')
    dispatch(UserActions.logOut())
    ownProps.history.push('/login')
  }

  const onNavItemClick = (route) => {
    // console.log('[containers.App] -- onNavItemClick')
    ownProps.history.push(route)
  }
    
  const onTitleClick = () => {
    // console.log('[containers.App] -- onTitleClick')   //used in components.App.render {} = this.props
    //console.log('ownProps', ownProps)
    //console.log('ownProps.history', ownProps.history)
    
    ownProps.history.push('/home')
  }

  const onAdminClick = () => {
    // console.log('[containers.App] -- onAdminClick')   //used in components.App.render {} = this.props
    //console.log('ownProps', ownProps)
    //console.log('ownProps.history', ownProps.history)    
    ownProps.history.push('/adminhome')
  }

  return {
    logOut,
    onNavItemClick,
    onTitleClick,
    onAdminClick
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
