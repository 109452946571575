import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'

const DatePickerField = ({ input, ...other }) => (
  <KeyboardDatePicker
    autoComplete='off'
    autoOk
    clearable
    format='MM/dd/yyyy'
    fullWidth
    InputProps={{
      inputProps: {
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
      }
    }}
    variant='inline'
    {...input}
    {...other}
    minDate='1800-01-01'
    onChange={input.onChange}
    value={input.value ? input.value : null}
  />
)

export default DatePickerField