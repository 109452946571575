import 'isomorphic-fetch'
import config from '../config'

const fetchAuthHeaders = () =>{                                       //Session Storage
  const ut = JSON.parse(sessionStorage.getItem('persist:domir'))
  // console.log('ut',--ut--)  // Don't log... contains token
  const user = JSON.parse(ut.user)
  // console.log('user', --user--) // Don't log... contains token
  const headers = {
    Authorization: user ? user.authToken : null,
    'cache-control': 'no-cache',
    pragma: 'no-cache'
  }
  // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!HEADERS ARE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
  // console.log(headers)
  return headers;
}



const UNKNOWN_ERROR_MESSAGE = 'An unexpected server error occured while processing your request. Please try again.'
const CONNECTION_ERROR_MESSAGE = 'Failed to contact server.'

const convertToQueryParameters = (obj) => {
  // console.log('convertToQueryParameters called with obj: ', obj);
  try{
    var str = []
    Object.keys(obj).forEach(key => {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        if (obj[key].constructor === Array) {
          obj[key].forEach((item) => str.push(key + '=' + item))
        } else if (obj[key].constructor === Date) {
          str.push(key + '=' + obj[key].toISOString())
        } else {
          str.push(key + '=' + obj[key])
        }
      }
    })
    // console.log('convertToQueryParameters returned: ', str.join('&'));
    return str.join('&')
  } catch(err) {
    // console.log('convertToQueryParameters returned null. ');
    return ''
  }
}

// TODO:  Comment this back in when implementing SAMS barcode validation
// const fetchSamsPostResource = (path, options = {}) => {
//   return fetchGenericPostResource(sams_headers, config.SAMS_ENDPOINT, path, options)
// }
const fetchPostResource = (path, options = {}) => {
   return fetchGenericPostResource(fetchAuthHeaders(), config.ENDPOINT, path, options)
}

const fetchGenericPostResource = (myHeaders, endpoint, path, options = {}) => {
  let url = encodeURI(`${endpoint}/${path}`)

  // console.log("service-utils  fetchPostResource  BASE URL is ", url)
  // console.log("service-utils  fetchPostResource  options =  ", options)

 const criteria = options.criteria
  if (criteria) {
    url += `?${convertToQueryParameters(criteria)}`
  }
  //  console.log("service-utils  fetchPostResource  criteria =  ", criteria)

  // console.log("service-utils  fetchPostResource CALLING URL at ", url)
  // console.log("service utils  fetchPostResource calling with: ", {
  //   headers: { ...myHeaders, ...options.headers },
  //   method: options.method ? options.method : 'GET',
  //   body: options.body
  // });
  return fetch(url, {
    headers: { ...myHeaders, ...options.headers },
    method: options.method ? options.method : 'GET',
    body: options.body
  })
}

// const fetchSamsResource = (path, options = {}) => {
//   return fetchGenericResource (sams_headers, config.SAMS_ENDPOINT, path, options)
// }

const fetchResource = (path, options = {}) => {
  // console.log('fetchResource');
  return fetchGenericResource (fetchAuthHeaders(), config.ENDPOINT, path, options)
}

const fetchGenericResource = (myHeaders, endpoint, path, options = {}) => {
  let url = encodeURI(`${endpoint}/${path}`)
    // console.log("service-utils  fetchGenericResource  options =  ", options);
    var criteria;
    if(options) {
      criteria = options.criteria;
        if (criteria) {
          url += `?${convertToQueryParameters(criteria)}`;
          // console.log("service-utils  fetchGenericResource  criteria =  ",criteria);
        }
        // else console.log("criteria is empty");
    }
    // else console.log("options is empty");
  return new Promise((resolve, reject) => {
    //  console.log("service-utils  fetchGenericResource CALLING URL at " + url)
    //  console.log("service utils  fetchGenericResource calling with: ", {
    //   headers: { ...myHeaders, ...options.headers },
    //   method: options.method ? options.method : 'GET',
    //   body: options.body
    // });
    fetch(url, {
      headers: { ...myHeaders, ...options.headers },
      method: options.method ? options.method : 'GET',
      body: options.body
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 500) {
            // console.log("(res.status === 500") ;
            return reject(UNKNOWN_ERROR_MESSAGE)
          }

          return res.text().then((text) => {
            // console.log("res.text") ;
            return reject(text || UNKNOWN_ERROR_MESSAGE)
          })
        }

        if (options.handleResponse) {
          return options.handleResponse(res)
            .then((result) => resolve(result))
        } else {
          return res.text()
            .then((text) => {
              if (text) {
                resolve(JSON.parse(text))
              }
              resolve(text)
            })
        }
      })
      .catch((e) => {
        // console.log('[service-utils] fetchGenericResource() - Promise.then --> error = ', e)
        // Will only get here if there is a network error
        return reject(CONNECTION_ERROR_MESSAGE)
      })
  })
}

// export const getSamsResource = (path, options = {}) => {
//   console.log('[service-utils] getSamsResource() options=',options); 
//   return fetchSamsResource(path, options)
// }

export const getResource = (path, options = {}) => {
  // console.log('[service-utils] getResource() options=',options); 
  // console.log('[service-utils] getResource() DEBUG -- pre-calling fetchAuthHeaders just to show we can'); 
   fetchAuthHeaders();
  //  console.log('[service-utils] getResource() DEBUG -- pre-calling fetchResource'); 
  return fetchResource(path, options)
}

export const getBlob = (path, options = {}) => {
  return fetchResource(path, {
    handleResponse: (res) => res.blob(),
    ...options
  })
}

export const getPage = (countPromise, searchPromise) => {
  const promises = [countPromise, searchPromise]

  return Promise.all(promises).then((results) => {
    return {
      rowCount: results[0],
      rows: results[1]
    }
  })
}

export const postFile = (path, file) => {
  const body = new FormData()
  body.append('file', file)

  return fetchPostResource(path, {
    method: 'POST',
    body
  })
}

export const postResource = (path, resource, userId) => {
  // console.log('postResource');
  // console.log('PATH     ', path);
  // console.log('RESOURCE    ', resource)
  // console.log('USERID    ', userId)  
  return fetchResource(path, {
    headers: {
      'Content-Type': 'application/json',
      UserId: userId
    },
    method: 'POST',
    body: JSON.stringify(resource)
  })
}

export const postInsecureResource = (path, resource, userId) => {
  // console.log('postInsecureResource');
  // console.log('PATH     ', path);
  // console.log('RESOURCE    ', resource)
  // console.log('USERID    ', userId)  
  return fetchInsecureResource(path, {
    headers: {
      'Content-Type': 'application/json',
      UserId: userId
    },
    method: 'POST',
    body: JSON.stringify(resource)
  })
}

const fetchInsecureResource = (path, options = {}) => {
  // console.log('fetchInsecureResource');
  return fetchGenericInsecureResource (config.ENDPOINT, path, options)
}

const fetchGenericInsecureResource = (endpoint, path, options = {}) => {
  let url = encodeURI(`${endpoint}/${path}`)
    // console.log('[service-utils] fetchInsecureResource() BASE URL is ', url);
    // console.log("service-utils  fetchGenericInsecureResource  options =  ", options);
    var criteria;
    if(options) {
      criteria = options.criteria;
        if (criteria) {
          url += `?${convertToQueryParameters(criteria)}`;
          // console.log("service-utils  fetchGenericInsecureResource  criteria =  ",criteria);
        }
        // else console.log("criteria is empty");
    }
    // else console.log("options is empty");
  return new Promise((resolve, reject) => {
    //  console.log("service-utils  fetchGenericInsecureResource CALLING URL at " + url)
    //  console.log("service-utils  fetchGenericInsecureResource CALLING with: ",{
    //   headers: { ...options.headers },
    //   method: options.method ? options.method : 'GET',
    //   body: options.body
    // })
    fetch(url, {
      headers: { ...options.headers },
      method: options.method ? options.method : 'GET',
      body: options.body
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 500) {
            // console.log("(res.status === 500") ;
            return reject(UNKNOWN_ERROR_MESSAGE)
          }

          return res.text().then((text) => {
            // console.log("res.text") ;
            return reject(text || UNKNOWN_ERROR_MESSAGE)
          })
        }

        if (options.handleResponse) {
          return options.handleResponse(res)
            .then((result) => resolve(result))
        } else {
          return res.text()
            .then((text) => {
              if (text) {
                resolve(JSON.parse(text))
              }
              resolve(text)
            })
        }
      })
      .catch((e) => {
        // console.log('[service-utils] fetchGenericInsecureResource() - Promise.then --> error = ', e)
        // Will only get here if there is a network error
        return reject(CONNECTION_ERROR_MESSAGE)
      })
  })
}


export const putResource = (path, updates, userId, options = {}) => {
  // console.log('putResource');
  return fetchResource(path, {
    headers: {
      'Content-Type': 'application/json',
      UserId: userId
    },
    method: 'PUT',
    body: JSON.stringify(updates),
    ...options
  })
}

export const deleteResource = (path) => {
  // console.log('deleteResource');
  return fetchResource(path, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'DELETE'
  })
}

export const fetchExcelFile = (path, options = {}) => {
  // console.log("utilities/service-utils -- fetchExcelFile -- options=", options)
  let url = encodeURI(`${config.ENDPOINT}/${path}`)
  const criteria = options
  if (criteria) {
    url += `?${convertToQueryParameters(criteria)}`
  }
  let authHeaders = fetchAuthHeaders();
  // console.log("utilities/service-utils -- fetchExcelFile --CALLING EXCEL SERVICE AT " + url)
  // console.log("[utilities/service-utils] -- fetchExcelFile -- calling with: ",{headers: {...authHeaders}})  
  // console.log("authHeaders: ", {headers: {authHeaders}});

  return fetch(url, {headers: {...authHeaders}})
    .then((res) => {
      // console.log('res=',res)
      return res.blob()
    })
    .then((text) => {
      // console.log('text=',text)
      return text
    })
}


export const  fetchWordFile = (path, hmKey, options = {}) => {
  // console.log("[utilities/service-utils] -- fetchWordFile")
  let url = encodeURI(`${config.ENDPOINT}/${path}`) + '/' + hmKey;
  let authHeaders = fetchAuthHeaders();
  // console.log("[utilities/service-utils] -- fetchWordFile -- CALLING WORD SERVICE AT " + url)
  // console.log("[utilities/service-utils] -- fetchWordFile -- authHeaders = ",authHeaders)
  // console.log("[utilities/service-utils] -- fetchWordFile -- calling with: ", {headers: {...authHeaders}});
  // console.log("[utilities/service-utils] -- fetchWordFile -- fetch ");
  return fetch(url, {headers: {...authHeaders}} )
    .then( response => response.blob()
    )
}
