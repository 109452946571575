import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as ErrorActions from '../actions/error'
import * as TableActions from '../actions/table'
import * as incidentActions from '../actions/incident'
import IncidentTable from '../components/IncidentTable'

const mapStateToProps = (state, ownProps) => {
    const table = state.table[ownProps.tableId] || {}
    
    const user = state.user
  
    return {
      errorText: table.errorText,
      filter: table.filter,
      loading: table.loading,
      orderBy: table.orderBy,
      page:  table.page,
      rowCount: table.rowCount,
      rows: table.rows,
      rowsPerPage: table.rowsPerPage,
      selected: table.selected,
      showFilter: table.showFilter,
      sortDirection: table.sortDirection,
      role: user.role,
      carrier: user.carrier,
      agency: user.agency,
      entity: user.role
    }
  }
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    const tableId = ownProps.tableId
    const page = ownProps.page
        
    const loadData = ()=> {
      dispatch(TableActions.clearRows(tableId))
      dispatch(TableActions.changePage(tableId, page, ownProps.onLoadData))
    }
      
    const onError = (errorText, title) => {
      dispatch(ErrorActions.openErrorDialog({ errorText, title }))
    }
  
    const onFilterChange = (columnName, value) => {
      dispatch(TableActions.changeFilter(tableId, columnName, value, ownProps.onLoadData))
    }
  
    const onFilterClick = () => {
      dispatch(TableActions.toggleFilter(tableId))
    }
  
    const onHeaderClick = (columnName) => {
      dispatch(TableActions.sort(tableId, columnName, ownProps.onLoadData))
    }
  
    const onPageChange = (page) => {
      dispatch(TableActions.changePage(tableId, page, ownProps.onLoadData))
    }
  
    const onRowsPerPageChange = (rowsPerPage) => {
      dispatch(TableActions.changeRowsPerPage(tableId, rowsPerPage, ownProps.onLoadData))
    }

    const clearSelectedImages = () => {
      dispatch(incidentActions.clearSelectedImages())
    }
  
    const onSerialNumberClick = (incident) => {
      dispatch(incidentActions.selectIncident(incident))
      //console.log('[containers.IncidentTable] onSerialNumberClick() - incident = ', incident)         
    }

    const exportHazmatToExcel = () => {
      // console.log("containers/IncidentTable -- exportHazmatToExcel")
      dispatch(TableActions.exportHazmatToExcel(tableId))
    }

  
    return {
      loadData,
      onError,
      onFilterChange,
      onFilterClick,
      onPageChange,
      onRowsPerPageChange,
      onSerialNumberClick,
      onHeaderClick,
      clearSelectedImages,
      exportHazmatToExcel
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IncidentTable))
  