import { connect } from 'react-redux'
import Welcome from '../components/Welcome'
import * as TabActions from '../actions/tab'


const mapStateToProps = (state, ownProps) => {
  // console.log("[containers/WelcomePage] mapStateToProps")
  const role = state.user.role

  return {
    role,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const onTabChange = () => {
    // console.log("[containers/HomePage.mapDispatchToProps] onTabChange value=",1)  
      dispatch(TabActions.change('incidents', 1))
    }
return {
  onTabChange
}
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
