import * as OptionActions from '../actions/option'

const initialState = {
  allOptions: [],
  initialFindings: [],
  issueTypes: [],
  icaoHazardClasses: [],
  issueIdentifiers: [],  
  restrictedItems: [],
  usStates: [],
  letterMailed: [],
  //--Received has been intentionally misspelled as receivd because 'CUSTOMER_LETTER_RECEIVD_STATUS' is 30 characters long -- this is the maximum name length allowed by our Oracle database.
  letterReceivd: [],
  dangerousItems: []    
}

const option = (state = initialState, action) => {
  switch (action.type) {
    case OptionActions.RETRIEVE_ALL_OPTIONS_SUCCESS:
      return {
        ...state,
        allOptions: action.allOptions
      }   
    case OptionActions.RETRIEVE_INITIAL_FINDINGS_SUCCESS:
      return {
        ...state,
        initialFindings: action.initialFindings
      }   
      
    case OptionActions.RETRIEVE_ISSUE_TYPES_SUCCESS:
      return {
        ...state,
        issueTypes: action.issueTypes
      }      
      
    case OptionActions.RETRIEVE_ICAO_HAZARD_CLASSES_SUCCESS:
      return {
        ...state,
        icaoHazardClasses: action.icaoHazardClasses
      }   
      
    case OptionActions.RETRIEVE_ISSUE_IDENTIFIERS_SUCCESS:
      return {
        ...state,
        issueIdentifiers: action.issueIdentifiers
      }      

    case OptionActions.RETRIEVE_RESTRICTED_ITEMS_SUCCESS:
      // console.log('OptionActions.RETRIEVE_RESTRICTED_ITEMS_SUCCESS');
      return {
        ...state,
        restrictedItems: action.restrictedItems
      }  
      
    case OptionActions.RETRIEVE_US_STATES_SUCCESS:
      // console.log('OptionActions.RETRIEVE_US_STATES_SUCCESS');      
      return {
        ...state,
        usStates: action.usStates
      }  
      
      case OptionActions.RETRIEVE_LETTER_MAILED_SUCCESS:
        // console.log('OptionActions.RETRIEVE_LETTER_MAILED_SUCCESS');          
        return {
          ...state,
          letterMailed: action.letterMailed
        }    
        
        case OptionActions.RETRIEVE_LETTER_RECEIVD_SUCCESS:
          // console.log('OptionActions.RETRIEVE_LETTER_RECEIVD_SUCCESS');          
          return {
            ...state,
            letterReceivd: action.letterReceivd
          }          
      
    case OptionActions.RETRIEVE_DANGEROUS_ITEMS_SUCCESS:
      // console.log('OptionActions.RETRIEVE_DANGEROUS_ITEMS_SUCCESS')
      return {
        ...state,
        dangerousItems: action.dangerousItems
      }        


    default:
      return state
  }
}

export default option
