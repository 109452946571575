import * as AirportActions from '../actions/airport'

const initialState = {
  airportDestinations: [],
  airportOrigins: []
}

const airport = (state = initialState, action) => {
  switch (action.type) {
    case AirportActions.RETRIEVE_DESTINATION_AIRPORTS_SUCCESS:
      return {
        ...state,
        airportDestinations: action.airportDestinations
      }
      case AirportActions.RETRIEVE_ORIGIN_AIRPORTS_SUCCESS:
        return {
          ...state,
          airportOrigins: action.airportOrigins
        }      
    default:
      return state
  }
}

export default airport
