import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}

const ProgressButton = ({ children, classes, disabled, submitting, ...other }) => (
  <div style={{position: 'relative'}}>
    <Button disabled={submitting || disabled} {...other}>
      {children}
      {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
    </Button>
  </div>
)

export default withStyles(styles)(ProgressButton)
