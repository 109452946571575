import * as OptionServices from '../services/option'

export const RETRIEVE_ALL_OPTIONS_SUCCESS = 'RETRIEVE_ALL_OPTIONS_SUCCESS'

export const RETRIEVE_INITIAL_FINDINGS_SUCCESS = 'RETRIEVE_INITIAL_FINDINGS_SUCCESS'

export const RETRIEVE_ISSUE_TYPES_SUCCESS = 'RETRIEVE_ISSUE_TYPES_SUCCESS'
export const RETRIEVE_ICAO_HAZARD_CLASSES_SUCCESS = 'RETRIEVE_ICAO_HAZARD_CLASSES_SUCCESS'
export const RETRIEVE_ISSUE_IDENTIFIERS_SUCCESS = 'RETRIEVE_ISSUE_IDENTIFIERS_SUCCESS'
export const RETRIEVE_RESTRICTED_ITEMS_SUCCESS = 'RETRIEVE_RESTRICTED_ITEMS_SUCCESS'
export const RETRIEVE_US_STATES_SUCCESS = 'RETRIEVE_US_STATES_SUCCESS'

export const RETRIEVE_LETTER_MAILED_SUCCESS = 'RETRIEVE_LETTER_MAILED_SUCCESS'


export const RETRIEVE_DANGEROUS_ITEMS_SUCCESS = 'RETRIEVE_DANGEROUS_ITEMS_SUCCESS'

//--Received has been intentionally misspelled as receivd because 'CUSTOMER_LETTER_RECEIVD_STATUS' is 30 characters long -- this is the maximum name length allowed by our Oracle database.

export const RETRIEVE_LETTER_RECEIVD_SUCCESS = 'RETRIEVE_LETTER_RECEIVD_SUCCESS'

/**
 * 
 * @returns 
 */
export const retrieveAllOptions = () => (dispatch, getState) => {
  const state = getState()
  const allOptions = state.option.allOptions

  if (allOptions && allOptions.length > 0) {
    return
  }

  return OptionServices.getAllOptions()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_ALL_OPTIONS_SUCCESS,
        allOptions: sortedOptions
      })
    })
}

/**
 * 
 * @returns 
 */
export const retrieveInitialFindings = () => (dispatch, getState) => {
  const state = getState()
  const initialFindings = state.option.initialFindings

  if (initialFindings && initialFindings.length > 0) {
    return
  }

  return OptionServices.getInitialFindings()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_INITIAL_FINDINGS_SUCCESS,
        initialFindings: sortedOptions
      })
    })
}

/**
 * 
 * @returns 
 */
export const retrieveIssueTypes = () => (dispatch, getState) => {
  const state = getState()
  const issueTypes = state.option.issueTypes

  if ( issueTypes && issueTypes.length > 0) {
    return
  }

  return OptionServices.getIssueTypes()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_ISSUE_TYPES_SUCCESS,
        issueTypes : sortedOptions
      })
    })
}

/**
 * 
 * @returns 
 */
export const retrieveIcaoHazardClasses = () => (dispatch, getState) => {
  const state = getState()
  const icaoHazardClasses = state.option.icaoHazardClasses

  if (icaoHazardClasses && icaoHazardClasses.length > 0) {
    return
  }

  return OptionServices.getIcaoHazardClasses()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_ICAO_HAZARD_CLASSES_SUCCESS,
        icaoHazardClasses : sortedOptions
      })
    })
}

/**
 * 
 * @returns 
 */
export const retrieveIssueIdentifiers = () => (dispatch, getState) => {
  const state = getState()
  const issueIdentifiers = state.option.issueIdentifiers

  if (issueIdentifiers && issueIdentifiers.length > 0) {
    return
  }

  return OptionServices.getIssueIdentifiers()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_ISSUE_IDENTIFIERS_SUCCESS,
        issueIdentifiers : sortedOptions
      })
    })
}

/**
 * 
 * @returns 
 */
export const retrieveRestrictedItems = () => (dispatch, getState) => {
  // console.log('action/option -- retrieveRestrictedItems');
  const state = getState()
  const restrictedItems = state.option.restrictedItems

  if (restrictedItems && restrictedItems.length > 0) {
    return
  }

  return OptionServices.getRestrictedItems()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_RESTRICTED_ITEMS_SUCCESS,
        restrictedItems: sortedOptions
      })
    })
}

/**
 * 
 * @returns 
 */
export const retrieveUsStates = () => (dispatch, getState) => {
  // console.log('action/option -- retrieveUsStates');  
  const state = getState()
  const usStates = state.option.usStates

  if (usStates && usStates.length > 0) {
    return
  }

  return OptionServices.getUsStates()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_US_STATES_SUCCESS,
        usStates: sortedOptions
      })
    })
}

/**
 * 
 * @returns 
 */
 export const retrieveLetterMailed = () => (dispatch, getState) => {
  // console.log('action/option -- retrieveLetterMailed');
  const state = getState()
  const letterMailed = state.option.letterMailed

  if (letterMailed && letterMailed.length > 0) {
    return
  }

  return OptionServices.getLetterMailed()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_LETTER_MAILED_SUCCESS,
        letterMailed: sortedOptions
      })
    })
}

/**
 * 
 * @returns 
 */
 export const retrieveLetterReceivd = () => (dispatch, getState) => {
  // console.log('action/option -- retrieveLetterReceivd');
  const state = getState()
  const letterReceivd = state.option.letterReceivd

  if (letterReceivd && letterReceivd.length > 0) {
    return
  }

  return OptionServices.getLetterReceivd()
    .then((options) => {
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_LETTER_RECEIVD_SUCCESS,
        letterReceivd: sortedOptions
      })
    })
}

/**
 * 
 * @param {*} hazclass 
 * @returns 
 */
export const retrieveDangerousItems = (hazclass) => (dispatch, getState) => {
  // console.log('[actions/option/retrieveDangerousItems] - hazclass=' + hazclass)
  const state = getState()
  const dangerousItems = state.option.dangerousItems
  // Return previously cached list.
  if (dangerousItems && dangerousItems.length > 0) {
       if (hazclass === dangerousItems[1].name){
            return
       }
  }
  
  return OptionServices.getDangerousItems(hazclass)
    .then((options) => {
      // console.log('[actions/option/retrieveDangerousItems] .then(options)', options)      
      const sortedOptions = options.slice()
      sortedOptions.sort((a, b) => {
        return a.name > b.name
      })

      dispatch({
        type: RETRIEVE_DANGEROUS_ITEMS_SUCCESS,
        dangerousItems: sortedOptions
      })
    })
}



