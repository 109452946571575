import * as HmLogActions from '../actions/hmlog'

const initialState = {
  selected_logs: []
}

const hmlog = (state = initialState, action) => {
    switch (action.type) {
        case HmLogActions.SELECTED_LOGS:
          return {
            ...state,
            selected_logs: action.selLogs
          }
          default:
            return state
    }      

}

export default hmlog


