import { getIncidentCriteria } from '../selectors/incident'
import { fetchExcelFile } from '../utilities/service-utils'
import FileSaver from 'file-saver'

export const CHANGE_FILTER = 'TABLE_CHANGE_FILTER'
export const CHANGE_PAGE = 'TABLE_CHANGE_PAGE'
export const CHANGE_ROWS_PER_PAGE = 'TABLE_CHANGE_ROWS_PER_PAGE'
export const CLEAR_ROWS = 'TABLE_CLEAR_ROWS'
export const RETRIEVE_ROWS_START = 'RETRIEVE_ROWS_START'
export const RETRIEVE_ROWS_SUCCESS = 'RETRIEVE_ROWS_SUCCESS'
export const RETRIEVE_ROWS_ERROR = 'RETRIEVE_ROWS_ERROR'
export const SORT = 'TABLE_SORT'
export const TOGGLE_FILTER = 'TABLE_TOGGLE_FILTER'

/**
 * Clear all table rows
 * @param  tableId, Ex. "incidentsTable" 
 * @returns 
 */
export const clearRows = (tableId) => {
  // console.log('table.clearRows: tableId=',tableId);  
  return {
    type: CLEAR_ROWS,
    tableId
  }
}


/**
 * 
 * @param tableId , Ex. "incidentsTable" 
 * @returns 
 */
 export const exportHazmatToExcel = (tableId) => (dispatch, getState) => {
  // console.log("actions/table -- exportHazmatToExcel", "tableId = ", tableId)
  const state = getState()
  // console.log("actions/table -- exportHazmatToExcel", "state=", state)
  const tabs = state.tabs;
  // console.log("actions/table -- exportHazmatToExcel", "tabs=", tabs)
  const tabId = tabs.incidents;
  // console.log("actions/table -- exportHazmatToExcel", "tabId=", tabId)
  const table = state.table[tableId]
  // console.log("actions/table -- exportHazmatToExcel", "table=", table)
  
  var criteria = getIncidentCriteria(state, table.filter)
  // console.log("actions/table -- exportHazmatToExcel", "criteria=",criteria)
  //dispatch({ type: RETRIEVE_ROWS_START, tableId })

  const status=criteria.status;
  // console.log("actions/table -- exportHazmatToExcel", "status=",status)

  const orderBy = table.orderBy ? table.orderBy : 'hmKey'
  const sortDirection = table.sortDirection ? table.sortDirection : 'desc'



   criteria = {
    orderBy,
    sortDirection,
    ...table.filter};
    // console.log("actions/table -- exportHazmatToExcel", "criteria=",criteria)

    if (null != status) {
      criteria.status=status;
    }
    // console.log("actions/table -- exportHazmatToExcel", "criteria=",criteria)    

  // console.log("actions/table -- exportHazmatToExcel", "criteria=", criteria)
  return fetchExcelFile('hazmat/exportHazmatsToExcel', criteria)
    .then((excelFile) => {
      FileSaver.saveAs(excelFile, 'HazardousMaterial.xls')
    })
}

/**
 * Load up the table rows
 * @param {*} tableId, Ex. "incidentsTable"  
 * @param {*} searchService 
 * @param {*} page, Ex. "0" 
 * @param {*} isRefresh, Ex. "undefined" 
 * @returns 
 */
export const loadPage = (tableId, searchService, page, isRefresh) => (dispatch, getState) => {
  // console.log('table.loadPage: tableId=',tableId,' page=',page,' isRefresh=',isRefresh);
  const state = getState()
  // console.log('table.loadPage: state=',state);
  const table = state.table[tableId]
  // console.log('table.loadPage: table=',table);
  const rows = table.rows
  const rowsPerPage = table.rowsPerPage

  const start = rowsPerPage * page
  const count = rowsPerPage

  if (!isRefresh && rows[start]) {
    // Don't try to load again if data is already loaded
    return new Promise((resolve) => resolve())
  }

  const orderBy = table.orderBy ? table.orderBy : 'hmKey'
  const sortDirection = table.sortDirection ? table.sortDirection : 'desc'

  const criteria = {
    orderBy,
    sortDirection,
    ...table.filter
  }

  dispatch({ type: RETRIEVE_ROWS_START, tableId })

  return searchService(criteria, start, count)
    .then((response) => {
      if (!response) {
        return
      }

      const newRows = rows.slice()
      const rowCount = response.rowCount || 0
      for (let i = 0; i < count && i < response.rows.length; i++) {
        newRows[i + start] = response.rows[i]
      }

      dispatch({
        type: RETRIEVE_ROWS_SUCCESS,
        tableId,
        rows: newRows,
        rowCount
      })
    })
    .catch((e) => {
      dispatch({
        type: RETRIEVE_ROWS_ERROR,
        tableId,
        errorText: e
      })
    })
}

/**
 * Change the filter criteria
 * @param {*} tableId, Ex. "incidentsTable"  
 * @param {*} columnName, Ex "airlineReportingIncident  " 
 * @param {*} value, Ex. "BA" 
 * @param {*} searchService 
 * @returns 
 */
export const changeFilter = (tableId, columnName, value, searchService) => {
  // console.log('table.changeFilter: tableId=',tableId,' columnName=',columnName,' value=',value);  
  return {
    type: CHANGE_FILTER,
    tableId,
    columnName,
    value
  }
}

/**
 * Change which page of the results to display
 * @param {*} tableId, Ex. "incidentsTable" 
 * @param {*} page, Ex. "1" 
 * @param {*} searchService 
 * @returns 
 */
export const changePage = (tableId, page, searchService) => (dispatch, getState) => {
  // console.log('table.changePage: tableId=',tableId,' page=',page);  
  dispatch(loadPage(tableId, searchService, page))
  dispatch({
    type: CHANGE_PAGE,
    tableId,
    page
  })
}

/**
 * Change the number of table rows displayed per page
 * @param {*} tableId, Ex. "incidentsTable"  
 * @param {*} rowsPerPage, Ex. 10, 5, 25
 * @param {*} searchService 
 * @returns 
 */
export const changeRowsPerPage = (tableId, rowsPerPage, searchService) => (dispatch, getState) => {
  // console.log('table.changeRowsPerPage: tableId=',tableId,' rowsPerPage=',rowsPerPage);    
  dispatch(clearRows(tableId))

  dispatch({
    type: CHANGE_ROWS_PER_PAGE,
    tableId,
    rowsPerPage
  })

  dispatch(changePage(tableId, 0, searchService))
}

/**
 * 
 * @param {*} tableId, Ex. "incidentsTable" 
 * @param {*} searchService 
 * @returns 
 */
export const refreshPage = (tableId, searchService) => (dispatch, getState) => {
  // console.log('table.refreshPage: tableId=',tableId);   
  const state = getState()
  const table = state.table[tableId]
  const page = table.page

  dispatch(clearRows(tableId))
  return dispatch(loadPage(tableId, searchService, page, true))
}

/**
 * Change table sortation
 * @param {*} tableId, Ex. "incidentsTable"  
 * @param {*} columnName 
 * @param {*} searchService 
 * @returns 
 */
export const sort = (tableId, columnName, searchService) => (dispatch, getState) => {
  // console.log('table.sort: tableId=',tableId, ' columnName',columnName); 

  const state = getState()
  const table = state.table[tableId]

  let orderBy = columnName
  let sortDirection = 'asc'

  if (table.orderBy === columnName && table.sortDirection === 'asc') {
    sortDirection = 'desc'
  } else if (table.orderBy === columnName && table.sortDirection === 'desc') {
    orderBy = ''
    sortDirection = false
  }

  dispatch(clearRows(tableId))

  dispatch({
    type: SORT,
    tableId,
    orderBy,
    sortDirection
  })

  dispatch(changePage(tableId, 0, searchService))
}

/**
 * Show/hide the Filter criteria entry
 * @param tableId , Ex. "incidentsTable" 
 * @returns 
 */
export const toggleFilter = (tableId) => {
  // console.log('table.toggleFilter: tableId=',tableId); 
  return {
    type: TOGGLE_FILTER,
    tableId
  }
}


