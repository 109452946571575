import React, { Component } from 'react'
import { compose } from 'redux'	
import { Field, reduxForm } from 'redux-form'  
import { TextField } from './ReduxFormMaterialUI'
import { Divider, Grid, Typography, withStyles, Button } from '@material-ui/core'
import { blue, grey, red } from '@material-ui/core/colors'
import * as Roles from '../constants/roles'
import PageContainer from './PageContainer'
import DatePickerField from './inputs/DatePickerField'	
import { getOnePersonById } from '../services/person'
import 'react-confirm-alert/src/react-confirm-alert.css'

//const debug = true;
const debug = false;

var initialValues={};

const styles = (theme) => ({
  addIcon: {
    color: grey[600],
    cursor: 'pointer',
    marginRight: '64px',
    '&:hover': {
      color: blue[700]
    }
  },
  cancelIcon: {
    color: grey[600],
    cursor: 'pointer',
    marginRight: '64px',
    '&:hover': {
      color: red[700]
    }
  },
  container: {
    padding: theme.spacing(2)
  },
  errorIcon: {
    color: red[500]
  },
  hidden: {
    visibility: 'hidden'
  },
  iconColumn: {
    width: '30px'
  },
  tableBodyCell: {
    verticalAlign: 'top'
  },
  tableCell: {
    padding: '12px'
  },
  tooltip: {
    fontSize: '12px'
  }
})

const today = new Date();

class ViewPerson extends Component {
  state = {
    row: '',
    rows:[],
    reason:''
  }

  handleBackClick = () => {	
    // console.log('[components/ViewPerson]  handleBackClick ')	
    const { history } = this.props	
    history.push('/adminhome')	
  }	

  componentDidMount () {
    // console.log('[components.ViewPerson.index]  componentDidMount()   today=', today)
    this.props.change('updatedDatetime', today);
    var pathname = this.props.location.pathname;    
    var personId = pathname.slice(12); //length of '/viewperson/'
    // console.log(personId);
    initialValues = getOnePersonById(personId);         
    initialValues.then((rows) => {
      // console.log('[components.ViewPerson.componentDidMount].then'); 
        // console.log('initialValues.then   rows  ',rows);
        this.setState({ row: rows})
        // console.log('initialValues.then   this.state.row  ',this.state.row);   
        this.props.change('createdDatetime', this.state.row.createdDatetime);      
      })
  }

  render() {
    // console.log('[components/ViewPerson]  render ')          
    // console.log('state=', this.state)     
    // console.log('props=', this.props)            
    const {
      classes,
      handleApprove,
      handleDeny,
      role
    } = this.props;
    // console.log('[components/ViewPerson/render]  role= ', role)  
    // console.log('[components/ViewPerson/render]  state= ', this.state)              
    // if (this.state) console.log('[components/ViewPerson/render]  state= ', this.state);    
    var row = this.state.row;
    // console.log('this.state.row',this.state.row);
    // console.log('row',row);
    return(
      <PageContainer>
        <Typography className={classes.container} variant='h5'>Carrier Registration View</Typography>
        <Divider />
        <form className={classes.container}  >
        <Grid container direction='column' spacing={5}>
            <Grid container item spacing={1}>
              <Grid item xs={12}><Typography variant='h6'>Carrier Registration</Typography></Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off'
                  variant='outlined'    
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  label={debug ?  'manageUserKey' : 'Manage User Key'}
                  name='row.manageUserKey'
                  value={row ? row.manageUserKey : ''}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off'
                  variant='outlined'
                  fullWidth
                  inputProps={{minLength: 0, maxLength: 10, inputProps: {min: 0, max: 10} }}
                  label={debug ?  'agencyAbbreviation' : 'Agency Abbreviation'}
                  name='row.agencyAbbreviation'
                  value={row ? row.agencyAbbreviation : ''}
                />
              </Grid>       
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off'
                  variant='outlined'
                  fullWidth
                  inputProps={{minLength: 0, maxLength: 40, inputProps: {min: 0, max: 40} }}
                  label={debug ?  'agencyFullName' : 'Agency Full Name'}
                  name='row.agencyFullName'
                  value={row ? row.agencyFullName : ''}
                />
              </Grid>                        
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off'
                  variant='outlined'    
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  label={debug ?  'carrierAbbreviation' : 'Carrier Abbreviation'}
                  name='row.carrierAbbreviation'
                  value={row ? row.carrierAbbreviation : ''}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off'
                  variant='outlined'
                  fullWidth
                  inputProps={{minLength: 0, maxLength: 60, inputProps: {min: 0, max: 60} }}
                  label={debug ?  'carrierFullName' : 'Carrier Full Name'}
                  name='row.carrierFullName'
                  value={row ? row.carrierFullName : ''}
                />
              </Grid> 
              <Grid item xs={12} md={4}>
                <TextField
                disabled
                  autoComplete='off'
                  variant='outlined'    
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  label={debug ?  'userId' : 'User Id'} 
                  name='userId'
                  value={row ? row.userId : ''}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                disabled
                  autoComplete='off'
                  variant='outlined'                     
                  fullWidth
                  inputProps={{ maxLength: 72 }}
                  label={debug ?  'contactEmail' : 'Contact Email'}
                  name='contactEmail'
                  value={row ? row.contactEmail : ''}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  disabled
                  component={DatePickerField}
                  fullWidth
                  variant='outlined'
                  label={debug ?  'createdDatetime' : 'Date Created'}
                  name='createdDatetime'
                  props={{value:row ? row.createdDatetime : ''}}
                />
              </Grid>              
              <Grid item xs={12} md={4}>
                <Field
                  disabled 
                  component={DatePickerField}	                 
                  fullWidth
                  variant='outlined'                    
                  label={debug ?  'updatedDatetime' : 'Date Updated'}
                  name='updatedDatetime'
                  props={{value:row ? row.updatedDatetime : ''}}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off' 
                  variant='outlined'                     
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  label={debug ?  'contactName' : 'Contact Name'}
                  name='contactName'
                  value={row ? row.contactName : ''}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                disabled
                  autoComplete='off'  
                  variant='outlined'                    
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  label={debug ?  'contactPhone' : 'Contact Phone'}
                  name='contactPhone'
                  value={row ? row.contactPhone : ''}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off'                  
                  variant='outlined'  
                  fullWidth
                  label={debug ?  'approved' : 'Approval Status'}
                  name='approved'
                  value={!row ? '' : debug ? row.approved : (0===row.approved) ? 'Pending': (1===row.approved) ? 'Approved' : (0 > row.approved) ? 'Denied' :'Invalid' }                  
                 readOnly={true}
                />
              </Grid>              
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off' 
                  variant='outlined'                     
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  label={debug ?  'approvedBy' : 'Approved By'}
                  name='approvedBy'
                  value={!row ? '' :  (null === row.approvedBy ) ? '' : row.approvedBy }
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField 
                  disabled
                  variant='outlined'                   
                  fullWidth
                  label={debug ?  'approvedDate' : 'Date Approved'}
                  name='approvedDate'
                  value={row ? row.approvedDate : ''}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  autoComplete='off' 
                  variant='outlined'                     
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  label={debug ?  'deniedBy' : 'Denied By'}
                  name='deniedBy'
                  value={!row ? '' :  (null === row.deniedBy ) ? '' : row.deniedBy }
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField 
                  disabled
                  variant='outlined'                   
                  fullWidth
                  label={debug ?  'deniedDate' : 'Date Denied'}
                  name='deniedDate'
                  value={row ? row.deniedDate : ''}
                  readOnly={true}
                />
              </Grid>  
              <Grid item xs={12} md={4}>
                <TextField 
                  disabled
                  variant='outlined'                   
                  fullWidth
                  label={debug ?  'deniedReason' : 'Reason Denied'}
                  name='deniedReason'
                  value={row ? row.deniedReason : this.state.reason ? this.state.reason : ''}
                  readOnly={true}
                />
              </Grid>    
              <Grid item xs={12} md={4}>
                <TextField 
                  disabled
                  variant='outlined'                   
                  fullWidth
                  label={debug ?  'remarksCode' : 'Remarks'}
                  name='remarksCode'
                  value={row ? row.remarksCode : ''}
                  readOnly={true}
                />
              </Grid>    
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  variant='outlined'
                  fullWidth
                  label={debug ?  'entityType' : 'Entity Type'}
                  name='entityType'
                  value={row ? row.entityType : ''}
                  readOnly={true}
                />
              </Grid>                                                    
            </Grid>
          </Grid>

          { (role === Roles.DANGEROUS_GOODS_ADMIN) &&
          <Button variant='contained' color="inherit" onClick={() => handleApprove(this.state.row.manageUserKey).then(() => this.handleBackClick())} >Approve</Button> 
        }
                  { (role === Roles.DANGEROUS_GOODS_ADMIN) &&
         <Button variant='contained' color="inherit" onClick={() => {
            var reason=prompt ('Reason for denial', '');  
            // console.log('reason',reason); 
            this.setState({reason:reason, row:{...row,deniedReason:reason}});
            handleDeny(this.state.row.manageUserKey,reason).then(() => this.handleBackClick());}} >Deny</Button> 
  }
        </form>
        <Button variant='contained' color="inherit" onClick={this.handleBackClick}>Back to admin</Button> 
      </PageContainer>
    )
         
  }
}

export default compose(	
  reduxForm({	
    form: 'view-person'
  }),	
  withStyles(styles)	
)(ViewPerson)
