import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

const configureStore = (rootReducer) => {
  const store = createStore(
    rootReducer,
    undefined,
    applyMiddleware(thunk)
  )

  return store
}

export default configureStore
