import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'  
import { TextField } from './ReduxFormMaterialUI'
import * as Roles from '../constants/roles'
import Grid from '@material-ui/core/Grid'
import { ThemeProvider, Typography } from '@material-ui/core'
import * as IncidentActions from '../actions/incident'
import { withStyles } from '@material-ui/core/styles'
import { BorderBottom } from '@material-ui/icons'



const styles = (theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  item: {
    width: '100%' // Fix chrome issue where hovering over items changes width of flexbox
  },
  note: {
    width: '100%',
    backgroundColor: 'lightyellow',
    border: 'solid'
  },
  closed: {
    width: '100%',
    height: '50px',
    backgroundColor: 'lightgreen',
    border: 'solid'
  },
  indSubmit: {
    width: '100%',
    height: '50px',
    backgroundColor: 'violet',
    border: 'solid',
    borderColor: 'darkblue',
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px'
  },
  agent: {
    width: '100%',
    height: '50px',
    backgroundColor: 'lightgreen',
    border: 'solid',
    borderColor: 'darkblue',
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px'
        
  },
  response: {
    width: '100%',
    height: '50px',
    backgroundColor: 'LightPink',
    border: 'solid',
    borderColor: 'darkblue',
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px'
  },
  decision: {
    width: '100%',
    height: '50px',
    backgroundColor: 'aqua',
    border: 'solid',
    borderColor: 'darkblue',
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px'
  },
  grayBox: {
    width: '100%',
    height: '50px',
    backgroundColor: 'lightgray',
    border: 'solid',
    opacity: 0.3
  },
  dz: {
    height: '100px',
    width: '100px',
    borderColor: 'white',
    align: 'left'
  },
  image: {
    padding: '0 4px'
  }
})

function getStage (worknotes, status, response, mailedStatus, dateMailed) {
  var letterSent = false
  var pastMonth = false
  var wnNum = (worknotes || []).length

  var today = new Date()
  var mailed = new Date(dateMailed)
  mailed.setDate(mailed.getDate() + 30)

  if(today >= mailed) {
    pastMonth = true
  }

  if (mailedStatus === '1' || mailedStatus === '-1') {
    letterSent = true
  }

  console.log('DATEMAILED**** ' + mailed + ' PASTMONTH**** ' + pastMonth + ' TODAY**** ' + today) 
  
  if (status === 'CLOSED') {
    return 5
  }
  if (status === 'INPROCESS' && letterSent === true && response === '1') {
    return 4
  }
  if (status === 'INPROCESS' && letterSent === true && response === '0' && pastMonth === false) {
    return 3
  }
  if (status === 'INPROCESS' && wnNum > 0) {
    return 2
  }
    if (status === 'INPROCESS' && wnNum === 0) {
    return  1
  }
  return 0
}

class WorkNotes extends Component {
  
  componentDidMount () {
    const { fetchWorkNotes, hmKey} = this.props
    fetchWorkNotes(hmKey)
    
  }
  
  render () {
    const {
      classes,
      role
    } = this.props

    return (
      <div>
        <Grid container direction='column' >
          <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1'>Work Notes</Typography>
            </Grid>
          </Grid>
          {this.props.role !== Roles.CARRIER && this.props.status !== 'CLOSED' ? 
            <div>
              {/* <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                <Grid item xs={12} md={6}>
                  <Typography variant='subtitle1'>Work Notes</Typography>
                </Grid>
              </Grid> */}
              <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                <Grid item xs={12} md={6}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    label='work note'
                    variant='outlined'
                    name='workNote'
                    onChange={(event) => this.props.updateWorkNote(event.target.value)}
                  />
                </Grid> 
              </Grid>
            </div>
          :
          ''}
          {/* {this.props.role === Roles.CARRIER ? 
            <div>
              <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                <Grid item xs={12} md={6}>
                  <Typography variant='subtitle1'>Incident Progress</Typography>
                </Grid>
              </Grid>
             </div>
          :
          ''}
          {/* <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
            <Grid item xs={12} md={6}>
              <TextareaAutosize
                defaultValue="TEST......................................................"
              />
            </Grid> 
          </Grid> */}
          {this.props.role !== Roles.CARRIER ? 
            <div>
              <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                <Grid item xs={12} md={6}>
                  <div className={classes.note}>
                    {this.props.worknotes.map ?
                      this.props.worknotes.map((note) => (
                        <p><strong>User:</strong>&nbsp;&nbsp;{note.updtUserId}&nbsp;&nbsp;
                          <strong>Date:</strong>&nbsp;&nbsp;{note.updtDate}&nbsp;&nbsp;
                          <strong>Work Note:</strong>&nbsp;&nbsp;{note.workNote}</p>
                      ))
                      : ''
                    }
                  </div>
                </Grid>
              </Grid>
            </div>
            :
            ''}
          {this.props.role === Roles.CARRIER  ? 
            <div>
              <Grid container direction='row' justify='flex-start' alignment='center' item spacing={1}> 
                <Grid item xs={12} md={2}>
                  {getStage(this.props.worknotes,   
                            this.props.status,
                            this.props.response,
                            this.props.mailedStatus,
                            this.props.dateMailed) >= 1 ?
                    <div className={classes.indSubmit} >
                      <Typography variant='h6' align='center'>Incident Submitted   &#10003;</Typography> 
                    </div>
                    :
                    <div className={classes.grayBox} >
                    <Typography variant='subtitle2' align='center'>Incident Submitted} </Typography> 
                    </div>
                  }
                </Grid>
                <Grid item xs={12} md={2}>
                  {getStage(this.props.worknotes,
                            this.props.status,
                            this.props.response,
                            this.props.mailedStatus,
                            this.props.dateMailed) 
                            >= 2 ?
                    <div className={classes.agent} >
                      <Typography variant='h6' align='center'>Processed by Agent   &#10003;</Typography>
                    </div>
                    :
                    <div className={classes.grayBox} >
                      <Typography variant='subtitle2' align='center'>Processed by Agent</Typography> 
                    </div>
                  } 
                 </Grid>
                <Grid item xs={12} md={2}>
                  {getStage(this.props.worknotes,
                             this.props.status,
                             this.props.response,
                             this.props.mailedStatus,
                             this.props.dateMailed)
                             >= 3 ?
                    <div className={classes.response} >
                      <Typography variant='h6' align='center'>Awaiting Response   &#10003;</Typography> 
                    </div>
                    :
                    <div className={classes.grayBox} >
                      <Typography variant='subtitle2' align='center'>Awaiting Response</Typography> 
                    </div>
                  } 
                </Grid>
                <Grid item xs={12} md={2}>
                  {getStage(this.props.worknotes,
                            this.props.status,
                            this.props.response,
                            this.props.mailedStatus,
                            this.props.dateMailed)
                            >= 4 ?
                    <div className={classes.decision} >
                      <Typography variant='h6' align='center'>Decision Made   &#10003;</Typography> 
                    </div>
                    :
                    <div className={classes.grayBox} >
                      <Typography variant='subtitle2' align='center'>Decision Made</Typography> 
                    </div>
                  } 
                </Grid>
                <Grid item xs={12} md={2}>
                  {getStage(this.props.worknotes,
                            this.props.status,
                            this.props.response,
                            this.props.mailedStatus,
                            this.props.dateMailed) 
                   >= 5 ?
                    <div className={classes.closed} >
                      <Typography variant='h6' align='center'>Closed   &#10003;</Typography> 
                    </div>
                    :
                    <div className={classes.grayBox} >
                      <Typography variant='subtitle2' align='center'>Closed</Typography> 
                    </div>
                  } 
                  
                </Grid>
              </Grid>
            </div>
          : ''}
        </Grid>
      </div>
     
    )
  }
}

const mapStateToProps = (state) => {
  const role = state.user.role
  const status = state.incident.selected_incident.status
  const response = state.incident.selected_incident.customerLetterResponseStatus
  const mailedStatus = state.incident.selected_incident.customerLetterMailedStatus
  const dateMailed =  state.incident.selected_incident.customerLetterMailedDate
  const worknotes = state.incident.current_worknotes || ''
      
  return {
    worknotes,
    role,
    response,
    mailedStatus,
    dateMailed,
    status
  }
}

const mapDispatchToProps = (dispatch) => {
  const updateWorkNote = (worknote) => {
    dispatch(IncidentActions.updateWorkNote(worknote))

  }
  const fetchWorkNotes = (hmKey) => {
    dispatch(IncidentActions.retrieveWorkNotes(hmKey))
  }
  return {
    updateWorkNote,
    fetchWorkNotes
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(WorkNotes)
