import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { TextField } from './ReduxFormMaterialUI'

import { indigo } from '@material-ui/core/colors'
import red from '@material-ui/core/colors/red'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import PageContainer from './PageContainer'
import ProgressButton from './buttons/ProgressButton'

import { upper } from '../utilities/normalizers'


const styles = {
  buttonWrapper: {
    alignSelf: 'flex-start'
  },
  container: {
    padding: '24px'
  },
  error: {
    color: red[500]
  },
  item: {
    margin: '8px 0'
  },
  username: {
  }
}

function lettersNumbersCheck(name)
{
   var regEx = /^[0-9a-zA-Z_]+$/;
   if(name.match(regEx))
     {
      return true;
     }
   else
     {
     //alert("Please enter letters and numbers only.");
     return false;
     }
}  

class LoginExternal extends Component {

  render () {
    const { classes, errorMessage, handleSubmit, onFieldChange, submitting } = this.props
                          // Component.Login.props.handleSubmit gets mapped by redux-form to Container.LoginPage.onSubmit

    return (
      <PageContainer>

        <form className={classes.container} onSubmit={handleSubmit}>

          <Grid container direction='column' spacing={0}>
            <Typography color='error'>{errorMessage}</Typography>
            <Typography variant='h6'>Log In External</Typography>    
            <Field
              className={classes.item}
              component={TextField}
              InputProps={{inputProps: {className: classes.username}}}
              label='Username'
              name='userId'
              onChange={onFieldChange}
              normalize={upper}
            />

            <Field
              className={classes.item}
              component={TextField}
              label='Password'
              name='password'
              onChange={onFieldChange}
              type='password'
            />
            <Grid className={classes.buttonWrapper} item>
              <ProgressButton
                className={classes.item}
                color='primary'
                submitting={submitting}
                type='submit'
                variant='contained'
              >
                Submit
              </ProgressButton>
            </Grid>
            <Grid item xs={12} md={4}>	
          {/* <Typography >
          <br></br>
            <br></br>
            <em>Don't have an account yet?</em>
            <br></br>
              <Link
                style={{ color: indigo[300] }}
                to={'/carriersignup'}
              >
                <em><b>Signup for an account</b></em>
              </Link>
          </Typography> */}
        </Grid>   
          </Grid>
        </form>

   
      </PageContainer>
    )
  }
}

const validate = (values) => {
  const errors = {}
  const requiredFields = [ 'userId', 'password' ]

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  if (values['userId'] && 0 < values['userId'].length && !lettersNumbersCheck(values['userId'])) {
    errors['userId'] = 'Must be alphanumeric'
  }

  return errors
}

export default compose(
  reduxForm({
    form: 'login',
    initialValues: { source: 'external' },
    validate
  }),
  withStyles(styles)
)(LoginExternal)
