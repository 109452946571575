export const CLOSE_ERROR_DIALOG = 'CLOSE_ERROR_DIALOG'
export const OPEN_ERROR_DIALOG = 'OPEN_ERROR_DIALOG'

/**
 * Action Creator -- makes CLOSE_ERROR_DIALOG Action
 * @returns 
 */
export const closeErrorDialog = () => {
  return {
    type: CLOSE_ERROR_DIALOG
  }
}

/**
 * Action Creator -- makes OPEN_ERROR_DIALOG Action
 * @param {*} param0 
 * @returns 
 */
export const openErrorDialog = ({ errorText, title }) => {
  return {
    type: OPEN_ERROR_DIALOG,
    errorText,
    title
  }
}
