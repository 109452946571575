import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const AuthRedirect = ({ children, isLoggedIn }) => {
  return isLoggedIn ? children : <Redirect to='/login' />
}

const mapStateToProps = (state) => {
  //console.log('mapStateToProps state=')
  const isLoggedIn = state.user.userId !== null && state.user.userId !== undefined

  return {
        isLoggedIn 
  }
}

const ConnectedAuthRedirect = connect(mapStateToProps)(AuthRedirect)

const createAuthRedirect = (Component) => {
  return (props) => <ConnectedAuthRedirect><Component {...props} /></ConnectedAuthRedirect>
}

export default createAuthRedirect
