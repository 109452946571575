export const OPEN = 'OPEN'
export const CLOSE = 'CLOSE'

/**
 * 
 * @param {*} name 
 * @returns 
 */
export const open = (name) => {
  return {
    type: OPEN,
    name
  }
}

/**
 * 
 * @param {*} name 
 * @returns 
 */
export const close = (name) => {
  return {
    type: CLOSE,
    name
  }
}
