import { getResource } from '../utilities/service-utils'

export const getAllOptions = () => {
  return getResource('option/all')
}

export const getInitialFindings = () => {
  return getResource('option/initialFindings')
}


export const getIssueTypes = () => {
  return getResource('option/issueTypes')
}

export const getIcaoHazardClasses = () => {
  return getResource('option/icaoHazardClasses')
}

export const getIssueIdentifiers = () => {
  return getResource('option/issueIdentifiers')
}

export const getRestrictedItems = () => {
  // console.log('services/option -- getRestrictedItems');
  return getResource('option/restrictedItems')
}

export const getUsStates = () => {
  return getResource('option/usStates')
}

export const getLetterMailed = () => {
  return getResource('option/letterMailedOptions')
}

//--Received has been intentionally misspelled as receivd because 'CUSTOMER_LETTER_RECEIVD_STATUS' is 30 characters long -- this is the maximum name length allowed by our Oracle database.
export const getLetterReceivd = () => {
  return getResource('option/letterReceivedOptions')
}


export const getDangerousItems = (hazclass) => {
  // console.log('[services/option/getDangerousItems]  hazclass = ' + hazclass)
  // return getResource('option/dangerousItems?hazclass='+'Class 1')  
  // return getResource('option/dangerousItems/'+'Class 1')   
  return getResource(`option/dangerousItems/${hazclass}`)  
}

// export const getDangerousItems = () => {
//   return getResource('option/allDangerous')
// }

