import { connect } from 'react-redux'	
import { withRouter } from 'react-router-dom'	

import * as PersonActions from '../actions/person'	
import * as SnackbarActions from '../actions/snackbar'	
import CreatePerson from '../components/CreatePerson'	

const mapStateToProps = (state) => {	
  const snackbarOpen = state.snackbars.createPerson	
  const snackbarFail = state.snackbars.createPersonFail	
  return {	
    initialValues: {	
    },	
    snackbarOpen,
    snackbarFail
  }	
}	

const mapDispatchToProps = (dispatch,ownProps) => {	
  // console.log("[containers/CreatePerson] mapDispatchToProps")


  const onBackClick = () => {	
    // console.log('[containers/CreatePerson]  onBackClick - ownProps='.ownProps)	    
    ownProps.history.push('/adminhome')	
  }	

  const onSnackbarClose = () => {	
    // console.log('[containers/CreatePerson]  onSnackbarClose')	
    dispatch(SnackbarActions.close('createPerson'))	
    // console.log('[containers/CreatePerson]  onSnackbarClose -- call onBackClick')
    onBackClick();
  }	

  const onSnackbarFailClose = () => {	
    // console.log('[containers/CreatePerson]  onSnackbarFailClose')	
    dispatch(SnackbarActions.close('createPersonFail'))	
  }	

  const onSubmit = (values,event) => {	
    // console.log('[containers/CreatePerson]  onSubmit   event=',event)	    
    // event.preventDefault();
    // console.log('[containers/CreatePerson]  onSubmit   values=',values)	
    return dispatch(PersonActions.createPersonSubmit(values))	
  }	

  return {	
    onBackClick,	
    onSnackbarClose,
    onSnackbarFailClose,	
    onSubmit	
  }	
}	

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreatePerson))