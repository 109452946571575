import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import * as UserActions from '../actions/user'
import option from './option'
import airport from './airport'
import country from './country'
import carrier from './carrier'
import location from './location'
import errors from './errors'
import snackbars from './snackbars'
import table from './table'
import tabs from './tabs'
import user from './user'
import incident from './incident'
import hmlog from './hmlog'
import person from './person'

const appReducer = combineReducers({
  option,
  airport,
  country,
  carrier,
  location,
  errors,
  snackbars,
  table,
  tabs,
  user,
  incident,
  hmlog,
  person,
  form: formReducer
})

const rootReducer = (state, action) => {
  if (action.type === UserActions.LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
