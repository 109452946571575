import * as AgentServices from '../services/agent'	
import * as ErrorActions from './error'	
import * as SnackbarActions from './snackbar'	


export const FIELD_CHANGE = 'FIELD_CHANGE'

/**
 * 
 * @param {*} name 
 * @param {*} value 
 * @returns 
 */
export const handleFieldChange = (name, value) => {
  return {
    type: FIELD_CHANGE,
    name,
    value
  }
}

export const SELECT_AGENT = 'SELECT_AGENT'

/**
 * 
 * @param {*} agent 
 * @returns 
 */
export const selectAgent = (agent) => {
  return {
    type: SELECT_AGENT,
    agent
  }
}


//NOT USED
// export const DENY_AGENT_SUCCESS = 'DENY_AGENT_SUCCESS'	


// // Action bound to redux form ViewAgent	
// export const denyAgent = (...values) => (dispatch, getState) => {	
//   // console.log('[actions/agent/AgentActions]  denyAgent  values = ', ...values)	
//   const state = getState()
//   // console.log('[actions/agent/AgentActions]  denyAgent  state = ', state)	 
//   // console.log('[actions/agent/AgentActions]  denyAgent  state.user = ', state.user)	 
//   // console.log('[actions/agent/AgentActions]  denyAgent  state.user.userId = ', state.user.userId)  

//   return AgentServices.denyAgent(...values,state.user.userId)	
//     .then(() => {	
//       dispatch(SnackbarActions.open('denyAgent'))	
//     })	
//     .then(() => {
//       dispatch({
//         type: DENY_AGENT_SUCCESS
//       })
//       })	    
//     .catch((e) => {	
//       dispatch(ErrorActions.openErrorDialog({ errorText: e, title: 'Could not deny agent' }))	
//     })	
// }


//NOT USED
// export const APPROVE_AGENT_SUCCESS = 'APPROVE_AGENT_SUCCESS'	


// // Action bound to redux form ViewAgent	
// export const approveAgent = (values) => (dispatch, getState) => {	
//   // console.log('[actions/agent/AgentActions]  approveAgent  values = ', values)	
//   const state = getState()
//   // console.log('[actions/agent/AgentActions]  approveAgent  state = ', state)	 
//   // console.log('[actions/agent/AgentActions]  approveAgent  state.user = ', state.user)	 
//   // console.log('[actions/agent/AgentActions]  approveAgent  state.user.userId = ', state.user.userId)	
//   return AgentServices.approveAgent(values,state.user.userId)	
//     .then(() => {	
//       dispatch(SnackbarActions.open('approveAgent'))	
//     })
//     .then(() => {
//       dispatch({
//         type: APPROVE_AGENT_SUCCESS
//       })
//       })		
//     .catch((e) => {	
//       dispatch(ErrorActions.openErrorDialog({ errorText: e, title: 'Could not approve agent' }))	
//     })	
// }

export const SAVE_AGENT_SUCCESS = 'SAVE_AGENT_SUCCESS'	

// Action bound to redux form CreateAgent	
export const createAgentSubmit = (values) => (dispatch, getState) => {	
  // console.log('[actions/agent/AgentActions]  createAgentSubmit  values = ', values)	
  //const state = getState()
  // console.log('[actions/agent/AgentActions]  createAgentSubmit  state = ', state)	
 
  return AgentServices.createAgent(values)	
    .then((res) => {	
      // console.log('actions.createAgentSubmit -- services.createAgent .then(res) res=',res)
      if ('success'===res.status) dispatch(SnackbarActions.open('createAgent'));
      else	dispatch(SnackbarActions.open('createAgentFail'));
    })
    .then((res) => {
      // console.log('actions.createAgentSubmit -- createAgent.then.Snackbar open .then(res) res=',res)      
      dispatch({
        type: SAVE_AGENT_SUCCESS
      })
      })	
    .catch((e) => {	
      const errObj=JSON.parse(e);
      // console.log('received error message:', errObj);
      dispatch(ErrorActions.openErrorDialog({ errorText: errObj.message, title: 'Could not create agent' }))	
    })	
}

//Not used
// export const RETRIEVE_ALL_AGENTS_SUCCESS = 'RETRIEVE_ALL_AGENTS_SUCCESS'

// export const retrieveAllAgents = () => (dispatch) => {
//   // console.log('[actions/agent/AgentActions]  retrieveAllAgents  id=' )	  
//   return AgentServices.getAllAgents()
//   .then(res => {
//     dispatch({
//       type: RETRIEVE_ALL_AGENTS_SUCCESS,
//       agents: res
//     })
//     })
//   }

//Not used
// export const RETRIEVE_ONE_AGENT_SUCCESS = 'RETRIEVE_ONE_AGENT_SUCCESS'

// export const retrieveOneAgentByName = (name) => (dispatch, name) => {
//   // console.log('[actions/agent/AgentActions]  retrieveOneAgentByName'+ name)	  
//   return AgentServices.getOneAgentByName(name)
//   .then(res => {
//     dispatch({
//       type: RETRIEVE_ONE_AGENT_SUCCESS,
//       agent: res
//     })
//     })
//   }

//Not used
//     export const RETRIEVE_ONE_AGENT_BY_ID_SUCCESS = 'RETRIEVE_ONE_AGENT_BY_ID_SUCCESS'

// export const retrieveOneAgentById = (dispatch) => (id) => {
//   // console.log('[actions/agent/AgentActions]  retrieveOneAgentById'+ id)	  
//   return AgentServices.getOneAgentById(id)
//   .then(res => {
//     dispatch({
//       type: RETRIEVE_ONE_AGENT_BY_ID_SUCCESS,
//       agent: res
//     })
//   })
//     }    


