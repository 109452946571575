import React, { Component } from 'react'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { TextField } from '../ReduxFormMaterialUI'
import { Button, Divider, Grid, Typography, withStyles } from '@material-ui/core'
import { blue, grey, red } from '@material-ui/core/colors'
import Snackbar from '@material-ui/core/Snackbar'
import PageContainer from '../PageContainer'
import ProgressButton from '../buttons/ProgressButton'
import DatePickerField from '../inputs/DatePickerField'
import { upper } from '../../utilities/normalizers'

const styles = (theme) => ({
  addIcon: {
    color: grey[600],
    cursor: 'pointer',
    marginRight: '64px',
    '&:hover': {
      color: blue[700]
    }
  },
  cancelIcon: {
    color: grey[600],
    cursor: 'pointer',
    marginRight: '64px',
    '&:hover': {
      color: red[700]
    }
  },
  container: {
    padding: theme.spacing(2)
  },
  errorIcon: {
    color: red[500]
  },
  hidden: {
    visibility: 'hidden'
  },
  iconColumn: {
    width: '30px'
  },
  tableBodyCell: {
    verticalAlign: 'top'
  },
  tableCell: {
    padding: '12px'
  },
  tooltip: {
    fontSize: '12px'
  }
})

const debug = false;
const today = new Date();

function lettersNumbersSpacesCheck(name)
{
  // console.log('lettersNumbersSpacesCheck', name);  
   var regEx = /^[0-9a-zA-Z_ ]+$/;
   if(name.match(regEx))
     {
      return true;
     }
   else
     {
     //alert("Please enter letters and numbers only.");
     return false;
     }
} 

function lettersNumbersCheck(name)
{
  // console.log('lettersNumbersCheck', name);
   var regEx = /^[0-9a-zA-Z_]+$/;
   if(name.match(regEx))
     {
      return true;
     }
   else
     {
     //alert("Please enter letters and numbers only.");
     return false;
     }
}  

function domesticUsPhoneCheck(name)
{
  var regEx = /^[0-9-( )x]+$/;
  if(name.match(regEx))
    { return true; }
  else      //alert("Please enter numbers parens or dash only");
    { return false; }
} 

class CreateAgent extends Component {
  componentDidMount = () => {
    // console.log('[components/CreateAgent]  componentDidMount   today=', today)
    this.props.change('createdDatetime', today);
    this.props.change('updatedDatetime', today);
    this.props.change('entityType', 'Agent');
  }
  componentDidUpdate = () => {
    // console.log('[components/CreateAgent]  componentDidUpdate   today=', today)
    this.props.change('createdDatetime', today);
    this.props.change('updatedDatetime', today);
    this.props.change('entityType', 'Agent');
  }
  handleBackClick = () => {
    // console.log('[components/CreateAgent]  handleBackClick ')
    const { history } = this.props
    history.push('/adminhome')
  }
  render() {
    // console.log('[components/CreateAgent]  render ')
    // console.log('state=', this.state)
    // console.log('props=', this.props)    
    const {
      asyncValidating,
      classes,
      handleSubmit,
      onSnackbarClose,
      onSnackbarFailClose,      
      reset,
      snackbarOpen,
      snackbarFail,
      submitting
    } = this.props
    // console.log('[components/CreateAgent]  render - this=',this)
    return (
      <PageContainer>
        <Typography className={classes.container} variant='h5'>Agency Signup Page</Typography>
        <Divider />
          <form className={classes.container} onSubmit= {handleSubmit}>                                          
            <Grid container direction='column' spacing={5}>
              <Grid container item spacing={1}>
                <Grid item xs={12}><Typography variant='h6'>Agent Registration</Typography></Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    variant='outlined'                
                    inputProps={{minLength: 2, maxLength: 10, inputProps: {min: 2, max: 10} }}
                    label={debug ?  'agencyAbbreviation' : 'Agency Abbreviation'}
                    name='agencyAbbreviation'
                    normalize={upper}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    variant='outlined'                
                    inputProps={{maxLength: 40, inputProps: {max: 40} }}
                    label={debug ?  'agencyFullName' : 'Agency Full Name'}
                    name='agencyFullName'
                    normalize={upper}
                  />
                </Grid>  
                <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    variant='outlined'
                    inputProps={{ minLength: 8, maxLength: 50, inputProps: {min: 8, max: 50} }}
                    label={debug ?  'userId' : 'User ID'} 
                    name='userId'
                    normalize={upper}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    variant='outlined'
                    inputProps={{ minLength: 8, maxLength: 50 }}
                    label={debug ?  'userPwd' : 'User Password'}
                    name='userPwd'
                    type='password'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    variant='outlined'
                    inputProps={{ minLength: 8, maxLength: 50  }}
                    label={debug ?  'confirmUserPwd' : 'Confirm User Password'}
                    name='confirmUserPwd'
                    type='password'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    type='email'
                    variant='outlined'
                    inputProps={{ maxLength: 72 }}
                    label={debug ?  'contactEmail' : 'Contact Email'}
                    name='contactEmail'
                  />
                </Grid>



                <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    variant='outlined'
                    inputProps={{ maxLength: 20 }}
                    label={debug ?  'contactName' : 'Contact Name'}
                    name='contactName'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    type='tel'
                    variant='outlined'
                    inputProps={{ maxLength: 20 }}
                    label={debug ?  'contactPhone' : 'Contact Phone'}
                    name='contactPhone'
                  />
                </Grid>
              </Grid> 

              <Field
                    type='hidden'
                    disabled
                    component={DatePickerField}
                    name='createdDatetime'
                    style={{ height: 0 }}
                  />
                  <Field
                    type='hidden'
                    disabled
                    component={DatePickerField}
                    name='updatedDatetime'
                    style={{ height: 0 }}
                  />
                  <Field
                    type='hidden'
                    autoComplete='off'
                    component={TextField}
                    inputProps={{ maxLength: 10 }}
                    name='entityType'
                    disabled
                    style={{ height: 0 }}
                  />

              <Grid justify='flex-end' container item spacing={2}> 
                <Grid item>
                  <Button onClick={this.handleBackClick} variant='contained'>Back to Login</Button>
                </Grid>
                <Grid item>
                  <Button onClick={reset} variant='contained'>Reset</Button>
                </Grid> 
                <Grid item>
                  <ProgressButton
                    color='primary'
                    submitting={submitting || asyncValidating === true}
                    type='submit'
                    variant='contained'               
                    >
                    Submit
                  </ProgressButton>
                </Grid>
              </Grid>
           </Grid> 
        </form>
        <Snackbar
        //  autoHideDuration={5000}
          message='Your request has been submitted and is pending approval.'
          onClose={onSnackbarClose}
          open={snackbarOpen}
        /> 
        <Snackbar
        //  autoHideDuration={5000}
          message='Request failed.  This UserID is already requested or in use.'
          onClose={onSnackbarFailClose}
          open={snackbarFail}
        />  
      </PageContainer >
    )
  }
}

const getInitialStartDate = () => {
  let date = new Date()
  return date
}

const initialValues = {
  startDate: getInitialStartDate().toISOString()
}

const validate = (values) => {
  // console.log('validate')
  const errors = {}
  const requiredFields = ['agencyAbbreviation', 'agencyFullName', 'userId', 'userPwd', 'confirmUserPwd', 'contactEmail', 'contactName', 'contactPhone']
  requiredFields.forEach((field) => {
    if (values[field] === '' || values[field] === null || values[field] === undefined) {
      errors[field] = 'Required'
    }
  })
  // console.log('userPwd',values['userPwd']);
  // console.log('userPwd',values['confirmUserPwd']);
  if (values['userPwd'] !== values['confirmUserPwd']) {
    errors['userPwd'] = 'Passwords must match'
    errors['confirmUserPwd'] = 'Passwords must match'
  }
  if (values['agencyAbbreviation'] && (values['agencyAbbreviation'].length < 2 || values['agencyAbbreviation'].length > 10)) {
     errors['agencyAbbreviation'] = 'Must be 2 to 10 letters'
  }
  if (values['agencyAbbreviation'] && 0 < values['agencyAbbreviation'].length && !lettersNumbersCheck(values['agencyAbbreviation'])) {
    errors['agencyAbbreviation'] = 'Must be alphanumeric'
  }  
  if (values['agencyFullName'] && (4 > values['agencyFullName'].length)) {
    errors['agencyFullName'] = 'Too short'
 }
 if (values['agencyFullName'] && 0 < values['agencyFullName'].length && !lettersNumbersSpacesCheck(values['agencyFullName'])) {
   errors['agencyFullName'] = 'Must be alphanumeric'
 }  
  if (values['userId'] && values['userId'].length < 8) {
    errors['userId'] = 'Must be at least 8 characters'
  }
  if (values['userId'] && 0 < values['userId'].length && !lettersNumbersCheck(values['userId'])) {
    errors['userId'] = 'Must be alphanumeric'
  }
  if (values['userPwd'] && values['userPwd'].length < 8) {
    errors['userPwd'] = 'Must be at least 8 characters'  
  }

 if (values['contactName'] && 0 < values['contactName'].length && !lettersNumbersSpacesCheck(values['contactName'])) {
   errors['contactName'] = 'Must be alphanumeric'
 }  

 var contactPhoneText = values['contactPhone'];
//  console.log('contactPhoneText=',contactPhoneText)
 if ((undefined === contactPhoneText) || (null===contactPhoneText) || (''===contactPhoneText)) { /*OK*/ }
 else if ((10>contactPhoneText.length) || (25<contactPhoneText.length) || (!domesticUsPhoneCheck(contactPhoneText))) {
   errors['contactPhone'] =  'Please enter a valid domestic US phone format'
 }








  return errors
}

export default compose(
  reduxForm({
    form: 'CreateAgent',
    initialValues,
    validate
  }),
  withStyles(styles)
)(CreateAgent)