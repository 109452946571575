import React from 'react'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      margin: '16px'
    }
  }
})

const PageContainer = ({ classes, children, ...other }) => (
  <Paper className={classes.container} elevation={3} {...other}>
    {children}
  </Paper>
)

export default withStyles(styles)(PageContainer)
