import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'  

import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import * as IncidentActions from '../actions/incident'
import { withStyles } from '@material-ui/core/styles'



const styles = (theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  item: {
    width: '100%' // Fix chrome issue where hovering over items changes width of flexbox
  },
  note: {
    width: '100%',
    backgroundColor: 'lightyellow',
    border: 'solid'
  },
  dz: {
    height: '100px',
    width: '100px',
    borderColor: 'white',
    align: 'left'
  },
  image: {
    padding: '0 4px'
  }
})

class ViewWorkNotes extends Component {
  
  componentDidMount () {
    const { fetchWorkNotes, hmKey } = this.props
    fetchWorkNotes(hmKey)
    
  }

  render () {
    const {
      classes
    } = this.props

    return (
      <div>
        <Grid container direction='column' >
          <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1'>Work Notes</Typography>
            </Grid>
          </Grid>


          <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
            <Grid item xs={12} md={6}>
              <div className={classes.note}>
                {this.props.worknotes.map ?
                  this.props.worknotes.map((note) => (
                    <p><strong>User:</strong>&nbsp;&nbsp;{note.updtUserId}&nbsp;&nbsp;
                      <strong>Date:</strong>&nbsp;&nbsp;{note.updtDate}&nbsp;&nbsp;
                      <strong>Work Note:</strong>&nbsp;&nbsp;{note.workNote}</p>
                  ))
                  : ''
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
     
  )
  }
}

const mapStateToProps = (state) => {
  const worknotes = state.incident.current_worknotes || ''
  return {
    worknotes
  }
}

const mapDispatchToProps = (dispatch) => {

  const fetchWorkNotes = (hmKey) => {
    dispatch(IncidentActions.retrieveWorkNotes(hmKey))
  }
  return {
    fetchWorkNotes
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ViewWorkNotes)
