import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import FilterListIcon from '@material-ui/icons/FilterList'
import { withStyles } from '@material-ui/core/styles'
import ProgressButton from '../buttons/ProgressButton'
import * as Roles from '../../constants/roles'

import IncidentTableFilterImport from './Incident/IncidentTableFilter'
import IncidentTableHeaderImport from './Incident/IncidentTableHeader'
import IncidentTableRowImport from './Incident/IncidentTableRow'



const styles = {
  message: {
    left: 0,
    position: 'absolute',
    textAlign: 'center',
    top: '70px',
    width: '100%'
  },
  messageFilter: {
    top: '150px'
  }
}

let typingTimer = null

const getTitle = (role) => {
  return    'INCIDENTS'
}

class IncidentTable extends Component {
  state = {
    incidentApprovals: {},
    printSubmitting: false,
    selected: []
  }


  //TODONE: Set entityType, Maybe set agencyReportingIncident
  componentDidMount () {
    // console.log('[components.IncidentTable.index]  componentDidMount()  ')
    const { loadData, clearSelectedImages, onFilterChange, carrier, agency, entity } = this.props
    clearSelectedImages()
    if('CARRIER' === entity) {
      onFilterChange('airlineReportingIncident',carrier)
      onFilterChange('entityReportingIncident','CARRIER')    
    }
    if('AGENT' === entity) {
      onFilterChange('agencyReportingIncident',agency)
      onFilterChange('entityReportingIncident','AGENT')
    }
    if (loadData) {
      loadData()
    }
  }

  componentDidUpdate (prevProps) {
    // console.log('[components.IncidentTable.index]  componentDidUpdate()  ')
    const { loadData } = this.props
    if (prevProps.location !== this.props.location && loadData) {
      loadData()
    }
  }

  handleApprovalChange = (incidentId, approval) => {
    this.setState({
      incidentApprovals: {
        ...this.state.incidentApprovals,
        [incidentId]: approval
      }
    })
  }

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props
    onPageChange(page)
  }

  handleChangeRowsPerPage = (event) => {
    const { onRowsPerPageChange } = this.props
    onRowsPerPageChange(event.target.value)
  }
    
  handleCreateIncidentClick = () => {
    const { history } = this.props
    history.push('/tracker')
  }

  handleHeaderClick = (name) => () => {
    const { onHeaderClick } = this.props
    onHeaderClick(name)
  }

  handleFilterChange = (name, timeout = 750) => (event) => {
    // console.log('[components.IncidentTable.index]  handleFilterChange()  ')    
    const { loadData, onFilterChange } = this.props
    // Delay search until typing is done so that it doesn't call the web service on every key
    const value = event.target.value.toUpperCase()
    onFilterChange(name, value)
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => {
      loadData()
    }, timeout)
  }

  handleFilterDataChange = (name) => (value) => {
    // console.log('[components.IncidentTable.index]  handleFilterDataChange()  ')       
    const { loadData, onFilterChange } = this.props
    const newValue = value && value.format ? value.format('YYYY-MM-DD') : value
    onFilterChange(name, newValue)
    loadData()
  }

  getMessageClassName = () => {
    const { classes, showFilter } = this.props
    if (showFilter) {
      return `${classes.message} ${classes.messageFilter}`
    }
    return classes.message
  }

  render () {
    // console.log('[components.IncidentTable.index]  render()  ')    
    const { incidentApprovals, saveSubmitting } = this.state
    const {
      errorText,
      filter,
      loading,
      noDataMessage,
      onFilterClick,
      onSaveClick,
      onSerialNumberClick,
      orderBy,
      page,
      role,
      rowCount,
      rows,
      rowsPerPage,
      showFilter,
      sortDirection,
      exportHazmatToExcel
    } = this.props

    const pagedIncidents = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const rowsInPage = Math.min(rowsPerPage, rows.length - page * rowsPerPage)
    const emptyRows = rowsPerPage - Math.max(rowsInPage, 0)

    let IncidentTableHeader = IncidentTableHeaderImport
    let IncidentTableFilter = IncidentTableFilterImport
    let IncidentTableRow = IncidentTableRowImport
    let columnCount = 7

    if  (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY || role === Roles.CARRIER || role === Roles.AGENT || role === Roles.CUSTOMER_SERVICE_AGENT){
      IncidentTableHeader = IncidentTableHeaderImport
      IncidentTableFilter = IncidentTableFilterImport
      IncidentTableRow = IncidentTableRowImport
      columnCount = 9
    }
 
    return (
      <div>
        <Grid alignItems='center' container spacing={3} style={{padding: '8px 16px'}}>
          <Grid item>
            <Typography variant='h5'>{getTitle(role)}</Typography>
          </Grid>
          <div style={{ flex: '1 1 auto' }} />
              <Grid item>
                  {
                  (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.CARRIER || role === Roles.AGENT) &&
                  <Button
                    onClick={this.handleCreateIncidentClick}
                    variant='outlined'
                  >
                    Create Incident
                  </Button>
                  }
                  <Button
                    onClick={exportHazmatToExcel}
                    variant='outlined'
                  >
                    Export Table to Excel
                  </Button>            
              </Grid>
              {onSaveClick &&
              <Grid item>
                  <ProgressButton
                    color='primary'
                    disabled={rows.length === 0}
                    onClick={this.handleSaveClick}
                    submitting={saveSubmitting}
                    variant='outlined'
                  >
                    Save
                  </ProgressButton>
              </Grid>
              }
              <Grid item>
                <Tooltip title='Filter'>
                  <IconButton onClick={onFilterClick}>
                    <FilterListIcon style={{cursor: 'pointer', transition: 'height .5s'}} />
                  </IconButton>
                </Tooltip>
              </Grid>
        </Grid>
        <div style={{overflowX: 'auto', overflowY: 'hidden', position: 'relative'}}>
          <Table><IncidentTableHeader onHeaderClick={this.handleHeaderClick} orderBy={orderBy} sortDirection={sortDirection} />
            <TableBody>
                {showFilter &&
                  <IncidentTableFilter
                    filter={filter}
                    onFilterChange={this.handleFilterChange}
                    onFilterDateChange={this.handleFilterDataChange}
                  />
                }
                {pagedIncidents.map((row, index) => (
                  <IncidentTableRow
                    incidentApprovals={incidentApprovals}
                    key={index}
                    onApprovalChange={this.handleApprovalChange}
                    onSerialNumberClick={onSerialNumberClick}
                    row={row}
                    role={role}
                  />
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 37 * emptyRows }}>
                    <TableCell colSpan={columnCount} />
                  </TableRow>
                )}
             </TableBody>
          </Table>
          {pagedIncidents.length === 0 && !loading && !errorText &&
            <Typography className={this.getMessageClassName()}>{noDataMessage}</Typography>
          }
          {pagedIncidents.length === 0 && !loading && errorText &&
            <Typography className={this.getMessageClassName()}>{errorText}</Typography>
          }
          {loading && <div className={this.getMessageClassName()}><CircularProgress /></div>}
        </div>
        <div>
          <TablePagination
            component='div'
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
          />
        </div>
      </div>
    )
  }
}

IncidentTable.defaultProps = {
  filter: {},
  loading: false,
  noDataMessage: 'No data to display',
  page: 0,
  rows: [],
  rowsPerPage: 10,
  showFilter: false,
  sortDirection: false
}

IncidentTable.propTypes = {
  errorText: PropTypes.string,
  filter: PropTypes.object,
  loading: PropTypes.bool,
  noDataMessage: PropTypes.string,
  loadData: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func,
  onSerialNumberClick: PropTypes.func.isRequired,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  role: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  rows: PropTypes.array,
  rowsPerPage: PropTypes.number,
  showFilter: PropTypes.bool,
  sortDirection: PropTypes.bool
}

export default withStyles(styles)(IncidentTable)
