import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dropzone from 'react-dropzone'
import * as DateUtils from '../utilities/date-utils'
import PageContainer from './PageContainer'
import TextField from '@material-ui/core/TextField'
import * as Roles from '../constants/roles'
import * as Incidents from '../constants/incidents'
import CarrierSelect from '../containers/inputs/CarrierSelect'
import AirportDestinationSelect from '../containers/inputs/AirportDestinationSelect'
import AirportOriginSelect from '../containers/inputs/AirportOriginSelect'
import InitialFindingSelect from '../containers/inputs/InitialFindingSelect'
import IcaoHazardClassSelect from '../containers/inputs/IcaoHazardClassSelect'
import IssueIdentifierSelect from '../containers/inputs/IssueIdentifierSelect'
import IssueTypeSelect from '../containers/inputs/IssueTypeSelect'
import DangerousItemSelect from '../containers/inputs/DangerousItemSelect'
import RestrictedItemSelect from '../containers/inputs/RestrictedItemSelect'
import UsStateSelect from '../containers/inputs/UsStateSelect'
import LetterMailedSelect from '../containers/inputs/LetterMailedSelect'
//--Received has been intentionally misspelled as receivd because 'CUSTOMER_LETTER_RECEIVD_STATUS' is 30 characters long -- this is the maximum name length allowed by our Oracle database.
import LetterReceivdSelect from '../containers/inputs/LetterReceivdSelect'
import CountrySelect from '../containers/inputs/CountrySelect'
import LoadingImage from '../containers/LoadingImage'
import ProgressButton from './buttons/ProgressButton'
import { DatePicker } from '@material-ui/pickers'
import ViewWorkNotes from './ViewWorkNotes'
import AuditTable from './AuditTable'
import WorkNotes from './WorkNotes'
import 'react-confirm-alert/src/react-confirm-alert.css'

const styles = {
    upldImage: {
      height: '100px',
      width: '100px',
      backgroundColor: 'lightblue',
      border: 'dashed',
      borderColor: 'darkblue'
    },
    dz: {
      height: '100px',
      width: '100px',
      borderColor: 'white',
      align: 'left',
      padding: 10
    },
    image: {
      padding: '0 4px'
    },
    imageContainer: {
        padding: '20px'
    },
    redBox: {
        border: 'solid',
        borderColor: 'red'
    }
    
  }

  const phoneRegex = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9)]{4})$/

  const handlePhoneInput = (value) => {
    // console.log('[components/ViewForm] handlePhoneInput')
      if (value) {
        return (
            value.replace(phoneRegex, '($1) $2-$3')
        )
      }
  }

  // const isAlphaNumeric = (text) =>  { return text.match(/^[0-9a-zA-Z]+$/) }

  function lettersNumbersCheck(name)
{
   var regEx = /^[0-9a-zA-Z]+$/;
   if(name.match(regEx))
     {
      return true;
     }
   else
     {
     //alert("Please enter letters and numbers only.");
     return false;
     }
}  

function certifiedTrackingNumberCheck(name) {
    var regEx = /^[0-9]+$/;
    if(name.match(regEx)) { return true; }
    else                  { return false; }
  }  

class ViewForm extends React.Component {
    
    componentDidMount = () => {
        // console.log('[components/ViewForm] componentDidMount')
        this.props.onSnackbarClose()
        this.props.fetchLogs(this.props.incident.hmKey)
        this.props.deleteStagedImages()
        this.props.fetchImages(this.props.incident.hmKey)
    }

    
    showHistory = () => {
        this.props.onSnackbarOpen()
    }


    formatDate(date){
        return (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    }

    colorMeRed = () => {
      // console.log('[components/ViewForm] colorMeRed')      
        if (this.props.incident.barcodeCn35  === '' || this.props.incident.barcodeCn35 == null) {
            document.getElementById('barcodeCn35').className = 'redBox'
        }

    }

    render(){ 

      // console.log('[components/ViewForm] render')
        const uploadIt = (acceptedFiles) =>{
            acceptedFiles.forEach((file) => {
                uploadImage(file, incident.hmKey)
            })
            
        }
        const { incident, classes, selimages, onSnackbarClose, uploadImage, snackbarOpen, role } = this.props;

        return(
        <PageContainer>
            <Grid container direction='column' spacing={3}>
                <Grid container direction='row' alignment='center' item spacing={2}>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>
                            {/* TODONE: Display Agency reporting incident, if reported by Agency */}
                            Dangerous Item -- 
                            {'AGENT'===incident.entityReportingIncident && incident.agencyReportingIncident}
                            { 'AGENT'!==incident.entityReportingIncident && incident.airlineReportingIncident }
                            -{ DateUtils.formatIdDate(incident.dateReported) }-{ incident.hmKey } -- View
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='CN-35 Barcode'
                            id='barcodeCn35'
                            variant='outlined'
                            helperText='Required'
                            error={incident.barcodeCn35 == null}
                            value={ incident.barcodeCn35 || '' }
                            readonly={true}
                        />
                    </Grid>
                    <TextField
                        type='hidden'
                        id='barcodeCn35VerificationStatus'
                        value={ incident.barcodeCn35VerificationStatus || '' }
                        readonly={true}
                    />

                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customs Barcode'
                            variant='outlined'
                            helperText='Required'
                            error={incident.barcodeCustoms == null}
                            value={ incident.barcodeCustoms || '' }
                            name='barcodeCustoms'
                            readonly={true}
                        />
                    </Grid>
                    <TextField
                        type='hidden'                        
                        id='barcodeCustomsVerificationStatus'
                        value={ incident.barcodeCustomsVerificationStatus || '' }
                        readonly={true}
                    />
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Handling Unit Barcode'
                            variant='outlined'
                            helperText='Must be 29 alphanumeric characters if filled'
                            error={(null !== incident.barcodeHandlingUnit) && (0 !== incident.barcodeHandlingUnit.length) && (incident.barcodeHandlingUnit.length !== 29 || !lettersNumbersCheck(incident.barcodeHandlingUnit))}
                            value={ incident.barcodeHandlingUnit || '' }
                            name='barcodeHandlingUnit'
                            readonly={true}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                          <CarrierSelect
                            fullWidth
                            label='Reporting Airline'
                            variant='outlined'
                            name='airlineReportingIncident'
                            helperText='Required'
                            error={null == incident.airlineReportingIncident || 0 === incident.airlineReportingIncident.length || '' === incident.airlineReportingIncident }
                            value={ incident.airlineReportingIncident || '' }
                            readonly={true}
                        />
                    </Grid>                  

                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id='todate'
                            floatingLabelText="To"
                            container="inline" 
                            label='Date Reported'
                            variant='inline'
                            format='MM/dd/yyyy'
                            formatDate={this.formatDate}
                            defaultDate = { new Date() }
                            value={ incident.dateReported }
                            readonly={true}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Reporting Contact Name'
                            variant='outlined'
                            helperText='Required'
                            error={incident.reportingContactName == null}
                            value={ incident.reportingContactName || '' }
                            name='reportingContactName'
                            readonly={true}

                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            autoComplete='off'
                            fullWidth
                            label='Reporting Contact Phone'
                            variant='outlined'
                            helperText='Required'
                            error={incident.reportingContactPhone == null || incident.reportingContactPhone === ''}
                            value={handlePhoneInput(incident.reportingContactPhone)}
                            name='reportingContactPhone'
                            readonly={true}

                         />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}> 
                        <TextField
                        fullWidth
                        label='Reporting Contact Email'
                        variant='outlined'
                        helperText='Required'
                        error={incident.reportingContactEmail == null}
                        value={ incident.reportingContactEmail || '' }
                        name='reportingContactEmail'
                        readonly={true}

                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <AirportDestinationSelect 
                                fullWidth
                                label='Reporting Airport Hub'
                                variant='outlined'
                                name='airlineReportingIncident'
                                helperText='Required'
                                error={incident.reportingAirportHub == null}
                                value={ incident.reportingAirportHub || '' }
                                readonly={true}

                            />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                                id='todate'
                                floatingLabelText="To"
                                container="inline" 
                                label='Date of Discovery'
                                format='MM/dd/yyyy'
                                variant='inline'
                                formatDate={this.formatDate}
                                defaultDate = { new Date() }
                                helperText='Required'
                                value={ incident.dateOfDiscovery }
                                readonly={true}

                            />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <AirportOriginSelect 
                                fullWidth
                                label='USA Origin Airport (Office of Exchange/ISC)'
                                name='airlineReportingIncident'
                                variant='outlined'
                                helperText='Required'
                                error={incident.originOfficeOfExchange == null}
                                value={ incident.originOfficeOfExchange || '' }
                                readonly={true}

                            />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Airline Record/Incident Number'
                            variant='outlined'
                            value={ incident.airlineRecordNo || '' }
                            name='airlineRecordNo'
                            readonly={true}
                            />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <InitialFindingSelect 
                                    fullWidth
                                    label='Initial Findings'
                                    variant='outlined'
                                    name='initialFinding'
                                    helperText='Required'
                                    error={incident.initialFinding == null}
                                    value={ incident.initialFinding || '' }
                                    readonly={true}
                                />
                    </Grid>
                    {incident.initialFinding === 'DG' ?
                    <Grid item xs={12} md={3}>
                        <IcaoHazardClassSelect
                            fullWidth
                            label='Icao Hazard Class'
                            variant='outlined'
                            name='icaoHazardClass'
                            helperText='Required'
                            error={incident.icaoHazardClass == null}
                            value={ incident.icaoHazardClass || '' }
                            readonly={true}
                        />
                    </Grid>
                    :''}
                </Grid>
                {incident.initialFinding === 'DG' ?
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                        <Grid item xs={12} md={3}>
                            <IssueIdentifierSelect 
                            fullWidth
                            label='How Record was Identified'
                            variant='outlined'
                            name='issueIdentificationMeans'
                            value={ incident.issueIdentificationMeans || '' }
                            readonly={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                        { incident.icaoHazardClass !== 'Not Selected' && incident.icaoHazardClass !== null?
                            <DangerousItemSelect 
                                fullWidth
                                label='Dangerous Item Type'
                                variant='outlined'
                                name='dangerousItem'
                                helperText='Required'
                                error={incident.dangerousItem == null}
                                value={ incident.dangerousItem || '' }
                                hazclass={incident.icaoHazardClass}
                                readonly={true}
                                />
                            :''
                        }
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                        <Grid item xs={12} md={3}>
                            <IssueTypeSelect
                                fullWidth
                                label='Issue Type'
                                variant='outlined'
                                name='issueType'
                                helperText='Required'
                                error={incident.issueType == null}
                                value={ incident.issueType || '' }
                                readonly={true}
                                />
                        </Grid>
                        { incident.issueType === 'RI'?
                            <Grid item xs={12} md={3}>
                                <RestrictedItemSelect
                                fullWidth
                                label='Restricted Item Type'
                                variant='outlined'
                                name='restrictedItem'
                                value={ incident.restrictedItem || '' }
                                readonly={true}
                                />
                            </Grid>
                        :
                        ''}
                    </Grid>
                }
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={6}>
                        <TextField
                                fullWidth
                                label='Additional Comments'
                                value={ incident.commentsGeneral || '' }
                                name='commentsGeneral'
                                readonly={true}
                                />
                    </Grid>
                </Grid>
                <Grid container direction='row' alignment='center' item spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.container} variant='subtitle1'>Sender</Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                                    fullWidth
                                    label='Company'
                                    variant='outlined'
                                    // helperText='Required'
                                    // error={incident.senderCompany == null}
                                    value={ incident.senderCompany || '' }
                                    name='senderCompany'
                                    readonly={true}
                                    />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                                    fullWidth
                                    label='Name'
                                    helperText='Required'
                                    error={incident.senderName == null}
                                    value={ incident.senderName || '' }
                                    variant='outlined'
                                    name='senderName'
                                    readonly={true}
                                    />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                                    fullWidth
                                    label='Address'
                                    variant='outlined'
                                    helperText='Required'
                                    error={incident.senderAddress == null}
                                    value={ incident.senderAddress || '' }
                                    name='senderAddress'
                                    readonly={true}
                                    />
                
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                                    fullWidth
                                    label='Address 2'
                                    variant='outlined'
                                    value={ incident.senderAddress2 || '' }
                                    name='senderAddress2'
                                    readonly={true}
                                    />
                
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <UsStateSelect
                                fullWidth
                                label='State'
                                variant='outlined'
                                name='restrictedItem'
                                helperText='Required'
                                error={incident.senderState == null}
                                value={ incident.senderState || '' }
                                readonly={true}
                                />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                                    fullWidth
                                    label='City'
                                    variant='outlined'
                                    helperText='Required'
                                    error={incident.senderCity == null}
                                    value={ incident.senderCity || '' }
                                    name='senderCity'
                                    readonly={true}
                                    />

                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                                    fullWidth
                                    label='ZIP'
                                    variant='outlined'
                                    helperText='Required'
                                    error={incident.senderZip == null}
                                    value={ incident.senderZip || '' }
                                    name='senderZip'
                                    readonly={true}
                                    />

                    </Grid>
                    <Grid item xs={12} md={3}>
                    <TextField
                            autoComplete='off'
                            fullWidth
                            label='Sender Phone'
                            variant='outlined'
                            helperText='Required'
                            error={incident.senderPhone == null || incident.reportingContactPhone === ''}
                            value={handlePhoneInput(incident.senderPhone)}
                            name='senderPhone'
                            readonly={true}
                         />
                      
                    </Grid>
                </Grid>
                <Grid container direction='row' alignment='center' item spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.container} variant='subtitle1'>Recipient/Consignee</Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                                fullWidth
                                label='Company'
                                variant='outlined'
                                // helperText='Required'
                                // error={incident.recipientCompany == null}
                                value={ incident.recipientCompany || '' }
                                name='recipientCompany'
                                readonly={true}
                                />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                                fullWidth
                                label='Name'
                                variant='outlined'
                                helperText='Required'
                                error={incident.recipientName == null}
                                value={ incident.recipientName || '' }
                                name='recipientName'
                                readonly={true}
                                />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                                fullWidth
                                label='Address'
                                variant='outlined'
                                helperText='Required'
                                error={incident.recipientAddress == null}
                                value={ incident.recipientAddress || '' }
                                name='recipientAddress'
                                readonly={true}
                                />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                                fullWidth
                                label='Address 2'
                                variant='outlined'
                                value={ incident.recipientAddress2 || '' }
                                name='recipientAddress2'
                                readonly={true}
                                />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>                                           
                    <Grid item xs={12} md={3}>
                        <TextField
                                fullWidth
                                label='City/Locale'
                                variant='outlined'
                                helperText='Required'
                                error={incident.recipientCity == null}
                                value={ incident.recipientCity || '' }
                                name='recipientCity'
                                readonly={true}
                                />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                                fullWidth
                                label='Post Code'
                                variant='outlined'
                                helperText='Required'
                                error={incident.recipientZip == null}
                                value={ incident.recipientZip || '' }
                                name='recipientZip'
                                readonly={true}
                                />
                    </Grid>
            </Grid>
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <CountrySelect 
                                fullWidth
                                label='Country'
                                variant='outlined'
                                name='recipientCountry'
                                helperText='Required'
                                error={incident.recipientCountry == null}
                                value={ incident.recipientCountry || '' }
                                readonly={true}
                            />

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            autoComplete='off'
                            fullWidth
                            label='Recipient Phone'
                            variant='outlined'
                            helperText='Required'
                            error={incident.recipientPhone == null || incident.recipientPhone === ''}
                            value={handlePhoneInput(incident.recipientPhone)}
                            name='recipientPhone'
                            readonly={true}
                         />

                    </Grid>
            </Grid>
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={6}>
                        <TextField
                                fullWidth
                                label='Content Description written from Customs Form'
                                helperText='Required'
                                error={incident.contentDescrnOnCustomsForm == null}
                                value={ incident.contentDescrnOnCustomsForm || '' }
                                name='contentDescrnOnCustomsForm'
                                readonly={true}
                                />
                    
                    </Grid>
            </Grid>
            {/* <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                <Grid item xs={12}>
                    <ViewWorkNotes hmKey={incident.hmKey}  />
                </Grid>
            </Grid>
            <Grid container direction='row' className={classes.imageContainer} justify='flex-start' alignment='center' item spacing={2}>
              {selimages.map ?
                selimages.map((image, index)=>(
                  <LoadingImage image={image.image} />
                  ))
                :
                ''
              
              }
            </Grid> */}

            { ((role === Roles.DANGEROUS_GOODS_ADMIN ) && (incident.status === Incidents.INPROCESS || incident.status === Incidents.CLOSED) ) ?
                <Grid container direction='column' justify='flex-start' alignment='center' item spacing={2}> 
                                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>

                        <LetterMailedSelect
                            fullWidth
                            label='Customer Letter Mailed Status'
                            variant='outlined'
                            name='customerLetterMailedStatus'
                            // error={incident.customerLetterMailedStatus == null}
                            value={ incident.customerLetterMailedStatus || '-1' }
                        />                        

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id='customerLetterMailedDate'
                            container="inline" 
                            label='Letter Mailed Date'
                            formatDate={this.formatDate}
                            defaultDate = { new Date() }
                            value={ incident.customerLetterMailedDate }
                        />
                    </Grid>
                    </Grid>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Filename'
                            value={ incident.customerLetterFilename || '' }
                            name='customerLetterFilename'
                        />               
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter File Location'
                            value={ incident.customerLetterFileLocation || '' }
                            name='customerLetterFileLocation'
                        />               
                    </Grid>   
                    </Grid>                    
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter CRF Tracking Number'                       
                            value={ incident.customerLetterCrfTrackNo || '' }
                            name='customerLetterCrfTrackNo'
                        />               
                    </Grid>                                                                                                 
                    <br></br>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>




                      <LetterReceivdSelect
                            fullWidth
                            label='Customer Letter Received Status'
                            variant='outlined'
                            name='customerLetterResponseStatus'
                            value={ incident.customerLetterResponseStatus || '-1' }
                        />             
                    </Grid>



                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id='customerLetterResponseDate'
                            container="inline" 
                            label='Letter Received Date'
                            formatDate={this.formatDate}
                            value={ incident.customerLetterResponseDate }
                        />
                    </Grid>
                    </Grid>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Response Filename'
                            value={ incident.customerLetterResponseFilename || '' }
                            name='customerLetterResponseFilename'
                        />               
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Response File Location'
                            value={ incident.customerLetterResponseFileLoc || '' }
                            name='customerLetterResponseFileLoc'
                        />               
                    </Grid>  
                    </Grid>                                                                              
                </Grid>
                    :
                    ''}
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                <Grid item xs={12}>
                    <WorkNotes hmKey={incident.hmKey} />
                </Grid>
            </Grid>
            
            <Grid container direction='row' className={classes.imageContainer} justify='flex-start' alignment='center' item spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Images</Typography>
                </Grid>
                {selimages.map ?
                    selimages.map((image, index)=>(
                <LoadingImage image={image.image} />
                    ))
                :
                ''  (
                )
                }
            </Grid>
            { ((role === Roles.CUSTOMER_SERVICE_AGENT) && (incident.status === Incidents.INPROCESS)) ?
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={2}>
                      <ProgressButton
                          color='primary'
                          variant='contained'
                          onClick={()=>{
                            this.downloadWordFile()
                          }}
                      >
                        Generate Customer Letter
                      </ProgressButton>
                    </Grid>

                </Grid>
                :
                ''}  

                { ((role === Roles.CUSTOMER_SERVICE_AGENT ) && (incident.status === Incidents.INPROCESS) ) ?
                <Grid container direction='column' justify='flex-start' alignment='center' item spacing={2}> 
                                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                       <LetterMailedSelect
                            fullWidth
                            label='Customer Letter Mailed Status'
                            variant='outlined'
                            name='customerLetterMailedStatus'
                            // error={incident.customerLetterMailedStatus == null}
                            value={ incident.customerLetterMailedStatus || '-1' }
                            onChange =  {(event) => {this.props.handleFieldChange('customerLetterMailedStatus', event.target.value)}}  
                        />                        


                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id='customerLetterMailedDate'
                            container="inline" 
                            label='Letter Mailed Date'
                            formatDate={this.formatDate}
                            defaultDate = { new Date() }
                            // helperText='Required'
                            value={ incident.customerLetterMailedDate }
                            onChange={ (event) => this.props.handleFieldChange('customerLetterMailedDate', event)}
                        />
                    </Grid>
                    </Grid>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Filename'
                            inputProps={{ maxLength: 40 }}
                            // helperText='Required'
                            // error={incident.customerLetterFilename == null}
                            value={ incident.customerLetterFilename || '' }
                            name='customerLetterFilename'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterFilename', event.target.value)}
                        />               
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter File Location'
                            // helperText='Required'
                            // error={incident.customerLetterFileLocation == null}
                            value={ incident.customerLetterFileLocation || '' }
                            name='customerLetterFileLocation'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterFileLocation', event.target.value)}
                        />               
                    </Grid>   
                    </Grid>                    
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Certified Mail Tracking No.'

                            helperText='20 digit number, no spaces'

                            error={((null !== incident.customerLetterCrfTrackNo) && (0 !== incident.customerLetterCrfTrackNo.length) && ((incident.customerLetterCrfTrackNo.length < 20) || (incident.customerLetterCrfTrackNo.length > 20) || !certifiedTrackingNumberCheck(incident.customerLetterCrfTrackNo)))}                            
                            value={ incident.customerLetterCrfTrackNo || '' }
                            name='customerLetterCrfTrackNo'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterCrfTrackNo', event.target.value)}
                        />               
                    </Grid>                                                                                                 
                    <br></br>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                      <LetterReceivdSelect
                            fullWidth
                            label='Customer Letter Received Status'
                            variant='outlined'
                            name='customerLetterResponseStatus'
                            // error={incident.customerLetterMailedStatus == null}
                            value={ incident.customerLetterResponseStatus || '-1' }
                            onChange =  {(event) => {this.props.handleFieldChange('customerLetterResponseStatus', event.target.value)}}  
                        />             
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id='customerLetterResponseDate'
                            container="inline" 
                            label='Letter Received Date'
                            formatDate={this.formatDate}
                            // defaultDate = { new Date() }
                            // helperText='Required'
                            value={ incident.customerLetterResponseDate }
                            onChange={ (event) => this.props.handleFieldChange('customerLetterResponseDate', event)}
                        />
                    </Grid>
                    </Grid>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Response Filename'
                            // helperText='Required'
                            // error={incident.customerLetterResponseFilename == null}
                            value={ incident.customerLetterResponseFilename || '' }
                            name='customerLetterResponseFilename'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterResponseFilename', event.target.value)}
                        />               
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Response File Location'
                            // helperText='Required'
                            // error={incident.customerLetterResponseFileLoc == null}
                            value={ incident.customerLetterResponseFileLoc || '' }
                            name='customerLetterResponseFileLoc'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterResponseFileLoc', event.target.value)}
                        />               
                    </Grid>  
                    </Grid>                                                                              
                </Grid>
                    :
                    ''}
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}></Grid> 
              <Grid item xs={12} md={2}>
                <ProgressButton
                  color='primary'
                  variant='contained'
                  onClick={()=>{
                    this.showHistory()
                  }}
                >
                 View History
                </ProgressButton>
            </Grid>
          
          </Grid>
          <AuditTable logs={this.props.hm_logs} snackbarClose={onSnackbarClose} snackbarOpen={snackbarOpen}/>
        </PageContainer>
      )
    }

}

export default withStyles(styles)(ViewForm)