const styles = {
  dateInput: {
    flex: '1 1 0px'
  },
  input: {
    fontSize: '13px',
    width: '100%'
  },
  tableCell: {
    padding: '8px'
  }
  //,
  // serialNumberColumn: {
  //   width: '150px'
  // },
  // incidentColumn: {
  //   width: '80%'
  // },
  // barcodeCustomsColumn: {
  //   width: '150px'
  // },
  // submittedByColumn: {
  //   width: '150px'
  // },
  // hubColumn: {
  //   width: '150px'
  // },
  // statusColumn: {
  //   width: '150px'
  // },
  // initialFindingColumn: {
  //   width: '200px'
  // },
  // airlineReportingIncidentColumn: {
  //   width: '200px'
  // }  
}

export default styles
  