import { postResource, postInsecureResource, getResource } from '../utilities/service-utils'	


// export const getAllPersons = () => {
//   // console.log('[services.person] - getAllPersons')  
//   return getResource('dguser')
// }

// export const getAllApprovedPersons = () => {
//   // console.log('[services.person] - getAllApprovedPersons')  
//   return getResource('dguser/approved')
// }

export const getAllPendingPersons = () => {
  // console.log('[services.person] - getAllPendingPersons')  
  return getResource('dguser/findAllPending')
}

//NOT USED
// export const getAllDeniedPersons = () => {
//   // console.log('[services.person] - getAllDeniedPersons')  
//   return getResource('dguser/denied')
// }

//NOT USED
//by User Name
// export const getOnePersonByName = (name) => {
//   // console.log('[services.person] - getOnePersonByName');  
//   // console.log('name=',name);
//   return getResource(`dguser/dguser/${name}`);   
// }

//by Database Key
export const getOnePersonById = (id) => {
  // console.log('[services.person] - getOnePersonById') 
  // console.log('id=',id); 
  return getResource(`dguser/findByUserKeyVal/${id}`)  
}

export const createPerson = (person, userId) => {	
  // console.log('[services/person] createPerson' )	
  return postInsecureResource('dguser/create', person, userId)	
}

export const approvePerson = (userId,adminId) => {	
  // console.log('[services/person] approvePerson:'+userId+"  by:"+adminId )	
  return postResource('dguser/approve/'+ userId,null,adminId)	
}

export const denyPerson = (userId,reason, adminId) => {	
  // console.log('[services/person] denyPerson:'+userId+"  by:"+adminId +"  reason:"+reason)	
  return postResource('dguser/deny/'+userId,reason,adminId)	
}