import * as LocationServices from '../services/location'

export const RETRIEVE_ALL_LOCATIONS_SUCCESS = 'RETRIEVE_ALL_LOCATIONS_SUCCESS'

/**
 * 
 * @returns 
 */
export const retrieveAllLocations = () => (dispatch, getState) => {
  const state = getState()
  const locations = state.location.locations

  if (locations && locations.length > 0) {
    return
  }

  return LocationServices.getAllLocations()
    .then((locations) => {
      const sortedLocations = locations.slice()
      sortedLocations.sort((a, b) => {
        return a.locationName > b.locationName
      })

      dispatch({
        type: RETRIEVE_ALL_LOCATIONS_SUCCESS,
        locations: sortedLocations
      })
    })
}
