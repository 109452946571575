import * as LocationActions from '../actions/location'

const initialState = {
  locations: []
}

const location = (state = initialState, action) => {
  switch (action.type) {
    case LocationActions.RETRIEVE_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations
      }
    default:
      return state
  }
}

export default location
