import * as HmLogServices from '../services/hmlog'
export const SELECTED_LOGS = 'SELECTED_LOGS'

/**
 * 
 * @param {*} hmkey 
 * @returns 
 */
export const fetchHmLogs = (hmkey) => (dispatch) => {
    return HmLogServices.fetchLogs(hmkey)
      .then(res => {
        dispatch({
          type: SELECTED_LOGS,
          selLogs: res
        })
      })
  }
  