import { getPage, getResource, postResource, postFile } from '../utilities/service-utils'


export const getIncident = (incidentId) => {
  return getResource(`hazmat/${incidentId}`)
}

export const getIncidents = (criteria) => {
  // console.log('[services.incident] - getIncidents()  criteria = ',criteria)  
  return getResource('hazmat', { criteria })
}

export const fetchIncidentImages = (hmkey) => {
  return getResource(`hazmat/fetchImages/${hmkey}`)
}

export const fetchWorkNotes = (hmkey) => {
  return getResource(`hazmat/fetchWorkNotes/${hmkey}`)
}

export const getIncidentsCount = (criteria) => {
  return getResource('hazmat/count', { criteria })
}

export const getIncidentsCountPilot = (criteria) => {
  return getResource('hazmat/pilotcount', { criteria })
}

export const getIncidentsPage = (criteria, start, count) => {
  return getPage(
    getIncidentsCount(criteria),
    getIncidents({ ...criteria, start, count })
  )
}
export const insertBase64Image = (hmkey, b64, userId) =>{
  return postResource(`hazmat/uploadImageBase64/${hmkey}`, b64, userId)
}
export const insertIncidentImage = (incidentId, file) => {
  return postFile(`hazmat/uploadIncidentImage/${incidentId}`, file)
}

export const insertWorkNote = (hmkey, worknote, userId) => {
  return postResource(`hazmat/insertWorkNote/${hmkey}`, worknote, userId)
}

export const saveDraft = (incident, userId) => {
  console.log('[services/incident] saveDraft')
  
  return postResource(`hazmat/createDraft/${userId}`, incident, userId)
}

export const createIncident = (incident, userId) => {
  // console.log('[services/incident] createIncident' )
  
  return postResource(`hazmat/create/${userId}`, incident, userId)
}

export const deleteIncident = (incident, userId) => {
  return postResource(`hazmat/delete/${userId}`, incident, userId)
}

export const updateIncident = (incident, userId) => {
  return postResource(`hazmat/update/${userId}`, incident, userId)
}

