import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as ErrorActions from '../../actions/error'
import * as IncidentActions from '../../actions/incident'
import * as SnackbarActions from '../../actions/snackbar'
import * as Roles from '../../constants/roles'
import * as HmLogAction from '../../actions/hmlog'
import EditForm from '../../components/EditForm'

const getInitialValues = (incident, role) => {
  // console.log('[containers/EditForm] getInitialValues')
  if (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY || role === Roles.CARRIER || role === Roles.AGENT ) {
    return {
      hmkey: incident.hmKey
    }
  }
  return {}
}

const mapStateToProps = (state, ownProps) => {
  // console.log('[containers/EditForm] mapStateToProps')
  const incident = state.incident.selected_incident
  const hm_logs = state.hmlog.selected_logs
  const role = state.user.role
  const snackbarOpen = state.snackbars.reportDetail
  const selimages = state.incident.selected_incident_images || {}
  const page = state.table.incidentsTable.page
  return {
    incident,
    initialValues: getInitialValues(incident, role),
    role,
    snackbarOpen,
    selimages,
    page,
    hm_logs
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  // console.log('[containers/EditForm] mapDispatchToProps')
  const onError = (errorText, title) => {
    // console.log('[containers/EditForm] mapDispatchToProps -- onError')
    dispatch(ErrorActions.openErrorDialog({ errorText, title }))
  }

  const onSnackbarClose = () => {
    // console.log('[containers/EditForm] mapDispatchToProps -- onSnackbarClose')
    dispatch(SnackbarActions.close('reportDetail'))
  }

  const onSnackbarOpen = () => {
    // console.log('[containers/EditForm] mapDispatchToProps -- onSnackbarOpen')
    dispatch(SnackbarActions.open('reportDetail'))
  }

  const deleteIncident = () => {
    dispatch(IncidentActions.deleteIncident())
    .then(() => {
        ownProps.history.push('/home')
    })
  }

  const closeIncident = () => {
    // console.log('[containers/EditForm] mapDispatchToProps -- closeIncident')
    dispatch(IncidentActions.closeIncident())
    .then(() => {
        ownProps.history.push('/home')
    })
  }
  const fetchLogs = (hmkey) =>{
    dispatch(HmLogAction.fetchHmLogs(hmkey))
  }

  const uploadImage = (file, hmkey) => {
    // console.log('FILE IS ')
    // console.log(file)
      dispatch(IncidentActions.uploadImage(hmkey,file))
      dispatch(IncidentActions.retrieveItemImages(hmkey))
  }

  const fetchImages = (hmkey) => {
      dispatch(IncidentActions.retrieveItemImages(hmkey))
  }

  const onSubmit = () => {
    // console.log('[containers/EditForm] mapDispatchToProps -- onSubmit...')
    return new Promise((resolve) => {
      // console.log('...resolve complete')      
      resolve('complete')
   })
    .then(() => {
      // console.log('...Calling dispatch IncidentActions.submitEditComplete')
      return dispatch(IncidentActions.submitEditComplete())
    })     
    .then((success) => {
      if (success) {
        ownProps.history.push('/home')
        
      }
    })
  }

  const saveDraft = () =>{
    console.log('[containers/EditForm] mapDispatchToProps -- saveDraft...')
    console.log('...Calling dispatch IncidentActions.submitEditDraft')
    dispatch(IncidentActions.submitEditDraft())
    .then((success) => {
      console.log('[EditForm] mapDispatchToProps - dispatch IncidentActions.submitEditDraft - then - success =', success)
      if (success) {
        ownProps.history.push('/home')
      }
    })
  }

  const deleteStagedImages = () => {
    dispatch(IncidentActions.deleteStagedImages())
  }

  const handleFieldChange = (name, value) =>{      
    dispatch(IncidentActions.handleFieldChange(name, value))
  }

  const generateWord = (hmKey) => {
    // console.log('[containers/EditForm] mapDispatchToProps - generateWord called  hmKey=',hmKey);
    const rval = dispatch(IncidentActions.generateWord(hmKey));
    // console.log('[containers/EditForm] mapDispatchToProps - generateWord returned',rval);
    return rval;
  }

  return {
    onError,
    onSnackbarClose,
    onSnackbarOpen,
    onSubmit,
    uploadImage,
    fetchImages,
    saveDraft,
    fetchLogs,
    closeIncident,
    deleteIncident,
    deleteStagedImages,
    handleFieldChange,
    generateWord
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditForm))
