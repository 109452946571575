import * as PersonActions from '../actions/person'

const initialState = {
  persons: []
}

const person = (state = initialState, action) => {
  switch (action.type) {
    // case  PersonActions.FIELD_CHANGE:
      // case  PersonActions.SELECT_PERSON:
        // case: PersonActions.DENY_PERSON_SUCCESS
        // case: PersonActions.APPROVE_PERSON_SUCCESS
        // case: PersonActions.SAVE_PERSON_SUCCESS

    // case PersonActions.RETRIEVE_ALL_PERSONS_SUCCESS:
    //   return {
    //     ...state,
    //     persons: action.persons
    //   }

      // case PersonActions.RETRIEVE_ONE_PERSON_SUCCESS:
      //   return {
      //     ...state,
      //     person: action.person
      //   }        

      case PersonActions.RETRIEVE_ONE_PERSON_BY_ID_SUCCESS:  
      return {
        ...state,
        person: action.person
      }           
    default:
      return state
  }
}

export default person
