import * as CarrierActions from '../actions/carrier'

const initialState = {
  carriers: []
}

const carrier = (state = initialState, action) => {
  switch (action.type) {
    case CarrierActions.RETRIEVE_ALL_CARRIERS_SUCCESS:
      return {
        ...state,
        carriers: action.carriers
      }
      case CarrierActions.RETRIEVE_PILOT_CARRIERS_SUCCESS:
        return {
          ...state,
          carriers: action.carriers
        }
    default:
      return state
  }
}

export default carrier
