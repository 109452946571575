import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { connect } from 'react-redux'
import { compose } from 'redux'

const IncidentTableFilter = ({ classes, filter, onFilterChange, selectedTab, onFilterDateChange }) => (
  <TableRow>
    <TableCell className={classes.tableCell}  style={{verticalAlign: 'bottom'}}>
      <TextField
        InputProps={{
          inputProps: { className: classes.input }
        }}
        onChange={onFilterChange('incidentLike')}
        value={filter.incidentLike || ''}
      />
    </TableCell>
    <TableCell className={classes.tableCell}  style={{verticalAlign: 'bottom'}}>
      <TextField
        InputProps={{
          inputProps: { className: classes.input }
        }}
        onChange={onFilterChange('barcodeCustomsLike')}
        value={filter.barcodeCustomsLike || ''}
      />
    </TableCell>
    <TableCell className={classes.tableCell}  style={{verticalAlign: 'bottom'}}>
      <TextField
        InputProps={{
          inputProps: { className: classes.input }
        }}
        onChange={onFilterChange('reportingContactNameLike')}
        value={filter.reportingContactNameLike || ''}
      />
    </TableCell>
    <TableCell className={classes.tableCell} style={{verticalAlign: 'bottom'}}>
      <TextField
        InputProps={{
          inputProps: { className: classes.input }
        }}
        onChange={onFilterChange('reportingAirportHubLike')}
        value={filter.reportingAirportHubLike || ''}
      />
    </TableCell>
    {selectedTab === 3 ?
      <TableCell className={classes.tableCell} style={{verticalAlign: 'bottom'}}>
        <TextField
          InputProps={{
            inputProps: { className: classes.input }
          }}
          onChange={onFilterChange('statusLike')}
          value={filter.statusLike || ''}
        />
      </TableCell>
      : ''}
    <TableCell className={classes.tableCell} style={{verticalAlign: 'bottom'}}>
      <TextField
        InputProps={{
          inputProps: { className: classes.input }
        }}
        onChange={onFilterChange('initialFindingLike')}
        value={filter.initialFindingLike || ''}
      />
    </TableCell>
    <TableCell className={classes.tableCell} style={{verticalAlign: 'bottom'}}>
      <TextField
        InputProps={{
          inputProps: { className: classes.input }
        }}
        onChange={onFilterChange('airlineReportingIncidentLike')}
        value={filter.airlineReportingIncidentLike || ''}
      />
    </TableCell>
    <TableCell className={classes.tableCell} style={{verticalAlign: 'bottom'}}>
      <TextField
        InputProps={{
          inputProps: { className: classes.input }
        }}
        onChange={onFilterChange('agencyReportingIncidentLike')}
        value={filter.agencyReportingIncidentLike || ''}
      />
    </TableCell>
    <TableCell className={classes.tableCell} style={{verticalAlign: 'bottom'}}>
      <TextField
        InputProps={{
          inputProps: { className: classes.input }
        }}
        onChange={onFilterChange('entityReportingIncidentLike')}
        value={filter.entityReportingIncidentLike || ''}
      />
    </TableCell>
  </TableRow>
)

const mapStateToProps = (state) => {
  const selectedTab = state.tabs.incidents
  return {
    selectedTab
  }
}

export default compose(withStyles(styles), connect(mapStateToProps))(IncidentTableFilter)
