import { connect } from 'react-redux'
import * as AirportActions from '../../actions/airport'

import SelectField from '../../components/inputs/SelectField'

const mapStateToProps = (state) => {
  const airportOrigins = state.airport.airportOrigins

  const options = airportOrigins
    .map((airport) => ({
      label: airport.code + ' - ' + airport.name,
      name: airport.name,
      value: airport.code,
      altValue: airport.name

    }))
    .sort((a, b) => a.label > b.label ? 1 : -1)

  return {
    options
  }
}

const mapDispatchToProps = (dispatch) => {
  const loadData = () => {
    return dispatch(AirportActions.retrieveOriginAirports())
  }

  return {
    loadData
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectField)
