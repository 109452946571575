import * as PersonServices from '../services/person'	
import * as ErrorActions from './error'	
import * as SnackbarActions from './snackbar'	

export const FIELD_CHANGE = 'FIELD_CHANGE'
/**
 * 
 * @param {*} name 
 * @param {*} value 
 * @returns 
 */
export const handleFieldChange = (name, value) => {
  return {
    type: FIELD_CHANGE,
    name,
    value
  }
}

export const SELECT_PERSON = 'SELECT_PERSON'
/**
 * 
 * @param {*} person 
 * @returns 
 */
export const selectPerson = (person) => {
  return {
    type: SELECT_PERSON,
    person
  }
}

export const DENY_PERSON_SUCCESS = 'DENY_PERSON_SUCCESS'	
// Action bound to redux form ViewPerson	
export const denyPerson = (...values) => (dispatch, getState) => {	
  // console.log('[actions/person/PersonActions]  denyPerson  values = ', ...values)	
  const state = getState()
  // console.log('[actions/person/PersonActions]  denyPerson  state = ', state)	 
  // console.log('[actions/person/PersonActions]  denyPerson  state.user = ', state.user)	 
  // console.log('[actions/person/PersonActions]  denyPerson  state.user.userId = ', state.user.userId)  
  return PersonServices.denyPerson(...values,state.user.userId)	
    .then(() => {	
      dispatch(SnackbarActions.open('denyPerson'))	
        })	
    .then(() => {
      dispatch({
        type: DENY_PERSON_SUCCESS
        })
      })	    
    .catch((e) => {	
      dispatch(ErrorActions.openErrorDialog({ errorText: e, title: 'Could not deny person' }))	
    })	
}

export const APPROVE_PERSON_SUCCESS = 'APPROVE_PERSON_SUCCESS'	
// Action bound to redux form ViewPerson	
export const approvePerson = (values) => (dispatch, getState) => {	
  // console.log('[actions/person/PersonActions]  approvePerson  values = ', values)	
  const state = getState()
  // console.log('[actions/person/PersonActions]  approvePerson  state = ', state)	 
  // console.log('[actions/person/PersonActions]  approvePerson  state.user = ', state.user)	 
  // console.log('[actions/person/PersonActions]  approvePerson  state.user.userId = ', state.user.userId)	
  return PersonServices.approvePerson(values,state.user.userId)	
    .then(() => {	
      dispatch(SnackbarActions.open('approvePerson'))	
      })
    .then(() => {
      dispatch({
        type: APPROVE_PERSON_SUCCESS
        })
      })		
    .catch((e) => {	
      dispatch(ErrorActions.openErrorDialog({ errorText: e, title: 'Could not approve person' }))	
      })	
}

export const SAVE_PERSON_SUCCESS = 'SAVE_PERSON_SUCCESS'	
// Action bound to redux form CreatePerson	
export const createPersonSubmit = (values) => (dispatch, getState) => {	
  // console.log('[actions/person/PersonActions]  createPersonSubmit  values = ', values)	
  //const state = getState()
  // console.log('[actions/person/PersonActions]  createPersonSubmit  state = ', state)	
  return PersonServices.createPerson(values)	
    .then((res) => {	
      // console.log('actions.createPersonSubmit -- services.createPerson .then(res) res=',res)
      if ('success'===res.status) dispatch(SnackbarActions.open('createPerson'));
      else	dispatch(SnackbarActions.open('createPersonFail'));
      })
    .then((res) => {
      // console.log('actions.createPersonSubmit -- createPerson.then.Snackbar open .then(res) res=',res)      
      dispatch({
        type: SAVE_PERSON_SUCCESS
        })
      })	
    .catch((e) => {	
      const errObj=JSON.parse(e);
      // console.log('received error message:', errObj);
      dispatch(ErrorActions.openErrorDialog({ errorText: errObj.message, title: 'Could not create person' }))	
    })	
}

//NOT USED
// export const RETRIEVE_ALL_PERSONS_SUCCESS = 'RETRIEVE_ALL_PERSONS_SUCCESS'
// export const retrieveAllPersons = () => (dispatch) => {
//   // console.log('[actions/person/PersonActions]  retrieveAllPersons  id=' )	  
//   return PersonServices.getAllPersons()
//   .then(res => {
//     dispatch({
//       type: RETRIEVE_ALL_PERSONS_SUCCESS,
//       persons: res
//       })
//     })
//   }

//NOT USED
// export const RETRIEVE_ONE_PERSON_SUCCESS = 'RETRIEVE_ONE_PERSON_SUCCESS'
// export const retrieveOnePersonByName = (name) => (dispatch, name) => {
//   // console.log('[actions/person/PersonActions]  retrieveOnePersonByName'+ name)	  
//   return PersonServices.getOnePersonByName(name)
//   .then(res => {
//     dispatch({
//       type: RETRIEVE_ONE_PERSON_SUCCESS,
//       person: res
//       })
//     })
//   }

export const RETRIEVE_ONE_PERSON_BY_ID_SUCCESS = 'RETRIEVE_ONE_PERSON_BY_ID_SUCCESS'
export const retrieveOnePersonById = (dispatch) => (id) => {
  // console.log('[actions/person/PersonActions]  retrieveOnePersonById'+ id)	  
  return PersonServices.getOnePersonById(id)
  .then(res => {
    dispatch({
      type: RETRIEVE_ONE_PERSON_BY_ID_SUCCESS,
      person: res
      })
    })
  }    
