import { connect } from 'react-redux'
import EnlargeableImage from '../components/EnlargeableImage'

const mapStateToProps = (state, ownProps) => {
  // const images = state.entities.incidentItemImages[ownProps.incidentId]
  // const itemImages = images && images[ownProps.itemId]
  const image = ownProps.image
 // console.log('Image IS !!!' + image)
  return {
    image
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  // const loadImage = () => {
  //   const incidentId = ownProps.incidentId
  //   const itemId = ownProps.itemId
  //   return dispatch(IncidentActions.retrieveIncidentItemImage(incidentId, itemId, seqNbr))
  // }

  return {
    //loadImage
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnlargeableImage)
