import { connect } from 'react-redux'	
import { withRouter } from 'react-router-dom'	

import * as AgentActions from '../actions/agent'	
import * as SnackbarActions from '../actions/snackbar'	
import CreateAgent from '../components/CreateAgent'	

const mapStateToProps = (state) => {	
  const snackbarOpen = state.snackbars.createAgent
  const snackbarFail = state.snackbars.createAgentFail	
  return {	
    initialValues: {	
    },	
    snackbarOpen,
    snackbarFail
  }	
}	

const mapDispatchToProps = (dispatch,ownProps) => {	
  // console.log("[containers/CreateAgent] mapDispatchToProps")


  const onBackClick = () => {	
    // console.log('[containers/CreateAgent]  onBackClick - ownProps='.ownProps)	    
    ownProps.history.push('/adminhome')	
  }	

  const onSnackbarClose = () => {	
    // console.log('[containers/CreateAgent]  onSnackbarClose')	
    dispatch(SnackbarActions.close('createAgent'))	
    // console.log('[containers/CreateAgent]  onSnackbarClose -- call onBackClick')
    onBackClick();
  }	

  const onSnackbarFailClose = () => {	
    // console.log('[containers/CreateAgent]  onSnackbarFailClose')	
    dispatch(SnackbarActions.close('createAgentFail'))	
  }	

  const onSubmit = (values,event) => {	
    // console.log('[containers/CreateAgent]  onSubmit   event=',event)	    
    // event.preventDefault();
    // console.log('[containers/CreateAgent]  onSubmit   values=',values)	
    return dispatch(AgentActions.createAgentSubmit(values))	
  }	

  return {	
    onBackClick,	
    onSnackbarClose,
    onSnackbarFailClose,	
    onSubmit	
  }	
}	

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateAgent))