import React, { Component, Fragment } from 'react'
import * as Incidents from '../../constants/incidents'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'   
import { TextField, Checkbox } from '../ReduxFormMaterialUI'
import { Divider, Grid, Typography, withStyles } from '@material-ui/core'
import { blue, grey, red } from '@material-ui/core/colors'
import Snackbar from '@material-ui/core/Snackbar'
import * as Roles from '../../constants/roles'
import PageContainer from '../PageContainer'
import ProgressButton from '../buttons/ProgressButton'
import DatePickerField from '../inputs/DatePickerField'
import CarrierSelect from '../../containers/inputs/CarrierSelect'
import CountrySelect from '../../containers/inputs/CountrySelect'
import AirportDestinationSelect from '../../containers/inputs/AirportDestinationSelect'
import AirportOriginSelect from '../../containers/inputs/AirportOriginSelect'
import InitialFindingSelect from '../../containers/inputs/InitialFindingSelect'
import IssueTypeSelect from '../../containers/inputs/IssueTypeSelect'
import IcaoHazardClassSelect from '../../containers/inputs/IcaoHazardClassSelect'
import IssueIdentifierSelect from '../../containers/inputs/IssueIdentifierSelect'
import RestrictedItemSelect from '../../containers/inputs/RestrictedItemSelect'
import UsStateSelect from '../../containers/inputs/UsStateSelect'
import DangerousItemSelect from '../../containers/inputs/DangerousItemSelect'
import Dropzone from 'react-dropzone'
import LoadingImage from '../../containers/LoadingImage'
import * as verif from '../../constants/incidentVerificationStatus'

const styles = (theme) => ({
  addIcon: {
    color: grey[600],
    cursor: 'pointer',
    marginRight: '64px',
    '&:hover': {
      color: blue[700]
    }
  },
  cancelIcon: {
    color: grey[600],
    cursor: 'pointer',
    marginRight: '64px',
    '&:hover': {
      color: red[700]
    }
  },
  container: {
    padding: theme.spacing(2)
  },
  errorIcon: {
    color: red[500]
  },
  hidden: {
    visibility: 'hidden'
  },
  iconColumn: {
    width: '30px'
  },
  tableBodyCell: {
    verticalAlign: 'top'
  },
  tableCell: {
    padding: '12px'
  },
  tooltip: {
    fontSize: '12px'
  },
  upldImage: {
    height: '100px',
    width: '100px',
    backgroundColor: 'lightblue',
    border: 'dashed',
    borderColor: 'darkblue'
  },
  dz: {
    height: '100px',
    width: '100px',
    borderColor: 'white',
    align: 'left'
  },
  image: {
    padding: '0 4px'
  }
})

const today = new Date();

function lettersNumbersCheck(name)
  {
    var regEx = /^[0-9a-zA-Z]+$/;
    if(name.match(regEx))
      { return true; }
    else      //alert("Please enter letters and numbers only.");
      { return false; }
  }  

  function zipPlusFourCheck(name)
  {
    var regEx = /^[0-9-]+$/;
    if(name.match(regEx))
      { return true; }
    else      //alert("Please enter numbers or dash only");
      { return false; }
  } 

  function domesticUsPhoneCheck(name)
  {
    var regEx = /^[0-9-( )x]+$/;
    if(name.match(regEx))
      { return true; }
    else      //alert("Please enter numbers parens or dash only");
      { return false; }
  } 
          //1111111111 
        //0 1-(800)111-1111 x1234


  

class TrackerForm extends Component {

  state = { 
    phone: ''
  }
 
  goBack = () => {
    // console.log('[components/TrackerForm] - goBack')
    this.props.onBackClick()
  }

  componentDidMount = () => {
    // console.log('[components/TrackerForm] - componentDidMount')
    this.props.change('dateReported',today);
    this.props.change('dateOfDiscovery',today); 
    this.props.deleteStagedImages()
    this.props.change('status',Incidents.DRAFT) 
    // console.log('[components/TrackerForm] - componentDidMount -- setting airlineReportingIncident to ', this.props.carrier)
    this.props.change('airlineReportingIncident', this.props.carrier)
    if (undefined === this.props.carrier) {
      // console.log('[components/TrackerForm] - componentDidMount -- setting airlineReportingIncident to --')
      this.props.change('airlineReportingIncident', '--')
    }
    // console.log('[components/TrackerForm] - componentDidMount -- setting agencyReportingIncident to ', this.props.agency)
    this.props.change('agencyReportingIncident', this.props.agency)
    if (undefined === this.props.agency) {
      // console.log('[components/TrackerForm] - componentDidMount -- setting agencyReportingIncident to --')
      this.props.change('agencyReportingIncident', '--')
    }
    // console.log('[components/TrackerForm] - componentDidMount -- setting entityReportingIncident to ', this.props.entity)
    this.props.change('entityReportingIncident', this.props.entity)
    if (undefined === this.props.entity) {
      // console.log('[components/TrackerForm] - componentDidMount -- setting entityReportingIncident to --')
      this.props.change('entityReportingIncident', '--')
    }
  }


  render () {

    // console.log('[components.TrackerForm]  render ')    
    const {    
      asyncValidating,
      classes,
      handleSubmit,
      onSnackbarClose,
      snackbarOpen,
      submitting,
      stageImage,
      role,
      carrier } = this.props;      

      // console.log('[components.TrackerForm]  role= ' + this.props.role)    

    const stageIt = (acceptedFiles) =>{
      acceptedFiles.forEach((file) => {
        new Promise((resolve,reject) => {
          let reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            if (!!reader.result){
              resolve(reader.result)
            }
            else{
              reject(Error("Failed converting to base64"))
            }
          }
        })
        .then(result => {
          file.dataUrl = result.split(',')[1]
          stageImage(file)
        })
        .catch(err =>{
          // console.log(err)
        })
      })
    }


    let issueTypeSelect = (
    <Fragment>
      <Grid item xs={12} md={3}>
        <Field
          component={IssueTypeSelect}
          fullWidth
          label='Issue Type'
          variant='outlined'
          helperText='Required'
          name='issueType'                  
        />
      </Grid> 
    </Fragment> 
    )
    if ('ND' !== this.props.initialFindingValue) issueTypeSelect = null;     


    let icaoHazardClassSelect = (             
    <Fragment>
      <Grid item xs={12} md={3}>
        <Field
          component={IcaoHazardClassSelect}
          fullWidth
          label='Icao Hazard Class'
          variant='outlined'
          helperText='Required'
          name='icaoHazardClass'
        />
      </Grid> 
    </Fragment>              
    )
    if ('DG' !== this.props.initialFindingValue) icaoHazardClassSelect = null;   

    let dangerousItemSelect = (
      <Fragment>
        <Grid item xs={12} md={3}>
          <Field
            component={DangerousItemSelect}
            fullWidth
            label='Dangerous Item Type'
            variant='outlined'
            helperText='Required'
            name='dangerousItem'
            hazclass={this.props.icaoHazardClassValue}                                 
          />
        </Grid>  
      </Fragment>
      )
      if ('DG' !== this.props.initialFindingValue) dangerousItemSelect = null;  
      if ('Not Selected' === this.props.icaoHazardClassValue) dangerousItemSelect = null;  
      if (undefined === this.props.icaoHazardClassValue) dangerousItemSelect = null;  

    let issueIdentificationMeansSelect = (
      <Fragment>
        <Grid item xs={12} md={3}>
          <Field
            component={IssueIdentifierSelect}
            fullWidth
            label='How Alarm Incident was Identified'
            name='issueIdentificationMeans'
          />
        </Grid>
      </Fragment>
    )
    if ('NS' === this.props.initialFindingValue ) issueIdentificationMeansSelect = null;  
    if ('DG' === this.props.initialFindingValue && 'Not Selected' === this.props.icaoHazardClassValue) issueIdentificationMeansSelect = null;  
    if ('ND' === this.props.initialFindingValue && 'NS' === this.props.issueTypeValue) issueIdentificationMeansSelect = null;  
    if ('ND' === this.props.initialFindingValue && 'RI' === this.props.issueTypeValue && 'Not Selected' === this.props.restrictedItemValue) issueIdentificationMeansSelect = null;  

    let restrictedItemSelect = (
      <Fragment>
        <Grid item xs={12} md={5}>
          <Field
            component={RestrictedItemSelect}
            fullWidth
            label='Restricted Item Type'
            name='restrictedItem'                 
          />
        </Grid> 
        <Grid item xs={12} md={7}></Grid> 
      </Fragment>
    )
    if ('RI' !== this.props.issueTypeValue) restrictedItemSelect = null;
    if ('ND' !== this.props.initialFindingValue) restrictedItemSelect = null; 

    let  commentsGeneralField = (
      <Fragment>
        <Grid item xs={12} md={6}>
        <Field
            autoComplete='off'
            component={TextField}
            fullWidth
            inputProps={{ maxLength: 100 }}
            label='Additional Comments'
            name='commentsGeneral'
          />
        </Grid>  
      </Fragment>
    )

    if ('NS' === this.props.initialFindingValue)    commentsGeneralField = null;
    if ('DG' === this.props.initialFindingValue     && 'Not Selected' === this.props.dangerousItemValue )     commentsGeneralField = null;
    if ('DG' === this.props.initialFindingValue     && 'Not Selected' === this.props.icaoHazardClassValue )     commentsGeneralField = null;
    if ('ND' === this.props.initialFindingValue     && 'NS'           === this.props.issueTypeValue )         commentsGeneralField = null;
    if ('ND' === this.props.initialFindingValue     && 'RI'           === this.props.issueTypeValue           && 'Not Selected' === this.props.restrictedItemValue )   commentsGeneralField = null;
        
    //Set customs barcode verification status -- begin
    if (true === this.props.skipBarcodeCustomsVerificationValue) {
      // console.log('Setting Customs BC verif status to 5 verif.SKIPPED_BY_USER because skip Customs BC verif is true')
      this.props.change('barcodeCustomsVerificationStatus',verif.SKIPPED_BY_USER) 
    } 
    else {
      // console.log('Setting Customs BC verif status to 0 verif.PENDING because skip Customs BC verif is false')
      this.props.change('barcodeCustomsVerificationStatus',verif.PENDING) 
      var bcCustomsValue = this.props.barcodeCustomsValue;
      var bcCustomsVerifNewValue = verif.PENDING;
      if ((undefined === bcCustomsValue) || (null===bcCustomsValue) || (''===bcCustomsValue) || (13!==bcCustomsValue.length) || (!lettersNumbersCheck(bcCustomsValue))) {
        bcCustomsVerifNewValue = verif.INVALID_PER_BIZ_RULES;
      }
      else {
        bcCustomsVerifNewValue = verif.VALID_PER_BIZ_RULES;;
      }
      if (verif.SKIPPED_BY_USER !== this.props.barcodeCustomsVerificationStatusValue) {
        this.props.change('barcodeCustomsVerificationStatus',bcCustomsVerifNewValue) 
      }
    }
    //Set customs barcode verification status -- end

    //Set Cn35 barcode verification status -- begin
    if (true === this.props.skipBarcodeCn35VerificationValue) {
      // console.log('Setting Cn35 BC verif status to 5 verif.SKIPPED_BY_USER because skip Cn35 BC verif is true')
      this.props.change('barcodeCn35VerificationStatus',verif.SKIPPED_BY_USER) 
    } 
    else {
      // console.log('Setting Cn35 BC verif status to 0 verif.PENDING because skip Cn35 BC verif is false')
      this.props.change('barcodeCn35VerificationStatus',verif.PENDING) 
      var bcCn35Value = this.props.barcodeCn35Value;
      var bcCn35VerifNewValue = verif.PENDING;
      // console.log('BC Cn35 text = ',bcCn35text)
      if ((undefined === bcCn35Value) || (null===bcCn35Value) || (''===bcCn35Value) || (29!==bcCn35Value.length) || (!lettersNumbersCheck(bcCn35Value))) {
        bcCn35VerifNewValue = verif.INVALID_PER_BIZ_RULES;
      }
      else {
        bcCn35VerifNewValue = verif.VALID_PER_BIZ_RULES;;
      }
      // console.log('barcodeCn35VerificationStatusValue was ',this.props.barcodeCn35VerificationStatusValue)
      if (verif.SKIPPED_BY_USER !== this.props.barcodeCn35VerificationStatusValue) {
        // console.log('Setting barcodeCn35VerificationStatus to',bcCn35newValue)
        this.props.change('barcodeCn35VerificationStatus',bcCn35VerifNewValue) 
      }
    }
    //Set Cn35 barcode verification status -- end

    return (
      <PageContainer>
        <Typography className={classes.container} variant='h5'>Create Incident - Draft</Typography>
        <Divider />

        <form className={classes.container} onSubmit={handleSubmit}>
        
          <Grid container direction='column' spacing={3}>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>                              
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='CN-35 Barcode'
                  helperText='Required'
                  variant='outlined'
                  inputProps={{ minLength: 29, maxLength: 29  }}
                  name='barcodeCn35'
              />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography><label for='skipBarcodeCn35Verification'>
                  <Field
                    autoComplete='off'
                    component={Checkbox}
                    fullWidth
                    label='Skip CN-35 BC Verification'
                    variant='outlined'
                    name='skipBarcodeCn35Verification'
                  />
                  Skip CN-35 BC Verification</label></Typography>
              </Grid>
              <Field
                type='hidden'
                autoComplete='off'
                component={TextField}
                inputProps={{  maxLength: 4  }}
                name='barcodeCn35VerificationStatus'
                readonly={true}
              />
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>  
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Customs Barcode'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ minLength: 13, maxLength: 13 }}
                  name='barcodeCustoms'
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography><label for='skipBarcodeCustomsVerification' >
                  <Field
                    autoComplete='off'
                    component={Checkbox}
                    fullWidth
                    label='Skip Customs BC Verification'
                    variant='outlined'
                    name='skipBarcodeCustomsVerification'
                  />
                  Skip Customs BC Verification</label></Typography>
              </Grid>
              <Field
                type='hidden'              
                autoComplete='off'
                component={TextField}
                inputProps={{  maxLength: 4  }}
                name='barcodeCustomsVerificationStatus'
                readonly={true}
              />
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>                              
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Handling Unit Barcode'
                  variant='outlined'
                  inputProps={{ minLength: 29, maxLength: 29  }}
                  name='barcodeHandlingUnit'
              />
              </Grid>
            </Grid>          

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
            { (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY || role === Roles.AGENT) &&
              <Grid item xs={12} md={3}>
                <Field
                  component={CarrierSelect}
                  fullWidth
                  variant='outlined'
                  helperText='Required'
                  label='Reporting Airline'
                  name='airlineReportingIncident'
                  onChange =  {(event) => {this.props.change('airCarrierFullName',event.currentTarget.attributes.altvalue.value)}}           
                />
              </Grid>
            }
            { (role === Roles.CARRIER) &&
                  <Grid item xs={12} md={3}>
                  <TextField
                      fullWidth
                      label='Reporting Airline'
                      readOnly
                      variant='outlined'
                      name='airlineReportingIncident'
                      value = { carrier }             
                  />
              </Grid>
            }

            { (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY) &&
                  <Grid item xs={12} md={3}>
                    <Field
                        autoComplete='off'
                        component={TextField}
                        fullWidth                         
                        label='Reporting Agency Abbr'
                        variant='outlined'
                        inputProps={{  maxLength: 10  }}
                        name='agencyReportingIncident'
                    />
              </Grid>
            }
            { (role === Roles.AGENT || role === Roles.CARRIER) &&
                  <Grid item xs={12} md={3}>
                    <Field
                        autoComplete='off'
                        component={TextField}
                        fullWidth
                        disabled                      
                        label='Reporting Agency Abbr'
                        variant='outlined'
                        name='agencyReportingIncident'
                    />
              </Grid>
            }

            { (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY) &&
              <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    disabled                 
                    label={'Entity Type'}
                    name='entityReportingIncident'
                    value='DGADMIN'
                  />
              </Grid>   
            }       
            { (role ===  Roles.CARRIER) &&
              <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    disabled
                    label={'Entity Type'}
                    name='entityReportingIncident'
                    value='CARRIER'
                  />
              </Grid>   
            }      
            { (role === Roles.AGENT) &&
              <Grid item xs={12} md={4}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    disabled
                    variant='outlined'
                    label={'Entity Type'}
                    name='entityReportingIncident'
                    value='AGENT'
                  />
              </Grid>   
            }                

              <Grid item xs={12} md={3}>
                <Field
                  component={DatePickerField}
                  disabled
                  fullWidth
                  label='Date Reported'
                  variant='outlined'
                  helperText='Required'
                  name='dateReported'
                  // value={today}
                />
              </Grid>   
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Reporting Contact Name'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ maxLength: 40 }}
                  name='reportingContactName'
                />
              </Grid>  
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Reporting Contact Phone'
                  variant='outlined'
                  helperText='Required'
                  // normalize={NormalizePhone}
                  name='reportingContactPhone'
                />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Reporting Contact Email'
                  type='email'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ maxLength: 72 }}
                  name='reportingContactEmail'
                />
              </Grid> 
            </Grid> 

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              <Grid item xs={12} md={3}>
                <Field
                  component={AirportDestinationSelect}
                  fullWidth
                  label='Reporting Airport Hub'
                  variant='outlined'
                  helperText='Required'
                  name='reportingAirportHub'
                  onChange =  {(event) => {
                    this.props.change('airportHubFullName',event.currentTarget.attributes.altvalue.value  );                                    
                  }}                                                     
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  component={DatePickerField}
                  fullWidth
                  variant='outlined'
                  helperText='Required'
                  label='Date of Discovery'
                  name='dateOfDiscovery'
                  // value={today}
              />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
              <Grid item xs={12} md={3}>
                <Field
                  component={AirportOriginSelect}
                  fullWidth
                  label='USA Origin Airport (Office of Exchange/ISC)'
                  variant='outlined'
                  helperText='Required'
                  name='originOfficeOfExchange'
                  onChange =  {(event) => {
                    this.props.change('originAirport',event.currentTarget.attributes.altvalue.value  );                                    
                  }}                     
                />
              </Grid>  
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Airline Record/Incident Number'
                  inputProps={{ maxLength: 40 }}
                  name='airlineRecordNo'
                />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              <Grid item xs={12} md={3}>
                <Field
                  component={InitialFindingSelect}
                  fullWidth
                  label='Initial Findings'
                  variant='outlined'
                  helperText='Required'
                  name='initialFinding'              
                />
              </Grid>
              {icaoHazardClassSelect}  
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              {issueTypeSelect}             
              {restrictedItemSelect}           
            </Grid>
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                {issueIdentificationMeansSelect}
                {dangerousItemSelect}                          
            </Grid>
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                {commentsGeneralField}
            </Grid>
            <Typography className={classes.container} variant='subtitle1'>Sender</Typography>
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Company'
                  variant='outlined'
                  // helperText='Required'
                  inputProps={{ maxLength: 28 }}
                  name='senderCompany'
                />
              </Grid> 
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Name'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ maxLength: 24 }}
                  name='senderName'
                />
              </Grid> 
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Address'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ maxLength: 25 }}
                  name='senderAddress'
                />
              </Grid> 
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Address 2'
                  inputProps={{ maxLength: 25 }}
                  name='senderAddress2'
                />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>                                          
              <Grid item xs={12} md={3}>
                <Field
                  component={UsStateSelect}
                  fullWidth
                  label='State'
                  variant='outlined'
                  helperText='Required'
                  name='senderState'
                />
              </Grid> 
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='City'
                  variant='outlined'
                  helperText='Required'
                  name='senderCity'
                />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              <Grid item xs={12} md={3}>
                  <Field
                    autoComplete='off'
                    component={TextField}
                    fullWidth
                    label='ZIP'
                    variant='outlined'
                    helperText='Required'
                    inputProps={{ minLength: 5, maxLength: 10 }}
                    name='senderZip'
                  />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Phone'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ minLength: 10, maxLength: 25 }}
                  // normalize={NormalizePhone}
                  name='senderPhone'
                />
              </Grid> 
            </Grid>

            <Typography className={classes.container} variant='subtitle1'>Recipient/Consignee</Typography>   
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Company'
                  variant='outlined'
                  // helperText='Required'
                  inputProps={{ maxLength: 28 }}
                  name='recipientCompany'
                />
              </Grid> 
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Name'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ maxLength: 37 }}
                  name='recipientName'
                />
              </Grid> 
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Address'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ maxLength: 44 }}
                  name='recipientAddress'
                />
              </Grid> 
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Address 2'
                  inputProps={{ maxLength: 35 }}
                  name='recipientAddress2'
                />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>                                           
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='City/Locale'
                  variant='outlined'
                  helperText='Required'
                  name='recipientCity'
                />
              </Grid> 
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Post Code'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ maxLength: 13 }}
                  name='recipientZip'
                />
              </Grid> 
            </Grid>

            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              <Grid item xs={12} md={3}>
                <Field
                  component={CountrySelect}
                  fullWidth
                  label='Country'
                  variant='outlined'
                  helperText='Required'
                  name='recipientCountry'
                />
              </Grid>  
              <Grid item xs={12} md={3}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Phone'
                  variant='outlined'
                  helperText='Required'
                  // normalize={NormalizePhone}
                  name='recipientPhone'
                />
              </Grid>
            </Grid>

            <Divider />
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              <Grid item xs={12} md={6}>
                <Field
                  autoComplete='off'
                  component={TextField}
                  fullWidth
                  label='Content Description written from Customs Form'
                  variant='outlined'
                  helperText='Required'
                  inputProps={{ maxLength: 86 }}
                  name='contentDescrnOnCustomsForm'
                />
              </Grid> 
            </Grid>
            {/* <WorkNotes/> */}
            <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete='off'
                  fullWidth
                  label='work note'
                  variant='outlined'
                  name='workNote'
                  onChange={(event) => this.props.updateWorkNote(event.target.value)}
                />
            </Grid> 
          </Grid>

          <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
            {this.props.staged_images.map ?
              this.props.staged_images.map((image, index)=>(
                <LoadingImage image={image.imageFile.dataUrl} />
              ))
              :
              ''
            }
            {role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.CARRIER || role === Roles.AGENT ?
              <Dropzone accept='.jpg, .png' onDrop={acceptedFiles => stageIt(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div className={classes.dz} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className={classes.upldImage}>Drag 'n' drop, or click to select files</div>
                    </div>
                  </section>
                )}
              </Dropzone>
              :''
            }
          </Grid>

          <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
            <Grid item xs={12} md={2}>
            {role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.CARRIER || role === Roles.AGENT ?                
              <ProgressButton
                color='primary'
                submitting={submitting || asyncValidating === true}
                type='submit'
                variant='contained'
              >
                SUBMIT COMPLETE
              </ProgressButton>
              :''
            }
            </Grid>
            <Grid item xs={12} md={2}>
            {role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.CARRIER || role === Roles.AGENT ?                    
              <ProgressButton
                color='primary'
                variant='contained'
                onClick={()=>{
                  this.props.saveDraft()
                }}
              >
                SAVE AS DRAFT
              </ProgressButton>
              :''
            }                
            </Grid>
          </Grid> 
              
          </Grid>

        </form>
        <Snackbar
          autoHideDuration={10000}
          message='Incident created [click away to dismiss]'
          onClose={onSnackbarClose}
          open={snackbarOpen}
        />
      </PageContainer>
    )
  }
}

const validate = (values) => {
  // console.log('[components/TrackerForm]  validate')
  const errors = {}
  //console.log('[components/TrackerForm]  in validate')
  let kvArray=[
    //TODO:  airlineReportingIncident is required of Air Carrier.  It may not be required of military(?)  Is it required of USPIS / DG_ADMIN?
    //['airlineReportingIncident','Reporting Airline must be selected'],
    // ['reportingContactName','Please enter a valid contact name'],
    // ['reportingContactPhone','Please enter a valid phone number'],
    // ['reportingContactEmail','Please enter a valid email'],
    // ['reportingAirportHub','Reporting Airport Hub must be selected'],
    ['dateOfDiscovery', 'must be filled out'],
    // ['originOfficeOfExchange','USA Origin Airport must be selected'],
    // ['airlineRecordNo','Please fill out Airline Record Number'],
    ['barcodeCn35','CN35 Barcode must be 29 alphanumeric characters'],
    ['barcodeCustoms','Customs Barcode must be 13 alphanumeric characters'],
    ['barcodeHandlingUnit','Handling Unit Barcode must be 29 alphanumeric characters if filled'],
    // ['initialFinding','Initial Finding must be selected'],
    // ['issueType','Issue Type must be selected'],
    // ['icaoHazardClass','ICAO Hazard Class must be selected'],
    // ['dangerousItem',' Dangerous Item Type must be selected'],
    // ['restrictedItem','Restricted Item Type must be selected'],
    // ['issueIdentificationMeans','How Alarm Incident was Identified must be selected'],  
    ['senderPhone','Please enter a valid domestic US phone format'],
    // ['senderCompany','Please enter a valid company'],   
    // ['senderName','Please enter a valid name'],
    // ['senderAddress','Please enter a valid address'],
    // ['senderState','State must be selected'],
    ['senderZip','must be a valid ZIP or ZIP+4 format'],
    // ['recipientPhone','Please enter a valid recipient phone'],
    // ['recipientCompany','Please enter a valid recipient company'], 
    // ['recipientName','Please enter a valid name'],
    // ['recipientAddress','Please enter a valid address'],
    // ['recipientZip','Please enter a valid postal code'],
    // ['recipientCountry','Country must be selected'],
    // ['contentDescrnOnCustomsForm','Please enter a valid description']
  ]
  //console.log('[components/TrackerForm]  in validate')
  let myMap = new Map(kvArray);

  //console.log('[components/TrackerForm]  in validate')
  const requiredFields = [
    'barcodeCn35',
    'barcodeCustoms',
    'reportingContactName',
    'reportingContactPhone',
    'reportingContactEmail',
    'reportingAirportHub',
    'dateOfDiscovery',
    'originOfficeOfExchange',
    'initialFinding',
    'issueType',
    'icaoHazardClass',
    'dangerousItem',
    'senderPhone',            
    'senderName',
    'senderAddress',
    'senderState',
    'senderCity',
    'senderZip',
    'recipientPhone',
    'recipientName',
    'recipientAddress',
    'recipientCity',
    'recipientCountry',
    'recipientZip',
    'contentDescrnOnCustomsForm'
    ]
    //console.log('[components/TrackerForm]  in validate')
  //TODO -- prefer dashString constant instead of '--' magic value
  requiredFields.forEach((field) => {
    if (values[field] === '' || values[field] === null || values[field] === undefined || values[field] === '--') {
      errors[field] = myMap.get(field) || 'Required'
    }
  })


  //Validate Customs barcode -- begin
  var bcCustomsText = values['barcodeCustoms'];
  if ((undefined === bcCustomsText) || (null===bcCustomsText) || (''===bcCustomsText) || (13!==bcCustomsText.length) || (!lettersNumbersCheck(bcCustomsText))) {
    if (0 >= values['barcodeCustomsVerificationStatus']) {
      errors['barcodeCustoms'] =  myMap.get('barcodeCustoms') || 'Required'
    }
  }
  //Validate Customs barcode -- end


  //Validate Cn35 barcode -- begin
  var bcCn35text = values['barcodeCn35'];
  if ((undefined === bcCn35text) || (null===bcCn35text) || (''===bcCn35text) || (29!==bcCn35text.length) || (!lettersNumbersCheck(bcCn35text))) {
    if (0 >= values['barcodeCn35VerificationStatus']) {
      errors['barcodeCn35'] =  myMap.get('barcodeCn35') || 'Required'
    }
  }
  //Validate Cn35 barcode -- end


  //Validate HU barcode -- begin    
  var bcHutext = values['barcodeHandlingUnit'];
  if ((undefined === bcHutext) || (null===bcHutext) || (''===bcHutext)) { /*OK*/ }
  else if ((29!==bcHutext.length) || (!lettersNumbersCheck(bcHutext))) {
    errors['barcodeHandlingUnit'] =  myMap.get('barcodeHandlingUnit') || 'Must be complete if filled'
  }
  //Validate HU barcode -- end   

    //Validate Sender ZIP -- begin    
    var senderZipText = values['senderZip'];
    // console.log('senderZipText=',senderZipText)
    if ((undefined === senderZipText) || (null===senderZipText) || (''===senderZipText)) { /*OK*/ }
    else if ((5>senderZipText.length) || (10<senderZipText.length) || (!zipPlusFourCheck(senderZipText))) {
      errors['senderZip'] =  myMap.get('senderZip') || 'Must be complete if filled'
    }
    //Validate Sender ZIP-- end   

        //Validate Sender Phone -- begin    
        var senderPhoneText = values['senderPhone'];
        // console.log('senderPhoneText=',senderPhoneText)
        if ((undefined === senderPhoneText) || (null===senderPhoneText) || (''===senderPhoneText)) { /*OK*/ }
        else if ((10>senderPhoneText.length) || (25<senderPhoneText.length) || (!domesticUsPhoneCheck(senderPhoneText))) {
          errors['senderPhone'] =  myMap.get('senderPhone') || 'Must be complete if filled'
        }
        //Validate Phone ZIP-- end  
        //1111111111 
        //0 1-(800)111-1111 x1234


    //senderPhone

  const requiredDropdowns = [
    'airlineReportingIncident',
     'reportingAirportHub',
     'originOfficeOfExchange',
     'initialFinding',
     'issueIdentificationMeans',
     'senderState',
     'recipientCountry' 
    ]

    requiredDropdowns.forEach((field) => {
      if (values[field] === '' || values[field] === null || values[field] === undefined || values[field] === '--' || values[field] === '---' || values[field] === 'NS' || values[field] === 'Not Selected') {
        // console.log('mustBeSelectedField-- ', field)
        errors[field] = myMap.get(field) || 'Must Be Selected'
      }
    })
    //console.log('[components/TrackerForm]  in validate')
  return errors
 
}

TrackerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,          //Declares and requires handleSubmit() function passed from containers.TrackerForm.mapDispatchToProps
  snackbarOpen : PropTypes.bool.isRequired,
  onSnackbarClose: PropTypes.func.isRequired,      //Declares and requires onSnackbarClose () function passed from containers.TrackerForm.mapDispatchToProps
  onBackClick: PropTypes.func.isRequired     //Declares and requires onBackClick() function passed from containers.TrackerForm.mapDispatchToProps
}

export default compose(
  reduxForm({
    form: 'TrackerForm',
    validate   
  }),
  withStyles(styles)
)(TrackerForm)

