import { connect } from 'react-redux'
import AdminHome from '../components/AdminHome'
import * as TabActions from '../actions/tab'
import { getAllPendingPersons } from '../services/person'

const mapStateToProps = (state, ownProps) => {
  // console.log("[containers/AdminHomePage] mapStateToProps")
  const role = state.user.role
  const onLoadPersonData = () => {
    // console.log("[containers/AdminHomePage/mapStateToProps] - onLoadPersonData")
    return getAllPendingPersons()
  }
  return {
    role,
    onLoadPersonData
  }


}

const mapDispatchToProps = (dispatch, ownProps) => {
  // console.log("[containers/AdminHomePage] mapDispatchToProps")
    const onTabChange = (value) => {
        dispatch(TabActions.change('persons', value))
      }
  return {
    onTabChange
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome)
