import React, { Component } from 'react'
import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'     //Session Storage
// import localStorage from 'redux-persist/lib/storage'          //Local Storage

import DateFnsUtils from '@date-io/date-fns'

//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { indigo, red } from '@material-ui/core/colors'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import rootReducer from '../reducers'
import configureStore from '../store/configureStore'
import createRoutes from '../routes.js'

require('../global.css')

const persistConfig = {                                    //Session Storage
  key: 'domir',
  storage
}

// const persistConfig = {                                       //Local Storage
//   key: 'persist:domir',
//   storage: localStorage
// }

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore(persistedReducer)

const persistor = persistStore(store)

const muiTheme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: red
  },
  typography: {
    useNextVariants: true
  }
})

export default class Root extends Component {
  render () {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {createRoutes()}
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    )
  }
}
