import React from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'

import config from './config'
import AuthRedirect from './containers/AuthRedirect'

import App from './containers/App'
import LoginPageInternal from './containers/LoginPageInternal'
import LoginPageExternal from './containers/LoginPageExternal'

import WelcomePage from './containers/WelcomePage'
import HomePage from './containers/HomePage'
import AdminHomePage from './containers/AdminHomePage'
import TrackerForm from './containers/TrackerForm'
import CreatePerson from './containers/CreatePerson'
import CreateAgent from './containers/CreateAgent'
import EditForm from './containers/Edit/EditForm'
import ViewForm from './containers/View/ViewForm'
import ViewPersonForm from './containers/ViewPerson/ViewPersonForm'

// import LoginBoth from './components/LoginBoth'

const LoginRedirect = () => <Redirect to='/login' />

// const debug = false;

const createRoutes = (history) => 
  (

  <Router basename={config.BASE_URL}>
    <div>
      <Route path='/' component={App} />
      <Route exact path='/' render={LoginRedirect} />
      <Switch>
        {// Carrier signs up for new account.  Does not need to login
        }
        <Route path='/carriersignup' component={CreatePerson} /> 

        <Route path='/agentsignup' component={CreateAgent} /> 

        {// Debug only.  Page allows internal and external logins on the same page.
        }
        {/* { (true === debug) && (
        <Route path='/login' component={LoginBoth} />           
        ) } */}
        
        {// External login for Air Carrier Users
        }
        { ('external' === config.LOGIN) && (
        <Route path='/login' component={LoginPageExternal} />    
        // <Route path='/carriersignup' component={CreatePerson} />        
        ) }

        {// Internal login for USPS DG Admin Users
        }
        {('internal' === config.LOGIN) && (
        <Route path='/login' component={LoginPageInternal} />   
        // <Route path='/persons' component={AuthRedirect(CreatePerson)} />   
        // <Route path='/adminhome' component={AuthRedirect(AdminHomePage)} />                  
        ) }

        {// Welcome 'Landing Page' for the sole purpose of completing the login before we try to view incidents.
        }
        <Route path='/welcome' component={AuthRedirect(WelcomePage)} />

        {// Home 'Landing Page' which lists all incidents
        }
        <Route path='/home' component={AuthRedirect(HomePage)} />

        {// Create New Incident on the Tracker Form
        }        
        <Route path='/tracker' component={AuthRedirect(TrackerForm)} />
        {// Edit existing Incident
        }          
        <Route path='/edit' component={AuthRedirect(EditForm)} />
        {// View existing Incident -- Read Only
        }            
        <Route path='/view' component={AuthRedirect(ViewForm)} />    
        {// Admin 'home page' which lists all pending user requests.  (Originally based on 'home page' incident list)
        } 
        <Route path='/adminhome' component={AuthRedirect(AdminHomePage)} /> 
        {// Form used to 'Create Person' by adding a new User pending approval
        }         
         <Route path='/persons' component={AuthRedirect(CreatePerson)} /> 
        {// Admin form used to approve or deny new Users
        } 
        <Route path='/viewperson' component={AuthRedirect(ViewPersonForm)} />       
        
      </Switch>
    </div>
  </Router>
  )



export default createRoutes
