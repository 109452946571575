import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { indigo } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'


import PageContainer from './PageContainer'

const styles = {
  // message: {
  //   left: 0,
  //   position: 'absolute',
  //   textAlign: 'center',
  //   top: '70px',
  //   width: '100%'
  // },
  // messageFilter: {
  //   top: '150px'
  // }
}

class Welcome extends Component {
 
  // componentDidMount () {
  //   console.log('[components.Welcome] componentDidMount ')
  //   this.clickLink(null);
  // }

  // clickLink = (event) => {
  //   const { onTabChange } = this.props
  //   console.log('[components.Welcome] clickLink')
  //   document.getElementById('linkid').click();
  // }

  render () {
    // console.log('[components.Welcome] render ')
    const { role} = this.props
    return (
      <PageContainer>
              <Grid container direction='column' justify='flex-start' alignment='center' item spacing={0}>
                    <Grid item xs={12} md={6}>&nbsp;</Grid>
                    <Grid item xs={12} md={6}>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={0}>
                      <Grid item xs={12} md={6}>&nbsp;</Grid>
                      <Grid item xs={12} md={6}>
                          <Typography >
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                              <em><b>Notice:  This portal must ONLY be used to report Dangerous Goods.  Please report non-DG issues though the proper channels.</b></em>
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>&nbsp;</Grid>
                    </Grid>
              </Grid>
              </Grid>
              <Grid container direction='column' justify='flex-start' alignment='center' item spacing={0}>
                    <Grid item xs={12} md={6}>&nbsp;</Grid>
                    <Grid item xs={12} md={6}>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={0}>
                      <Grid item xs={12} md={6}>&nbsp;</Grid>
                      <Grid item xs={12} md={6}>
                          <Typography >
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            <Link id='linkid'
                              style={{ color: indigo[300] }}
                              to={'/home'}
                            >
                              <em><b>Welcome to the Dangerous Goods application.  Please click on this link to continue.</b></em>
                            </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>&nbsp;</Grid>
                    </Grid>
              </Grid>
          {/* <Link to='/home'>'Welcome to Dangerous Goods.  Please click to continue.'</Link> */}
              <Grid item xs={12} md={6}>&nbsp;</Grid>
              </Grid>

      </PageContainer>
    )
  }
}

export default withStyles(styles)(Welcome)
