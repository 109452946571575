import * as IncidentActions from '../actions/incident'

import * as Incidents from '../constants/incidents'

const initialState = {
}

const incident = (state = initialState, action) => {
  // console.log('[reducers/incident] -- action.type = ',action.type)
  switch (action.type) {
    case IncidentActions.FIELD_CHANGE:
      return {
        ...state,
        selected_incident: {...state.selected_incident, [action.name]: action.value}
      }
    case IncidentActions.MARK_COMPLETE:
      return {
        ...state,
        selected_incident: {...state.selected_incident, status: Incidents.INPROCESS}
      }
    case IncidentActions.SELECT_INCIDENT:
      return {
        ...state,
        selected_incident: action.incident
      }
    case IncidentActions.SELECT_INCIDENT_IMAGES:
      return {
        ...state,
        selected_incident_images: action.selImages
      }
    case IncidentActions.DELETE_STAGED_IMAGES:
      return {
        ...state,
        staged_images: []
      }
    case IncidentActions.CLEAR_SELECTED_IMAGES:
      return {
        ...state,
        selected_incident_images: []
      }
    case IncidentActions.ADD_STAGE_IMAGE:
      let newStaged = []
      if ((state.staged_images !== '') && (state.staged_images !== null)) {
        newStaged = state.staged_images.slice(0)
      }
      newStaged.push({imageFile: action.stageFile})
      return {
        ...state,
        staged_images: newStaged
      }
    case IncidentActions.SELECT_INCIDENT_WORKNOTES:
      return {
        ...state,
        current_worknotes: action.selWorkNotes
      }
    case IncidentActions.UPDATE_WORK_NOTE:
      return {
        ...state,
        current_note: action.note
      }
    case IncidentActions.DELETE_CURRENT_NOTE:
      return {
        ...state,
        current_note: null
      }
    default:
      return state
  }
}

export default incident
