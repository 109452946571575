import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  tooltip: {
    fontSize: '12px'
  }
}

const tooltipMap = {
  DRAFT: 'Hazardous Incident Draft',
  INPROCESS: 'Hazardous Incident submitted',
  CLOSED: 'Hazardous Incident Resolved'
 
}

const StatusTooltip = ({ children, classes, status, ...other }) => (
  <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipMap[status]} {...other}>
    {children}
  </Tooltip>
)

export default withStyles(styles)(StatusTooltip)
