import * as CarrierServices from '../services/carrier'

export const RETRIEVE_ALL_CARRIERS_SUCCESS = 'RETRIEVE_ALL_CARRIERS_SUCCESS'

/**
 * 
 * @returns 
 */

export const retrieveAllCarriers = () => (dispatch, getState) => {
  const state = getState()
  const carriers = state.carrier.carriers

  if (carriers && carriers.length > 0) {
    return
  }

  return CarrierServices.getAllCarriers()
    .then((carriers) => {
      const sortedCarriers = carriers.slice()
      sortedCarriers.sort((a, b) => {
        return a.carrierName > b.carrierName
      })

      dispatch({
        type: RETRIEVE_ALL_CARRIERS_SUCCESS,
        carriers: sortedCarriers
      })
    })
}

export const RETRIEVE_PILOT_CARRIERS_SUCCESS = 'RETRIEVE_PILOT_CARRIERS_SUCCESS'

/**
 * 
 * @returns 
 */
export const retrievePilotCarriers = () => (dispatch, getState) => {
  const state = getState()
  const carriers = state.carrier.carriers

  if (carriers && carriers.length > 0) {
    return
  }

  return CarrierServices.getPilotCarriers()
    .then((carriers) => {
      const sortedCarriers = carriers.slice()
      sortedCarriers.sort((a, b) => {
        return a.carrierName > b.carrierName
      })

      dispatch({
        type: RETRIEVE_PILOT_CARRIERS_SUCCESS,
        carriers: sortedCarriers
      })
    })
}
