import 'isomorphic-fetch'
import config from '../config'
import * as Roles from '../constants/roles'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const ERROR_LOGIN = 'ERROR_LOGIN'
export const LOGOUT = 'LOGOUT'

export const REFRESH = 'REFRESH'

export const TOKEN_REFRESH_OFFSET = 30000;  // How soon before the session expires to refresh the token.  In milliseconds.

let authtimer = ''

/**
 * 
 * @param {*} userId 
 * @param {*} password 
 * @param {*} source 
 * @returns 
 */
export const logIn = (userId, password, source) => (dispatch) => {
    // console.log("[actions.user] login  source=",source)

  if('internal' === source) {
    //  console.log('^^^^^^^^ internal user -- LDAP authentication ^^^^^^^^^^^ ')    
    return fetch(`${config.ENDPOINT}/auth/authenticate`, {
      headers: {
        //Authorization: config.AUTHORIZATION,
        'cache-control': 'no-cache',
        pragma: 'no-cache',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        username: userId,
        password: window.btoa(password)
      })
    }).then((res) => {
      // console.log('[actions/user.js] - logIn returned res: ',res)
      return res.json()
    }).then((response) => {
      // console.log('[actions/user.js] - response: ',response)
      const data = response.data || {}
      // console.log('Role=', data.role)
      // console.log('Carrier=', data.carrierAbbr)
      if (response.status !== 'success' 
    //  || (data.role !== Roles.DANGEROUS_GOODS_ADMIN && data.role !== Roles.DANGEROUS_GOODS_ADMIN_READONLY /* && data.role !== Roles.IDP_DANGEROUS_GOODS */)
      ) {
        // console.log('[actions/user.js] - logIn failed -- Invalid username/password, or user lacks Admin role')
        // console.log('response',response);
        dispatch({
          type: LOGIN_FAILED,
          message: 'Invalid username/password, or user lacks Admin role'
        })
        return false
      }
      // console.log('[actions/user.js] - logIn succeeded')     
      // if(response.message === 'User Found'){  
        // console.log('Dispatching Login Success !!!!!!!!!!')
        // console.log('data= ', data);
      dispatch({
        type: LOGIN_SUCCESS,
        userId,
        role: data.role,
        authToken: data.authToken,
        // authExpiry: data.authTokenExpiry,
        authExpiry: data.authTokenExpiryMillis,
        tokenAssignTime: data.accessDateMillis
      })
      // console.log('NEW TOKEN WILL BE Timing out in millis',(data.authTokenExpiryMillis - data.accessDateMillis) - TOKEN_REFRESH_OFFSET);

      // console.log('authTokenExpiryMillis', data.authTokenExpiryMillis);
      // console.log('accessDateMillis', data.accessDateMillis);      
      // console.log('authTokenExpiryMillis - accessDateMillis',data.authTokenExpiryMillis - data.accessDateMillis);
      // console.log(' - ' + TOKEN_REFRESH_OFFSET, (data.authTokenExpiryMillis - data.accessDateMillis) - TOKEN_REFRESH_OFFSET)

      authtimer = setTimeout(
          ()=>{
            dispatch(refreshToken())}, 
            (data.authTokenExpiryMillis - data.accessDateMillis) - TOKEN_REFRESH_OFFSET
      );

      return true
    }).catch((e) => {
      // console.log('[actions/user.js] - logIn failed -- Unable to contact Spring service for LDAP auth')
      dispatch({
        type: LOGIN_FAILED,
        message: 'Unable to contact Spring service for LDAP auth'
      })
      return false
    })
  }



if('idp' === source) {
  //  console.log('^^^^^^^^ external user -- DB authentication -- IDP style ^^^^^^^^^^^ ')
  return fetch(`${config.ENDPOINT}/auth/authenticateIDP`, {
    headers: {
      //Authorization: config.AUTHORIZATION,
      'cache-control': 'no-cache',
      pragma: 'no-cache',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      username: userId,
      password: window.btoa(password)
    })
  }).then((res) => {
    // console.log('[actions/user.js] - (EXTERNAL) logIn returned res: ',res)
    return res.json()
  }).then((response) => {
    // console.log('[actions/user.js] - response: ',response)
    const data = response.data || {}
    // console.log('^^^^^^^^^^^^^^^^^^^ ' + data)
    
    // console.log('Role=', data.role)
    // console.log('Carrier=', data.carrierAbbr)
    if (response.status !== 'success'
    ||((data.role !== Roles.CARRIER) && (data.role !== Roles.AGENT)) 

    ) {
      dispatch({
        type: LOGIN_FAILED,
        message: 'Invalid username/password, or External user has not been approved'
      })

      return false
    }

    // console.log('[actions/user.js] - logIn succeeded')      
    // console.log('[actions/user.js] - logIn succeeded')   
    dispatch({
      type: LOGIN_SUCCESS,
      userId,
      role: data.role,
      entityType: data.entityType,
      carrier: data.carrierAbbr,
      agency: data.agencyAbbr,
      authToken: data.authToken,
      authExpiry: data.authTokenExpiry,
      tokenAssignTime: data.accessDateMillis
      // ,superSecretKey: data.superSecretKey
    })

    // console.log('NEW (EXTERNAL) TOKEN WILL BE Timing out in millis',(data.authTokenExpiryMillis - data.accessDateMillis) - TOKEN_REFRESH_OFFSET);

    // console.log('authTokenExpiryMillis', data.authTokenExpiryMillis);
    // console.log('accessDateMillis', data.accessDateMillis);      
    // console.log('authTokenExpiryMillis - accessDateMillis',data.authTokenExpiryMillis - data.accessDateMillis);
    // console.log(' - ' + TOKEN_REFRESH_OFFSET, (data.authTokenExpiryMillis - data.accessDateMillis) - TOKEN_REFRESH_OFFSET)

    authtimer = setTimeout(
      ()=>{
        dispatch(refreshToken())}, 
        (data.authTokenExpiryMillis - data.accessDateMillis) - TOKEN_REFRESH_OFFSET
  );


    return true
  }).catch((e) => {
    // console.log('********************* ' + e)
    dispatch({
      type: LOGIN_FAILED,
      message: 'Unable to contact Spring service for DB auth, IDP-style'
    })
    return false
  })
}


  dispatch({
    type: LOGIN_FAILED,
    message: 'Please select internal or external'
  })
  return false
}


export const refreshToken = () => (dispatch, getState) =>{
  const state = getState()
  // console.log('refreshToken')
  // console.log('state=',state)
  const user = state.user.userId;
  const token = state.user.authToken;
  // console.log('USER TOKEN REFRESH CALLED')
  // console.log('user', user)
  // console.log('token', token)
  clearInterval(authtimer)
  return fetch(`${config.ENDPOINT}/auth/refreshauthtoken`,
  {method: 'post',
   body: JSON.stringify({userId: user, authToken: token}),
    headers: new Headers({'Authorization': state.user.authToken,
                          'content-type' : 'application/json'})
  }).then((res) => {
    // console.log('USER TOKEN REFRESH -- res', res)
  return res.json()
}).then((res) => {
  // console.log('USER TOKEN REFRESH -- res', res)
    if(res.message === 'Validated'){
      dispatch({
        type: REFRESH,
        authToken: res.data.authToken,
        authExpiry: res.data.authTokenExpiryMillis,
        tokenAssignTime: res.data.accessDateMillis
      })
      // console.log('RESFRESHED TOKEN WILL BE Timing out in millis',(res.data.authTokenExpiryMillis - res.data.accessDateMillis) - TOKEN_REFRESH_OFFSET);

      // console.log('authTokenExpiryMillis', res.data.authTokenExpiryMillis);
      // console.log('accessDateMillis', res.data.accessDateMillis);      
      // console.log('authTokenExpiryMillis - accessDateMillis',res.data.authTokenExpiryMillis - res.data.accessDateMillis);
      // console.log(' - '+TOKEN_REFRESH_OFFSET, (res.data.authTokenExpiryMillis - res.data.accessDateMillis) - TOKEN_REFRESH_OFFSET)

      authtimer = setTimeout(()=>{
          dispatch(refreshToken())
      }, (res.data.authTokenExpiryMillis - res.data.accessDateMillis) - TOKEN_REFRESH_OFFSET);
    }
  })
}


/**
 * 
 * @returns 
 */
export const logOut = () => {
  return {
    type: LOGOUT
  }
}

