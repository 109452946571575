import React, { Component } from 'react'

import * as Roles from '../constants/roles'
import PageContainer from './PageContainer'
import IncidentTables from './IncidentTables/IncidentTables'


class Home extends Component {
  
    handleTabChange = (event, value) => {
        const { onTabChange, selectedTab } = this.props
        // console.log('[components.Home] handleTabChange - value = ', value)
        if (value !== selectedTab) {
          onTabChange(value)
        }
      }

  render () {
    const { role, onLoadHazmatData, selectedTab, page} = this.props
    return (
      <PageContainer>
         {/* <IncidentTables
            onLoadHazmatData={onLoadHazmatData}
            onTabChange={this.handleTabChange}
            selectedTab={selectedTab}
            page={page}
            
            /> */}
        
        { (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY|| role === Roles.CARRIER || role === Roles.AGENT || role === Roles.CUSTOMER_SERVICE_AGENT) &&
           <IncidentTables
            onLoadHazmatData={onLoadHazmatData}
            onTabChange={this.handleTabChange}
            selectedTab={selectedTab}
            page={page}
            
            />
        }
      </PageContainer>
    )
  }
}

export default Home
