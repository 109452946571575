import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const TableHeaderCell = ({ label, onClick, sortDirection, ...other }) => (
  <TableCell sortDirection={sortDirection} {...other}>
    <TableSortLabel
      active={!!sortDirection}
      direction={sortDirection || undefined}
      onClick={onClick}
    >
      {label}
    </TableSortLabel>
  </TableCell>
)

export default TableHeaderCell
