import * as CountryServices from '../services/country'

export const RETRIEVE_ALL_COUNTRIES_SUCCESS = 'RETRIEVE_ALL_COUNTRIES_SUCCESS'

/**
 * 
 * @returns 
 */
export const retrieveAllCountries = () => (dispatch, getState) => {
  const state = getState()
  const countries = state.country.countries

  if (countries && countries.length > 0) {
    return
  }

  return CountryServices.getAllCountries()
    .then((countries) => {
      const sortedCountries = countries.slice()
      sortedCountries.sort((a, b) => {
        return a.countryName > b.countryName
      })

      dispatch({
        type: RETRIEVE_ALL_COUNTRIES_SUCCESS,
        countries: sortedCountries
      })
    })
}
